import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import withStyle from '../style';

export default function Loader({show, progress}) {
  const classes = withStyle();

  // Add variant and value props if progress is provided and is a number
  const progressProps = typeof progress === 'number' ? { variant: "determinate", value: progress } : {};

  return show ? <CircularProgress className={classes.loader} {...progressProps} /> : null;
}
