import React, { useState, useEffect, useCallback } from 'react';
import _ from 'lodash';

import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Switch from '@material-ui/core/Switch';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import AppContainer from '../Components/AppContainer';
import MadeWithLove from '../Components/MadeWithLove';

import withStyle from '../style';
import Loader from '../Components/Loader';
import VolumetricItem from '../Components/VolumetricItemCard';

import io from 'socket.io-client';
import {HOST, BACKEND_PORT} from '../Utils/Networker';

import Networker from '../Utils/Networker';


const getUserVolumetricMeasurements = (userId, ignoreCache, all) => {
  return Networker.get({
    root: 'measurements',
    inner: 'owned/volumetric',
    query: all ? {
      all: true,
    } : {},
    cache: !ignoreCache
  });
};


const transformMeasurementArray = async (measurements) => {
  return measurements.map(({_id, measurements, maxAmount, count}) => {
    return {
      mac: _id,
      lastMeasurement: measurements[0],
      numMeasurements: count,
      previousCompute: measurements[0].computedData ,
      maxAmount
    };
  });
};


export default function Demo({match, history}) {
  const [user, setUser] = useState({});
  const [measurements, setMeasurements] = useState([]);
  const [loading, setLoading] = useState(false);
  const [getAll, setGetAll] = useState(false);
  const [connected, setConnected] = useState(false);

  const refresh = useCallback(async (ignoreCache) => {
    if (user && user._id) {
      setLoading(true);
      try {
        const measurements = await getUserVolumetricMeasurements(user._id, ignoreCache, getAll);
        setMeasurements([]);
        const allMeasurements = await transformMeasurementArray(measurements.body);
        setMeasurements(allMeasurements);
        setLoading(false);
      } catch (err) {
        setLoading(false);
        Networker.sendError({
          message: 'superuser volumetric dashboard error',
          namespace: 'dashboard.volumetric',
          data: err
        });
        history.push('/login');
      }
    }
  }, [getAll, history, user]);

  useEffect(() => {
    Networker.getUserSession()
    .then(res => {
      setUser(res.body);
    })
    .catch(err => {
      console.error(err);
      Networker.sendError({
        message: 'superuser volumetric dashboard session error',
        namespace: 'dashboard.volumetric',
        data: err
      });
      history.push('/login');
    });
  }, [history, refresh]);

  useEffect(() => {
    refresh(true);
    const socket = io(`${HOST}:${BACKEND_PORT}/measurements`, {
      transports: ['websocket']
    });
    socket.on('connect', function() {
      setConnected(true);
    });
    socket.on('disconnect', function(err) {
      setConnected(false);
    });
    socket.on('update', _.debounce(function(data) {
      refresh(true);
      Networker.interactionBeacon({
        message: 'volumetric update',
        data,
      });
    }, 2500, {leading: true, trailing: true, maxWait: 5000}));
    socket.on('error', function(data) {
      console.error('error in socket', data);
      Networker.sendError({
        message: 'volumetric dashboard socket error',
        namespace: 'dashboard.volumetric',
        data: data
      });
    });
    return () => {
      socket.close();
    }
  }, [refresh]);

  const deleteData = (mac, id) => (e) => {
      Networker.interactionBeacon({
        message: 'label reset',
        data: {
          mac: mac,
          id: id,
        },
      });
      if(window.confirm('Are you sure you want to delete all measurement data for ' + mac)) {
       Networker.delete({
         root: 'measurements',
         query: {
           mac,
           productId: id
         }
       })
       .then(res => {
         refresh(true);
       })
       .catch(err => {
         if (err.status === 401) {
           window.alert('You are not an admin of this product\'s organization');
         } else {
           console.error(err);
           Networker.sendError({
             message: 'volumetric dashboard delete error',
             namespace: 'dashboard.volumetric',
             data: err
           });
           refresh(true);
         }
       });
      }
  };
  const classes = withStyle();
  return <div className={classes.root}>
      <CssBaseline />
      <AppContainer classes={classes} title="Volumetric Product Demo" match={match}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth="lg">
          <Grid container spacing={3}
            className={classes.container} justifyContent="center" alignItems="center">
            <Grid item xs={12}>
              <FormGroup row>
                <FormControlLabel
                  disabled
                  control={
                    <Switch checked={connected} disableRipple={true} size="small" />
                  }
                  label="Connected"
                  />
                {user.isSuperUser ? <Grid component="label"
                  container alignItems="center" spacing={1}>
                  <Grid item>My Products</Grid>
                  <Grid item>
                    <Switch
                      checked={getAll}
                      onChange={() => {
                        setGetAll(!getAll);
                        // refresh(false);
                      }}
                      color="secondary"
                      />
                  </Grid>
                  <Grid item>All Products</Grid>
                </Grid> : null}
              </FormGroup>
            </Grid>
            <Grid item xs={12} container spacing={2} justifyContent="center" alignItems="center">
              {(measurements || []).map((meas) => {
                return <Grid item key={meas.mac} xs={12}>
                  <VolumetricItem {...meas}
                    onDelete={deleteData(meas.mac, (meas.lastMeasurement.productId || {})._id)}/>
                </Grid>})}
              </Grid>
            </Grid>
        </Container>
        <MadeWithLove />
        <Loader show={loading} />
      </AppContainer>
    </div>;
}
