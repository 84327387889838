import React, { useState, useEffect, useCallback } from 'react';
import _ from 'lodash';

import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Container from '@material-ui/core/Container';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import DialogContentText from '@material-ui/core/DialogContentText';
import Button from '@material-ui/core/Button';

import DescriptionIcon from '@material-ui/icons/Description';
import UnarchiveIcon from '@material-ui/icons/Unarchive';

import AppContainer from '../Components/AppContainer';
import Loader from '../Components/Loader';
import MadeWithLove from '../Components/MadeWithLove';
import ReactJson from 'react-json-view';

import Networker from '../Utils/Networker';
import withStyle from '../style';

function updateClipboard(newClip) {
  navigator.clipboard.writeText(newClip).then(() => {
    /* clipboard successfully set */
  }, function() {
    /* clipboard write failed */
  });
}
const clipEnable = (copy) => {
  try {
    navigator.permissions.query({name: "clipboard-write"})
    .then(result => {
      if (result.state === "granted" || result.state === "prompt") {
        /* write to the clipboard now */
        updateClipboard(JSON.stringify(copy.src));
      }
    });
  } catch (e) {
    console.error('copy failed');
  }
};

export default function Sensors({match}) {
  const [loading, setLoading] = useState(false);
  const [sensorNameFilter, setSensorNameFilter] = useState('');
  const [sensors, setSensors] = useState([]);
  const [sensorToEdit, setSensorToEdit] = useState({});
  const [errorMessage, setErrorMessage] = useState('');

  const refresh = useCallback(() => {
    Networker.get({
      root: 'admin',
      inner: 'sensors',
      query: {
      }
    }).then(({body}) => {
      setSensors(body.sort((a,b) => {
        return a.name.toLowerCase() > b.name.toLowerCase() ? 0 : -1;
      }));
      setLoading(false);
    }).catch((err) => {
      console.error(err);
    })
  }, []);

  useEffect(() => {
    refresh();
  }, [refresh]);

  const handleFilterChange = _.debounce((val) => {
    setSensorNameFilter(val);
  }, 150);

  const saveSensor = async () => {
    try {
      await Networker.put({
        root: 'admin',
        inner: 'sensor/' + sensorToEdit._id,
        body: {
          protobufPath: sensorToEdit.protobufPath,
          name: sensorToEdit.name,
        }
      });
      setErrorMessage('Saved at ' + new Date().toTimeString());
      refresh();
    } catch (e) {
      setErrorMessage(e.response.body.error);
    }
  };

  const closeEditor = () => {
    setSensorToEdit({});
  };

  const handleSensorClick = (sensor) => (e) => {
    setSensorToEdit(sensor);
  };

  const classes = withStyle();
  const schema = (sensorToEdit || {}).payloadSchema || {};
  const hasSchema  = Object.keys(schema).length !== 0;

  return <div className={classes.root}>
      <CssBaseline />
      <AppContainer classes={classes} title="Products" match={match}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth="lg" className={classes.container}>
          <Grid item xs={12} container spacing={1} justifyContent="center" alignItems="center">
            <Grid item xs={11}>
              <TextField label="Filter"
                defaultValue=""
                onChange={(e) => {
                  handleFilterChange(e.target.value);
                }}
                fullWidth autoFocus></TextField>
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <List>
                {sensors.filter(({name}) => {
                  return name.includes(sensorNameFilter);
                }).map((sensor) => {
                  const testSchema = (sensor || {}).payloadSchema || {};
                  const isV1  = Object.keys(testSchema).length !== 0;
                  return <ListItem key={sensor._id} button divider dense
                      onClick={handleSensorClick(sensor)}>
                    <ListItemAvatar>
                        {isV1 ? <UnarchiveIcon color="primary"/> : <DescriptionIcon />}
                    </ListItemAvatar>
                    <ListItemText primary={sensor.name} secondary={sensor.protobufPath}/>
                  </ListItem>
                })}
              </List>
            </Grid>
          </Grid>
        </Container>
        <Dialog open={!!sensorToEdit.name} onClose={closeEditor}
          fullWidth
          maxWidth="md">
          <DialogTitle id="form-dialog-title">
            Sensor Editor
            <Typography variant="caption" display="block">
              ID: {(sensorToEdit || {})._id}
            </Typography>
          </DialogTitle>
          <DialogContent>
            {errorMessage ? <DialogContentText>
              {errorMessage}
            </DialogContentText> : null}
            <TextField label="Name"
              fullWidth
              value={(sensorToEdit).name}
              onChange={e => {
                const newSensor = Object.assign({}, sensorToEdit);
                newSensor.name = e.target.value || '';
                setSensorToEdit(newSensor);
              }}
              >
            </TextField>
            {hasSchema ? null : <TextField label="Protobuf Path"
              fullWidth
              value={(sensorToEdit).protobufPath}
              onChange={e => {
                const newSensor = Object.assign({}, sensorToEdit);
                newSensor.protobufPath = e.target.value || '';
                setSensorToEdit(newSensor);
              }}
              >
            </TextField>}
            {hasSchema ? <ReactJson
              displayDataTypes={false}
              theme="solarized"
              enableClipboard={clipEnable}
              sortKeys={true}
              name="v1.1 Schema"
              src={schema}>
            </ReactJson> : null}
          </DialogContent>
          <DialogActions>
            <Button onClick={saveSensor}>
              Save
            </Button>
          </DialogActions>
        </Dialog>
        <MadeWithLove />
      <Loader show={loading} />
      </AppContainer>
    </div>;
}
