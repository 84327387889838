import React, { useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import Switch from '@material-ui/core/Switch';
import Tooltip from '@material-ui/core/Tooltip';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Chip from '@material-ui/core/Chip';
import Networker from '../Utils/Networker';
import TextEditor from '../Components/TextEditor';

import { camelToRead } from '../Utils/DataUtils';

import {
  getAllTags,
  getTriggerDescriptions
} from '../Utils/NotificationUtils';

import { Box, Dialog, DialogContent, DialogContentText } from '@material-ui/core';

import MobilePreview from './MobilePreview';
import Loader from './Loader';

// Look for any of the passed tags in the given message.
const matchTags = (message, tags) => {
  const getTagRx = (tag) => new RegExp('[[](' + tag + ')[\\]]', 'gm');
    console.info(`match '${message} in`, tags);

  return tags.map((tag) => message.match(getTagRx(tag)))
        .map((matched, i) => [tags[i], !!matched]);
}

export default function MessageEditor({ notification, handleSaveNotification, productData }) {
  const [tagMatches, setTagMatches] = useState([]);
  const [allTags, setAllTags] = useState([]);
  const [triggerDescriptions, setTriggerDescriptions] = useState({});
  const [newNotification, setNewNotification] = useState(notification);
  const [errorDialog, setErrorDialog] = useState(false);
  const [loading, setLoading] = useState(false);
  const [actionButton, setActionButton] = useState(notification?.actionButton || []);
  const [actionValue, setActionValue] = useState("");
  const [openMobilePreview, setOpenMobilePreview] = useState(false);

  const [quillValue, setQuillValue] = useState({
      editorHtml: notification?.message || '',
      title: notification?.subject || "",
      toggle: false,
      error: null
  })

  useEffect(() => document.querySelector(".MuiDialog-container").removeAttribute('tabindex'))

  useEffect(() => {
    // Setup the list of all possible tags which can be included in notification text.
    getAllTags().then((tags) => setAllTags(tags)).catch((err) => {
      console.error(err);

      Networker.sendError({
        message: "Tag description fetch error",
        namespace: "dashboard.messageeditor",
        data: err.toString()
      });
    });

    // Similarly setup a list of all possible triggers.
    getTriggerDescriptions().then(desc => setTriggerDescriptions(desc)).catch((err) => {
      console.error(err);
      Networker.sendError({
        message: "Trigger description fetch error",
        namespace: "dashboard.messageeditor",
        data: err.toString()
      });
    });
  }, []);

  useEffect(() => {
    if (newNotification.notificationType !== 'text') {
      setTagMatches(matchTags(quillValue.editorHtml, allTags));
    } else {
      setTagMatches(matchTags(newNotification.message, allTags));
    }
  }, [allTags, newNotification.message, quillValue.editorHtml, newNotification.notificationType]);

  // TODO: This is called for everything updating almost, make it make sense.
  const handleChange = (name) => (event) => {
    const value = event.target.value;
      console.info(`change ${name}='${value}'`)

    if (name.indexOf('.') > -1) {
      setNewNotification({
        ...newNotification,
        trigger: {
          ...newNotification.trigger,
          [name.split('.')[1]]: value
        }
      });
    } else if (name === 'survey') {
        setNewNotification({
          ...newNotification,
          associatedLink: value
        });
    } else if (event.target.hasOwnProperty('checked')) {
      setNewNotification({
        ...newNotification,
        [name]: event.target.checked
      });
    } else {
      setNewNotification({
        ...newNotification,
        [name]: value
      });
    }
  };

  const handleSave = () => {
    if (["push", "email"].includes(newNotification?.notificationType) && quillValue.error) {
        return setErrorDialog(true)
    }

    if (newNotification?.notificationType === "email" && quillValue?.title === "") {
      alert("Subject Required.")
    } else if (newNotification?.notificationType !== 'text'){
      handleSaveNotification({
        ...newNotification,
        message: quillValue.editorHtml,
        subject: quillValue.title,
        imageUrl: newNotification?.notificationType === "push" ? quillValue.imageUrl : null,
        actionButton: actionButton,
      })
    } else {
      handleSaveNotification(newNotification);
    }
  };

  const handleCancel = () => handleSaveNotification(null);

  const handleActionButton = () => {
    setActionButton((prev) => ([...prev, actionValue]))
    setActionValue("")
  }

  const handleDeleteActionButton = (button) => {
    setActionButton((prev) => prev.filter(actionButton => actionButton !== button))
  }

  // Don't show anything while loading.
  if (!productData) { return <div>Loading...</div>; }

  // Extracting surveys from productData.externalLinks
  const surveys = productData.externalLinks?.filter((link) => link.service === 'surveymonkey' && link.identifier)
        ?.map((link) => ({ name: link.meta.name, id: link.identifier })) || [];

  const descriptor = triggerDescriptions[newNotification.trigger.type];

  if (!(descriptor && newNotification)) {
      return <div>Internal Error: Please Reload the Page.</div>;
  }

  const type = newNotification.notificationType;

  const label = {
      email: 'Subject',
      push: 'Title'
  }[type] || '';

  // Length of the second line in the grid
  const fieldLength = 12 / (!!descriptor.field + !!descriptor.value + !!descriptor.value1 + !!surveys?.length);

  return <div>
    {openMobilePreview ?
      <MobilePreview actionButton={actionButton} quillValue={quillValue}
        setOpenMobilePreview={setOpenMobilePreview}
        openMobilePreview={openMobilePreview}>
    </MobilePreview> : null
    }
    <Loader show={loading} />
    <Dialog open={errorDialog} onClose={() => setErrorDialog(false)}
      aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
      <DialogContent>
        <DialogContentText id="alert-dialog-description" style={{ textAlign: "center", fontSize: "1.3rem", padding: "3rem" }}>
          {quillValue.error}
        </DialogContentText>
      </DialogContent>
    </Dialog>
    <FormControlLabel control={
      <Switch checked={!!newNotification.active} onChange={handleChange('active')}
        value={newNotification.active} inputProps={{ 'aria-label': 'secondary checkbox' }} />
    } label="Active"/>
    {label ?
      <>
        <TextField fullWidth label={label} value={quillValue.title} style={{ marginBottom: 15, marginTop: 15 }} variant="outlined"
          required onChange={(e) => { e.persist(); setQuillValue((prev) => ({ ...prev, title: e.target?.value })) }} />
        <TextEditor setOpenMobilePreview={setOpenMobilePreview} setQuillValue={setQuillValue}
          quillValue={quillValue} setLoading={setLoading} />
      </>
    : (<TextField multiline rows="6" fullWidth rowsMax="32" label="Message" size="small" variant="outlined"
        value={newNotification.message} onChange={handleChange('message')}></TextField>)
    }
    <Grid container spacing={1} direction="row" justifyContent="flex-start">
      {/* List of triggers used in this notification */}
      <Grid item xs={12}>
        {tagMatches.map(([key, match]) => <Tooltip style={{ marginRight: 6, marginTop: 10 }} title={`[${key}]`} key={key}>
          <Chip variant="outlined" key={key} color={match ? 'secondary' : 'primary'} size="small" onClick={() => {
              console.info('hi,', newNotification.message);
              if (label) {
                  console.info('add tag:', `[${key}]`)
                  setQuillValue((prev) => ({
                      editorHtml: prev.editorHtml,
                      ...prev,
                      toggle: !prev.toggle,
                      tag: `[${key}]`
                  }));
              } else {
                  console.info('message += ', `[${key}]`)
                  setNewNotification({
                      ...newNotification,
                      message: newNotification.message + `[${key}]`
                  });
              }
          }} label={camelToRead(key)} />
        </Tooltip>)
        }
      </Grid>
      {/* Notification Type (Method) */}
      <Grid item xs={6}>
        <TextField fullWidth select label="Type" value={newNotification.notificationType} onChange={handleChange('notificationType')}>
          <MenuItem value={'email'}>Email</MenuItem>
          <MenuItem value={'text'}>Text</MenuItem>
          <MenuItem value='push'>Push Notification</MenuItem>
        </TextField>
      </Grid>
      {/* Notification Trigger */}
      <Grid item xs={6}>
        <TextField fullWidth select label="Trigger" value={newNotification.trigger.type} onChange={handleChange('trigger.type')}>
          {Object.keys(triggerDescriptions).sort().map((trig) => <MenuItem key={trig} value={trig}>
            {triggerDescriptions[trig].description}
          </MenuItem>)}
        </TextField>
      </Grid>
      {/* Something called "actions" for push notifications. */}
      <Grid item xs={12}>
        {actionButton.map(button => <Tooltip title={button} key={button}>
            <Chip variant="outlined" style={{ marginTop: "0.5rem", marginRight: "0.5rem" }} key={button}
              color={'secondary'} size="medium" onDelete={() => handleDeleteActionButton(button)} label={button} />
          </Tooltip>)}
      </Grid>
      {/* Something to add "actions" for push notificaitons. */}
      {newNotification.notificationType === "push" ?
        <Grid item xs={12}>
          <Box style={{ display: "flex", alignItems: "end", gap: "1rem", marginBottom: "0.5rem" }}>
            <TextField style={{ flexGrow: "1" }} id="standard-basic" label="Action" value={actionValue} onChange={(e) => setActionValue(e.target.value)} />
            <Button disabled={!actionValue} onClick={handleActionButton} style={{ fontSize: "12px" }} variant="contained">Add Action</Button>
          </Box>
        </Grid> : null
      }
      {/* Selection for notification field. */}
      {descriptor.field ?
        <Grid item xs={fieldLength}>
          <TextField select fullWidth value={newNotification.trigger.field} label="Field" onChange={handleChange('trigger.field')}>
            {descriptor.field.map((dataKey) => <MenuItem key={dataKey} value={dataKey}>{camelToRead(dataKey)}</MenuItem>)}
            <MenuItem value={''}>None</MenuItem>
          </TextField>
        </Grid> : null
      }
      {/* Field for notification value */}
      {descriptor.value ?
        <Grid item xs={fieldLength}>
          <TextField fullWidth type={descriptor.value} label="Value" value={newNotification.trigger.value} onChange={handleChange('trigger.value')}>
          </TextField>
        </Grid> : null
      }
      {/* Field for notification value1 */}
      {descriptor.value1 ?
        <Grid item xs={fieldLength}>
          <TextField fullWidth type={descriptor.value1} label="Value 2" value={newNotification.trigger.value1} onChange={handleChange('trigger.value1')}>
          </TextField>
        </Grid> : null
      }
      {surveys?.length ?
        <Grid item xs={fieldLength}>
          <TextField select fullWidth value={newNotification.associatedLink} label="Linked Survey" onChange={handleChange('survey')}>
            {surveys.map((survey, index) => (<MenuItem key={index} value={survey.id}>{survey.name ? survey.name : survey.id}</MenuItem>))}
            <MenuItem value={''}>None</MenuItem>
          </TextField>
        </Grid> : null
      }
      <Grid item xs={6}><Button style={{ marginTop: 4, marginBottom: 4 }} fullWidth color="secondary" variant="contained" onClick={handleSave}>Save</Button></Grid>
      <Grid item xs={6}><Button style={{ marginTop: 4, marginBottom: 4 }} fullWidth color="primary" onClick={handleCancel}>Cancel</Button></Grid>
    </Grid>
  </div>;
}
