import React, { useState } from 'react';
import { XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, Cell } from 'recharts';
import { AreaChart, Area, BarChart, Bar, LineChart, Line } from 'recharts';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import InfoIcon from '@material-ui/icons/Info';
import FormatColorFillIcon from '@material-ui/icons/FormatColorFill';
import withStyle from '../style';
import { withStyles } from '@material-ui/core/styles';
import { useTheme } from '@material-ui/styles';

const CustomYAxisTick = (props) => {
  const length = 10;
  const { y, x, payload } = props;
  const val = payload.value.length > length ?
    payload.value.substr(0, length) + '...' : payload.value;
  return (<g transform={`translate(${x},${y + length})`}>
    <g transform={`rotate(35,0,0)`}>
      <text x={0} y={0}
        textAnchor="start"
        fill="#666">{val}</text>
    </g>
  </g>);
};

const METRIC_ANGLE = 30;
const RedRadio = withStyles({
  root: {
    color: 'black', // Unchecked color
    outline: 'black',
    '&$checked': {
      color: 'red', // Checked color
    },
    '& .MuiIconButton-root': {
      borderColor: 'black', // Outline color
    },
  },
  checked: {},
})((props) => <Radio color="default" {...props} />);

export default function MetricCurveNoProcessing({ title, data,
  showAverage, averageString, emptyAverageString, hideAxis, unit, isDate, isCategorical }) {
  const classes = withStyle();
  const theme = useTheme();
  const finalData = data ? data : [];
  //TODO: Refactor show empty to be something like filterType that will hold values (empty, in-use, all)
  const [showEmpty, setShowEmpty] = useState(null);
  const spacing = finalData.length < 2 || isCategorical || isDate
    ? 1
    : finalData[1].value - finalData[0].value;
  const fillColor = theme.palette.secondary.light;
  const strokeColor = theme.palette.secondary.dark;
  const handleProductChange = (event) => {
    setShowEmpty(event.target.value === 'empty' ? true : event.target.value === 'in-use' ? false : null);
  };

  let filteredData;
  if (title === 'Uses' && showEmpty !== null) {
    filteredData = finalData.filter(item => item.empty === showEmpty);
  } else {
    filteredData = finalData;
  }

  let average = 0.0;
  let emptyAverage = 0.0;
  let nonEmptyAverage = 0.0;

  if (showAverage && finalData.length > 0) {
    // Calculate average for all data points
    const tempAverage = finalData.reduce((sum, e) => sum + e.frequency, 0) / finalData.length;
    average = tempAverage.toFixed(2);

    // Calculate average separately for empty and is-use data points
    const emptyData = finalData.filter(e => e.empty === true);
    const nonEmptyData = finalData.filter(e => e.empty === false);

    if (emptyData.length > 0) {
      const tempAverageEmpty = emptyData.reduce((sum, e) => sum + e.frequency, 0) / emptyData.length;
      emptyAverage = tempAverageEmpty.toFixed(2);
    }

    if (nonEmptyData.length > 0) {
      const tempAverageNonEmpty = nonEmptyData.reduce((sum, e) => sum + e.frequency, 0) / nonEmptyData.length;
      nonEmptyAverage = tempAverageNonEmpty.toFixed(2);
    }
  }
  const totalAverageString = "Average total uses per user:";

  return <Card className={classes.paper}>
    <Grid container spacing={2}>
      <Grid item xs={12} sm={3} style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
        <Typography variant="h5">
          {title || (isDate ? 'Timeline (Days)' : 'Distribution')}
        </Typography>
      </Grid>
      {title === 'Uses' ? (
        <Grid item xs={12} sm={9} container justifyContent="flex-start">
          <RadioGroup value={showEmpty ? 'empty' : showEmpty === false ? 'in-use' : ''} onChange={handleProductChange} row>
            <FormControlLabel
              value="in-use"
              control={<RedRadio />}
              label={<Grid container direction="row">
                <Typography variant="body1" style={{ fontSize: '12px' }}>When Product in Use</Typography>
                <FormatColorFillIcon fontSize="small" color="secondary" />
              </Grid>}
            />
            <FormControlLabel
              value="empty"
              control={<RedRadio />}
              label={<Grid container direction="row">
                <Typography variant="body1" style={{ fontSize: '12px' }}>When Empty</Typography>
                <FormatColorFillIcon fontSize="small" color="primary" />
              </Grid>}
            />
            <FormControlLabel
              value=""
              control={<RedRadio />}
              label={<Typography variant="subtitle1" style={{ fontSize: '12px' }}>All</Typography>}
            />
          </RadioGroup>
        </Grid>
      ) : null}
    </Grid>
    {finalData.length < 1 ? <Typography>No Data</Typography> : <ResponsiveContainer width={"100%"} aspect={1.7}>
      {!isCategorical ? <AreaChart data={finalData}>
        <XAxis dataKey="value" type="number" interval="preserveStartEnd"
          angle={METRIC_ANGLE} domain={['dataMin', 'dataMax']} />
        <YAxis />
        <CartesianGrid strokeDasharray='3 3' />
        <Tooltip labelFormatter={(val) => {
          const max = (finalData[finalData.length - 1] || {}).value || 0;
          const min = (finalData[0] || {}).value || 0;
          if (parseFloat(val) + spacing > max) {
            return `${parseFloat(val) + spacing}+`;
          } else if (val === min) {
            return `< ${parseFloat(val) + spacing}`;
          } else {
            return `${val} - ${(parseFloat(val) + spacing)}`;
          }
        }}></Tooltip>
        <Area stroke={strokeColor} fill={fillColor} type="monotone"
          dataKey="frequency" isAnimationActive={false} connectNulls={true} />
      </AreaChart > :
        isDate ? <LineChart data={finalData}>
          <XAxis dataKey="value" interval="preserveStartEnd" angle={METRIC_ANGLE} />
          <YAxis />
          <Tooltip></Tooltip>
          <Line stroke={strokeColor} fill={fillColor} type="monotone" dataKey="frequency" />
        </LineChart> :
          //this is the bar-chat of Uses
          <BarChart data={filteredData} margin={{ bottom: 50, right: 60 }}>
            {hideAxis ? null : <XAxis dataKey="value" interval={0} minTickGap={10}
              tick={<CustomYAxisTick />} />}
            <YAxis />
            {hideAxis ? null : <Tooltip></Tooltip>}
            <Bar dataKey="frequency" type="monotone" fill={showEmpty ? theme.palette.primary.main : theme.palette.secondary.light} stroke={theme.palette.primary.main}>
              {showEmpty === null && title === 'Uses' && (
                filteredData.map((e, i) => (
                  <Cell
                    key={i}
                    stroke={e.empty ? theme.palette.primary.main : strokeColor}
                    fill={e.empty ? theme.palette.primary.main : fillColor}
                  />
                ))
              )}
            </Bar>
          </BarChart >
      }
    </ResponsiveContainer>}
    {showAverage && filteredData.length > 0 && (
      <CardActionArea className={classes.chartInfo}>
        {title === 'Uses' && showEmpty === null && (
          <Grid>
            <Grid container alignItems="center" justifyContent="center">
              <InfoIcon /> &nbsp;
              {totalAverageString}&nbsp;{average}&nbsp; {unit}
            </Grid>
            <Grid container alignItems="center" justifyContent="center">
              <InfoIcon /> &nbsp;
              {averageString}&nbsp;{nonEmptyAverage}&nbsp; {unit}
            </Grid>
            <Grid container alignItems="center" justifyContent="center">
              <InfoIcon /> &nbsp;
              {emptyAverageString}&nbsp;{emptyAverage}&nbsp; {unit}
            </Grid>

          </Grid>
        )}
        {title === 'Uses' && showEmpty === true && (
          <Grid container alignItems="center" justifyContent="center">
            <InfoIcon /> &nbsp;
            {emptyAverageString}&nbsp;{emptyAverage}&nbsp; {unit}
          </Grid>
        )}
        {title === 'Uses' && showEmpty === false && (
          <Grid container alignItems="center" justifyContent="center">
            <InfoIcon /> &nbsp;
            {averageString}&nbsp;{nonEmptyAverage}&nbsp; {unit}
          </Grid>
        )}
      </CardActionArea>
    )}
    {filteredData.length === 0 && title === 'Uses' && (
      <Typography variant="h6" style={{ position: 'relative', bottom: '8em', textAlign: 'center' }}>
        This graph currently does not have any data
      </Typography>
    )}
  </Card>
}
