import React from 'react';

import Card from '@material-ui/core/Card';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer';
import Typography from '@material-ui/core/Typography';

import AvatarGroup from '@material-ui/lab/AvatarGroup';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import Tooltip from '@material-ui/core/Tooltip';

import UnarchiveIcon from '@material-ui/icons/Unarchive';
import FunctionsIcon from '@material-ui/icons/Functions';
import SendIcon from '@material-ui/icons/Send';
import SaveIcon from '@material-ui/icons/Save';


import Grid from '@material-ui/core/Grid';
import Avatar from '@material-ui/core/Avatar';

import DeleteIcon from '@material-ui/icons/Delete';

import withStyle from '../style';

function getMiddlewareIcon(field) {
  return field === 'data' ? <UnarchiveIcon color="secondary"/> : <FunctionsIcon color="action"/>;
}

function productsEqual({prod, orgs}, newProps) {
  return JSON.stringify(prod) === JSON.stringify(newProps.prod) &&
  orgs.length === newProps.orgs.length;
}

export default React.memo(function ProductCard({
  isSuperUser, prod, orgs, middleware, onEdit, onSurveyEdit, onDelete
}) {
  const classes = withStyle();

  const sortedOrgs = (orgs || []).sort((a,b) => {
    return a.name >= b.name ? 0 : -1;
  });
  const maxAvatarLen = 3;
  return <Card className={classes.paper}>
      <Grid container spacing={1} alignItems="center" justifyContent="center">
        <Grid item container xs={12} alignItems="center" justifyContent="space-between">
          <Grid item xs={6}>
            <Typography variant="caption" color="textSecondary" gutterBottom >
              Product {isSuperUser ? '_id: ' + prod._id : ''}
            </Typography>
          </Grid>
          {isSuperUser ? <Grid item xs={6} spacing={3} container alignItems="center" justifyContent="flex-end">
            <Grid item>
              <Tooltip title="Edit">
              <IconButton  onClick={onEdit} id={prod._id}>
                <EditIcon id={prod._id}/>
              </IconButton>
              </Tooltip>
            </Grid>
            <Grid item>
              <Tooltip title="Delete">
              <IconButton  onClick={onDelete} id={prod._id}
                variant="contained" color="secondary">
                <DeleteIcon id={prod._id} />
              </IconButton>
              </Tooltip>
            </Grid>
            {['CONNECT','VOLUMETRIC','ANALYTICS'].includes(prod.tier[0]) ? <Grid item>
              <Tooltip title="External Link Setup">
              <IconButton onClick={onSurveyEdit} id={prod._id}>
                <QuestionAnswerIcon id={prod._id}/>
              </IconButton>
              </Tooltip>
            </Grid> : null}
          </Grid> : null}
          {/* <Grid item xs={2} zeroMinWidth>
              {prod.upc && prod.upc.length > 0 ?
                <Tooltip title="GS1 Compatible">
                  <img src='/GS1_Corporate_Large_RGB_2014-12-17.jpg' alt='GS1'
                    height="100%" width="100%"/>
                </Tooltip>: null}
          </Grid> */}
        </Grid>
        <Grid item container xs={12} alignItems="center" justifyContent="flex-start" wrap="nowrap">
          {prod.iconURL ? <Grid item xs={2}>
              <Avatar alt={prod.name} src={prod.iconURL}
                className={classes.avatar}>
                {prod.name.substr(0, 2)}
              </Avatar>
          </Grid> : null}
          <Grid item xs={10} zeroMinWidth>
            <Tooltip title={prod.name}>
              <Typography variant="h5" component="h5" noWrap>
                {prod.name}
              </Typography>
            </Tooltip>
          </Grid>
        </Grid>
        <Grid item xs={12} s={6}>
          <Typography display="inline" variant="subtitle2">Product ID: </Typography>
          <Typography variant="caption" display="inline">
            {prod.productId || '_'}
          </Typography>
        </Grid>
        <Grid item xs={12} s={6}>
          <Typography display="inline" variant="subtitle2">Tier: </Typography>
          <Typography variant="caption" display="inline">
            {prod.tier[0]}
          </Typography>
        </Grid>
        <Grid item xs={12} container alignItems="center" justifyContent="flex-start">
          <Typography display="inline" variant="body2">Organizations: </Typography>
          <AvatarGroup className={classes.avatarGroupNoBorder} spacing="small">
            {sortedOrgs.filter((_,idx) => {
              return idx < maxAvatarLen;
            }).map(org => <Grid item key={org._id}>
              <Tooltip title={org.name}>
                <Avatar alt={org.name} src={org.iconURL}  className={classes.smallAvatar}>
                  {org.name.substr(0, 2)}
                </Avatar>
              </Tooltip>
            </Grid>)}
            {orgs.length > maxAvatarLen ? <Tooltip title={
              sortedOrgs.filter((_,idx) => {
                return idx > maxAvatarLen;
              }).reduce((namelist, {name}) => {
                namelist = `${namelist}, ${name}`;
                return namelist;
              }, `${sortedOrgs[maxAvatarLen].name}`)}
            >
            <Avatar className={classes.smallAvatar}>+{orgs.length - maxAvatarLen}</Avatar>
            </Tooltip> : null}
          </AvatarGroup>
        </Grid>
        {isSuperUser ? <Grid item xs={12} container
          spacing={2} alignItems="center" justifyContent="center">
            <Grid item>
              <Typography gutterBottom>Dataflow</Typography>
            </Grid>
            <Grid item>
              <Tooltip title="Use">
                <SendIcon/>
              </Tooltip>
            </Grid>
            <Grid item>
              <ArrowRightIcon/>
            </Grid>
            {(middleware || []).map((midd, idx) => {
              return midd ? <React.Fragment key={midd._id}>
                <Grid item>
                  <Tooltip title={midd.name}>
                    {getMiddlewareIcon(midd.dataField)}
                  </Tooltip>
                </Grid>
                <Grid item>
                  <ArrowRightIcon/>
                </Grid>
            </React.Fragment> : null;
            })}
            <Grid item>
              <Tooltip title="Save">
                <SaveIcon />
              </Tooltip>
            </Grid>
        </Grid> : null}
      </Grid>
    </Card>;
}, productsEqual);
