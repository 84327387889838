import React, { useState, useEffect } from 'react';
import _ from 'lodash';

import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

import OrganizationCard from '../Components/OrganizationCard';
import OrganizationEditor from '../Components/OrganizationEditor';
import ErrorBoundary from '../Components/ErrorBoundary';

import AppContainer from '../Components/AppContainer';
import Networker from '../Utils/Networker';
import Loader from '../Components/Loader';
import MadeWithLove from '../Components/MadeWithLove';

import withStyle from '../style';

const getAllOrganizations = (userId) => {
  return Networker.get({
    root: 'organizations',
    query: {userId}
  });
};

const getUsersForOrg = (org) => {
  return Networker.get({
    root: 'organizations',
    inner: org + '/users'
  });
};

const getAllSUOrganizations = () => {
  return Networker.get({
    root: 'organizations',
    inner: 'all'
  });
};

const getAllSUUsers = () => {
  return Networker.get({
    root: 'user',
    inner: 'all'
  });
};

const deleteOrg = (_id) => {
  return Networker.delete({
    root: 'organizations',
    query: {_id}
  });
};

export default function Organizations({match}) {
  const [user, setUser] = useState();
  const [organizations, setOrganizations] = useState([]);
  const [orgUserObject, setOrgUserObject] = useState({});
  const [neworg, setNewOrg] = useState(null);
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState([]);
  const [orgNameFilter, setOrgNameFilter] = useState('');

  useEffect(() => {
    setLoading(true);
    Networker.getUserSession()
    .then(res => {
      setUser(res.body);
      setLoading(false);
      refresh(res.body);
    })
    .catch(err => {
      setLoading(false);
    });
  }, [match]);

  const refresh = (forUser) => {
    if (forUser && forUser._id) {
      const {isSuperUser, _id} = forUser;
      const getFunc = isSuperUser ? getAllSUOrganizations : getAllOrganizations;
      setLoading(true);
      if (isSuperUser) {
        getAllSUUsers()
        .then(res => {
          setUsers(res.body);
        })
        .catch(err => {
          console.error(err);
          Networker.sendError({
            message: 'organization superuser user fetch error',
            namespace: 'dashboard.organizations',
            data: err
          });
        });
      }
      getFunc(_id)
      .then(async (res) => {
        const newOrgUserObject = {};
        await Promise.all(res.body.map(async org => {
          newOrgUserObject[org._id] = [];
          try {
            const res = await getUsersForOrg(org._id);
            newOrgUserObject[org._id] = res.body;
            return Promise.resolve();
          } catch (e) {
            console.error(e);
            return Promise.resolve();
          }
        }));
        const dupeTrack = {};
        const nonDupes = res.body.map(org => {
          if (dupeTrack[org._id]) {
            return null;
          }
          dupeTrack[org._id] = true;
          return org;
        }).filter(e => e);
        setOrgUserObject(newOrgUserObject);
        setOrganizations(nonDupes);
        setLoading(false);
      })
      .catch((err) => {
        console.error(err);
        setLoading(false);
        Networker.sendError({
          message: 'organization fetch error',
          namespace: 'dashboard.organizations',
          data: err
        });
      });
    }
  };

  const showEditor = (e) => {
    let node = e.target;
    let i = 0;
    while (node.nodeName !== 'BUTTON' && i < 4) {
      node = node.parentNode;
      i += 1;
    }
    const id = node.id;
    const org = organizations.find((elem) => elem._id === id);
    setNewOrg(org);
  };

  const closeEditor = () => {
    setNewOrg(null);
    refresh(user);
  };

  const handleOrgAddClick = () => {
    setLoading(true);
    Networker.post({
      root: 'organizations',
      body: {
        name: `${user.email}'s Organization ${user.organizations.length + 1}`,
        email: user.email,
      }
    })
    .then(() => {
      refresh(user);
    })
    .catch(err => {
      console.error(err);
      setLoading(false);
      Networker.sendError({
        message: 'organization create error',
        namespace: 'dashboard.organizations',
        data: err
      });
    });
  };

  const handleFilterChange = _.debounce((val) => {
    setOrgNameFilter(val.toLowerCase());
  }, 150);

  const classes = withStyle();
  return <div className={classes.root}>
      <CssBaseline />
      <AppContainer classes={classes} title="Organizations" match={match}>
        <div className={classes.appBarSpacer} />
        <ErrorBoundary>
        <Container maxWidth="xl" className={classes.container}>
          <Grid container spacing={1} justifyContent="flex-start" alignItems="flex-start">
            <Grid item xs={12} md={11}>
              <TextField label="Filter"
                defaultValue=""
                onChange={(e) => {
                  handleFilterChange(e.target.value);
                }}
                fullWidth autoFocus></TextField>
            </Grid>
            {user && user.isSuperUser ? <Grid item xs={12} md={1}>
              <Button
                value="new"
                onClick={handleOrgAddClick}
              >Create New</Button>
            </Grid> : null}
            {organizations.filter(({name}) => {
              return name.toLowerCase().includes(orgNameFilter);
            }).map((org) => <Grid item key={org._id} xs={12} md={6} lg={4}>
              <OrganizationCard key={org._id}
                onEdit={showEditor}
                org={org}
                users={orgUserObject[org._id]}
                onUpgrade={() => {
                  refresh(user);
                }}/>
            </Grid>)}
          </Grid>
          {user ? <OrganizationEditor
            isSuperUser={user.isSuperUser}
            allUsers={users}
            onDelete={deleteOrg}
            org={neworg}
            open={Boolean(neworg)}
            handleClose={closeEditor}/> : null}
        </Container>
        <MadeWithLove />
      <Loader show={loading} />
      </ErrorBoundary>
      </AppContainer>
    </div>;
}
