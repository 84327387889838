import Networker from './Networker';


const getTriggerInfo = async () => {
  const triggerInfo = await Networker.get({
    root: 'notifications',
    inner: 'triggers',
    cache: true,
  });
  return triggerInfo.body;
};

export async function getTriggerDescriptions() {
  if (typeof getTriggerDescriptions.req == 'undefined' ) {
    getTriggerDescriptions.req = getTriggerInfo();
  }
  return getTriggerDescriptions.req;
}

const getTagInfo = async () => {
  const tagNames = await Networker.get({
    root: 'notifications',
    inner: 'tags',
    cache: true,
  });
  return tagNames.body;
};

export async function getAllTags() {
  if (typeof getAllTags.tagReq == 'undefined' ) {
    getAllTags.tagReq = getTagInfo();
  }
  return getAllTags.tagReq;
}
