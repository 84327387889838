import React, {useState, useEffect, useRef} from 'react';
import _ from 'underscore';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import ListSubheader from '@material-ui/core/ListSubheader';
import ReactJson from 'react-json-view';

import WarningIcon from '@material-ui/icons/Warning';
import InfoIcon from '@material-ui/icons/Info';
import ErrorIcon from '@material-ui/icons/ErrorOutline';
import ImportExportIcon from '@material-ui/icons/ImportExport';


import Networker from '../Utils/Networker';
import Loader from '../Components/Loader';
import AppContainer from '../Components/AppContainer';

import withStyle from '../style';

function updateClipboard(newClip) {
  navigator.clipboard.writeText(newClip).then(() => {
    /* clipboard successfully set */
  }, function() {
    /* clipboard write failed */
  });
}

const readableDate = (str) => {
  const date = new Date(str);
  return date.toLocaleString();
}

const MemoLog = function Log({log, onClick, filter, expandAround}) {
  const {_id, createdAt, namespace, message, type} = log;
  const classes = withStyle();
  const hidden = !message.toLowerCase().includes(filter);
  let status = null;
  if (log.namespace === 'request' && log.data.status) {
    status = 'Status: ' + log.data.status;
  }
  return (
    <ListItem className={classes.logEntry}
      divider
      dense
      style={{display: hidden ? 'none' : ''}}
      disableGutters>
      <Grid container spacing={0} justifyContent="flex-start" alignItems="flex-start">
        <Grid item xs={1}>
          <Button onClick={onClick} id={_id}>
            <ListItemIcon>
              {type === 'info' ? <InfoIcon color="primary"/> :
                (type === 'error' ? <ErrorIcon color="error"/> :
                <WarningIcon color="secondary"/>)}
                </ListItemIcon>
              </Button>
        </Grid>
        <Grid item xs={11}>
          <Typography
            fontWeight="fontWeightBold" display="inline"
            variant="subtitle1" color={status ? 'primary' : 'secondary'} noWrap>
            {status ? status : namespace}
          </Typography>
        </Grid>
        <Grid item xs={10}>
          <Typography component="span" gutterBottom={false} display="block"
            fontWeight="fontWeightLight"
            variant="subtitle2" color="textSecondary"  noWrap={true}>
            {message}
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <Button onClick={expandAround} id={_id}>
            Focus
            <ImportExportIcon size="small"/>
          </Button>
        </Grid>
        <Grid item xs={12}>
          <Typography display="inline" variant="caption">
            {readableDate(createdAt)}
          </Typography>
        </Grid>
      </Grid>
    </ListItem>
  );
};

const getLogs = (start, end, namespace, level, message) => {
  const tz = new Date().getTimezoneOffset() * 1000 * 60;
  const query = {
    start: new Date(start + 'Z').valueOf() + tz,
    end: new Date(end + 'Z').valueOf() + tz,
    limit: 250,
  };
  if (namespace && namespace.length > 4) {
    query.namespace = namespace;
  }
  if (level && level.length > 0) {
    query.type = level;
  }
  if (message && message.length > 4) {
    query.message = message;
  }
  return Networker.get({
    root: 'logs',
    query,
    cache: true
  });
}
const hour = new Date(1000*60*60*1 * 12);

const removeZ = (date) => {
  return date.replace('Z', '');
};

const clipEnable = (copy) => {
  try {
    navigator.permissions.query({name: "clipboard-write"})
    .then(result => {
      if (result.state === "granted" || result.state === "prompt") {
        /* write to the clipboard now */
        updateClipboard(JSON.stringify(copy.src));
      }
    });
  } catch (e) {
    console.error('copy failed');
  }
};

export default function Logs({match, history}) {
  const tz = new Date().getTimezoneOffset() * 1000 * 60;
  const [logs, setLogs] = useState([]);
  const [namespace, setNamespace] = useState('');
  const [startTime, setStart] = useState(removeZ(new Date((Date.now() - hour) - tz).toISOString()));
  const [endTime, setEnd] = useState(removeZ(new Date(Date.now() - tz).toISOString()));
  const [level, setLogLevel] = useState(' ');
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(true);
  const [messageFilter, setMessageFilter] = useState('');
  const [logToView, setLogToView] = useState(null);
  const [focusTime, setFocusTime] = useState(60);

  const refresh = useRef();

  useEffect(() => {
    if (refresh && !refresh.current) {
      refresh.current = _.debounce((start, end, name, lev, mess) => {
        setLoading(true);
        getLogs(start, end, name, lev, mess)
        .then(res => {
          setLogs([]);
          setLogs(res.body);
          setLoading(false);
        })
        .catch((err) => {
          console.error(err);
          setLoading(false);
        })
      }, 1000);
    }
    refresh.current(startTime, endTime, namespace, level, message);
  }, [namespace, startTime, endTime, level, message]);

  const edit = (e) => {
    let {id, value} = e.target;
    if (!id) {
      id = e.target.name;
    }
    if (id === 'namespace') {
      setNamespace(value);
    } else if (id === 'level') {
      setLogLevel(value);
    } else if (id === 'start') {
      setStart(value);
    } else if (id === 'end') {
      setEnd(value);
    } else if (id === 'message') {
      setMessage(value);
    } else if (id === 'delta') {
      setFocusTime(+value);
    }
  }

  const showJSON = (e) => {
    let node = e.target;
    let i = 0;
    while (!node.id && i < 4) {
      node = node.parentNode;
      i += 1;
    }
    const logId = node.id;
    const log = logs.find(({_id}) => _id === logId);
    setLogToView(log);
  };

  const focusLog = (e) => {
    let node = e.target;
    let i = 0;
    while (!node.id && i < 4) {
      node = node.parentNode;
      i += 1;
    }
    const logId = node.id;
    const log = logs.find(({_id}) => _id === logId);

    const newMidpoint = Date.parse(log.createdAt) - tz;
    const deltaT = focusTime * 1000;

    setNamespace('');
    setStart(removeZ(new Date(newMidpoint - deltaT).toISOString()));
    setEnd(removeZ(new Date(newMidpoint + deltaT).toISOString()));
    setMessageFilter('');
    setMessage('');
    setLogLevel(' ')
  };

  const handleFilterChange = _.debounce(() => {
    const val = document.getElementById('message-filter').value;
    setMessageFilter(val);
  }, 50);

  const getNew = () => {
      setLogs([]);
      setLogToView(null);
      setEnd(removeZ(new Date(Date.now() - tz).toISOString()));
    };

  const closeLog = () => {setLogToView(null)};

  const classes = withStyle();
  return <div className={classes.root}>
    <CssBaseline />
    <AppContainer classes={classes} title={`Logs [${logs.length}]`} match={match}>
        <Container maxWidth="md">
            <List dense>
              <ListSubheader style={{backgroundColor: 'white'}}>
                <div className={classes.appBarSpacer} />
                <Card>
                  <CardContent>
                    <Grid container item xs={12} md={12} spacing={1} justifyContent="space-between" alignItems="center">
                      <Grid item xs={6} md={4} lg={2}>
                        <TextField
                          id="level"
                          label="Level"
                          onChange={edit}
                          value={level}
                          select
                          SelectProps={{
                            name: 'level',
                            id: 'level',
                          }}
                          >
                          <MenuItem value=" ">
                            Any
                          </MenuItem>
                          <MenuItem value="info">
                            Info
                          </MenuItem>
                          <MenuItem value="warn">
                            Warning
                          </MenuItem>
                          <MenuItem value="error">
                            Error
                          </MenuItem>
                        </TextField>
                      </Grid>
                      <Grid item xs={6} md={4} lg={3}>
                        <TextField
                          id="namespace"
                          label="Namespace"
                          onChange={edit}
                          value={namespace}
                          />
                      </Grid>
                      <Grid item xs={12} md={6} lg={4}>
                        <TextField
                          id="start"
                          type="datetime-local"
                          label="Since"
                          onChange={edit}
                          value={startTime}
                          />
                      </Grid>
                      <Grid item xs={12} md={6} lg={4}>
                        <TextField
                          id="end"
                          type="datetime-local"
                          label="Until"
                          onChange={edit}
                          value={endTime}
                          />
                      </Grid>
                      <Grid item xs={6} md={3} lg={3}>
                        <TextField
                          id="delta"
                          type="number"
                          label="Focus seconds"
                          onChange={edit}
                          value={focusTime}
                          />
                      </Grid>
                      <Grid item xs={12} md={12} lg={5}>
                        <TextField
                          fullWidth
                          id="message"
                          label="Message Fetch (>4 characters)"
                          onChange={edit}
                          value={message}
                          />
                      </Grid>
                      <Grid item xs={6} md={12} lg={5}>
                        <TextField label="Message Filter"
                          id="message-filter"
                          onChange={handleFilterChange}
                          fullWidth
                          autoFocus>
                        </TextField>
                      </Grid>
                      <Grid item xs={4} md={4} lg={4}>
                        <Button onClick={getNew}>
                          Refresh
                        </Button>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </ListSubheader>
              {logs.map((log) => {
                return <MemoLog log={log}
                  expandAround={focusLog}
                  onClick={showJSON} key={log._id}
                  filter={messageFilter}/>
              })}
            </List>
          <Dialog open={!!logToView} onClose={closeLog}>
            <DialogTitle id="form-dialog-title">Log Viewer</DialogTitle>
            <ReactJson name='data'
              enableClipboard={clipEnable}
              displayDataTypes={false}
              sortKeys={true}
              src={logToView || {}}>
            </ReactJson>
          </Dialog>
          <Loader show={loading} />
        </Container>
    </AppContainer>
  </div>;
}
