import { makeStyles, createTheme } from '@material-ui/core/styles';
import red from '@material-ui/core/colors/red';


const drawerWidth =  260; //'max(min(13vw, 260px),260px)';

export default makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    borderBottom: '8px solid rgb(255,122,89)',
    marginLeft: -drawerWidth,
  },
  appBarShift: {
    width: `calc(100vw + ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(2),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft:  -drawerWidth
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0
  },
  connectList: {
    overflow: 'auto',
  },
  hide: {
    display: 'none'
  },
  menuButtonHidden: {
    display: 'none',
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  appTitle: {
    flexGrow: 1,
    marginLeft: drawerWidth,
    maxWidth: '100vw'
  },
  appTitleElement: {
    marginLeft: drawerWidth
  },
  title: {
    flexGrow: 1,
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    justifyContent: 'space-around',
    marginTop: theme.spacing(9),
  },
  appBarSpacer: theme.mixins.toolbar,
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    marginTop: theme.spacing(2),
    paddingBottom: theme.spacing(3),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 350,
    margin: theme.spacing(4)
  },
  paperChart: {
    fill: theme.palette.text.primary,
    minWidth: 100,
    margin: 10
  },
  productListItem: {
    listItemText:{
      fontSize:'0.05rem',//Insert your required size
    },
    padding: 0,
  },
  productListItemIcon: {
    marginLeft: theme.spacing(0),
    marginRight: theme.spacing(0),
    padding: 0,
  },
  productListItemIconClosed: {
    marginLeft: theme.spacing(0)
  },
  productDataHeader: {
  },
  productDataHeaderTitle: {
  },
  visCard: {
    height: 300,
    minWidth: 400,
    margin: theme.spacing(4),
    padding: theme.spacing(1),
    marginTop: theme.spacing(8),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
    alignItems: 'center',
  },
  visCardSmall: {
    minWidth: 400,
  },
  visCardLarge: {
    minWidth: '80%'
  },
  chartTooltip: {
    background: 'red'
  },
  avatar: {
    margin: theme.spacing(1),
    width: theme.spacing(5),
    height: theme.spacing(5),
    backgroundColor: '#fff',
    color: theme.palette.getContrastText('#fff')
  },
  bigAvatar: {
    minWidth: theme.spacing(22),
    minHeight: theme.spacing(22),
  },
  mediumAvatar: {
    minWidth: theme.spacing(11),
    minHeight: theme.spacing(11),
  },
  large: {
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
  smallAvatar: {
    margin: theme.spacing(2),
    maxWidth: theme.spacing(3),
    maxHeight: theme.spacing(3),
  },
  avatarGroupNoBorder: {
    border: '',
  },
  middlewarePreviewList: {
    border: '',
  },
  middlewareAvatar: {
    padding: theme.spacing(1),
    minWidth: theme.spacing(3),
    minHeight: theme.spacing(3),
  },
  loginForm: {
    width: '100%',
    marginTop: theme.spacing(3),
  },
  largeInput: {
    fontSize: '1.5rem !important'
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  iconFill: {
    width: '100%',
    height: '100%'
  },
  addCard: {
    padding: theme.spacing(1),
  },
  loader: {
    zIndex: 10000,
    minWidth: 150,
    minHeight: 150,
    position: 'fixed',
    left: 'calc(50% + 75px)',
    top: '50vh'
  },
  notificationMessage: {
    whiteSpace: 'pre-wrap',
    margin: 5,
    padding: 10,
  },
  notificationSubject: {
    whiteSpace: 'pre-wrap',
    fontWeight: 600,
    margin: 5,
    padding: 10
  },
  volumetricCard: {
    padding: theme.spacing(2),
    fontSize: '1.1rem'
  },
  map: {
    minWidth: 300,
    minHeight: 460,
    height: 460,
    maxHeight: '80vh',
    width: '100%'
  },
  productMiddlewareList: {
    maxHeight: 400,
    overflow: 'auto'
  },
  chartInfo: {
    fontSize: '1.15rem'
  },
  dateSlider: {
    fontSize: '1.1rem',
    padding: 1,
    zIndex: 1
  },
}));


// All the following keys are optional.
// We try our best to provide a great default value.
export const theme = createTheme({
  palette: {
    type: 'light',
    background: {
      default: 'rgba(0, 0, 0, 0)',
    },
    primary: {
      main: 'rgb(104,104,104)'
    },
    secondary: {
      main: 'rgb(255,144,107)'
    },
    error: red,
    // Used by `getContrastText()` to maximize the contrast between the background and
    // the text.
    contrastThreshold: 3,
    // Used to shift a color's luminance by approximately
    // two indexes within its tonal palette.
    // E.g., shift from Red 500 to Red 300 or Red 700.
    tonalOffset: 0.2,
  }
});
