import React, { useState, useEffect } from 'react';

import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Slider from '@material-ui/core/Slider';
import Tooltip from '@material-ui/core/Tooltip';
import Fade from '@material-ui/core/Fade';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Container from '@material-ui/core/Container';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

import ErrorBoundary from '../../Components/ErrorBoundary';
import AppContainer from '../../Components/AppContainer';

import PopularDaysNoProcessing from '../../Components/PopularDaysNoProcessing';
import PopularTimesNoProcessing from '../../Components/PopularTimesNoProcessing';
import TimeBetweenUseNoProcessing from '../../Components/TimeBetweenUseNoProcessing';
import InterMetricCurveNoProcessing from '../../Components/InterMetricCurveNoProcessing';
import MetricCurveNoProcessing from '../../Components/MetricCurveNoProcessing';
import UseMapNoProcessing from '../../Components/UseMapNoProcessing';
import { sum, tempOptions, weightOptions } from '../../Utils/DataUtils';

import withStyle from '../../style';
import ProductDataHeaderNoProcessing from './ProductHeader/index'
import MadeWithLove from '../../Components/MadeWithLove';

import Networker from '../../Utils/Networker';
import OverTime from '../../Components/OverTime';

const getProductStats = (productId, start, end) => {
  return Networker.get({
    root: 'products',
    inner: 'stats',
    query: {
      productId: productId,
      since: start,
      until: end,
    },
    cache: true
  });
};

const getProductAvg = (productID) => {
  return Networker.get({
    root: 'products',
    inner: productID + '/stats/avg',
    cache: true
  });
};

const getProductVal = (productID) => {
  return Networker.get({
    root: 'products',
    inner: productID + '/stats/volumetric',
    cache: true
  });
};

const getProductSummary = (productID, group, dateRangeDisplayed) => {
  // Create a Date object
  const fromDate = new Date(dateRangeDisplayed[0]);
  const toDate = new Date(dateRangeDisplayed[1]);

  // Convert to UTC
  const utcfromDate = fromDate.toUTCString();
  const utctoDate = toDate.toUTCString();

  // Check if group length is greater than 0
  if (group !== 'none') {
    // Send the query to the API with group parameter
    return Networker.get({
      root: 'products',
      inner: productID + '/stats/summary',
      query: {
        group: group,
        fromDate: utcfromDate,
        toDate: utctoDate,
      },
      cache: true
    });

  } else {
    // Send the query to the API without group parameter
    return Networker.get({
      root: 'products',
      inner: productID + '/stats/summary',
      query: {
        fromDate: utcfromDate,
        toDate: utctoDate,
      },
      cache: true
    });
  }
};

const getProduct = (productId) => {
  return Networker.get({
    root: 'products',
    query: {
      _id: productId
    },
    cache: true
  });
};

function ValueLabelComponent(props) {
  const { children, value } = props;
  const popperRef = React.useRef(null);
  React.useEffect(() => {
    if (popperRef.current) {
      popperRef.current.update();
    }
  });
  const classes = withStyle();
  const date = (new Date(value)).toLocaleDateString();
  return (
    <Tooltip
      PopperProps={{
        popperRef,
      }}
      className={classes.dateSlider}
      open={true}
      enterTouchDelay={0}
      placement={props.index === 0 ? "bottom" : 'top'}
      title={<div className={classes.dateSlider}>
        {date}
      </div>}
    >
      {children}
    </Tooltip>
  );
}

const getSession = async () => {
  try {
    const res = await Networker.getUserSession();
    return res.body;
  } catch (e) {
    return {};
  }
};

const DAY = 1000 * 60 * 60 * 24;
const SIX_MONTH = DAY * 180;
const MAX_MEASUREMENTS = 1000;

const prodRX = /productId=(?<productId>[A-z|0-9]+)/;
const requestCheck = (id, str) => {
  const res = prodRX.exec(str || '');
  if (res && res.groups) {
    const { productId } = res.groups;
    return productId === id;
  }
  return id === str;
}

function valuetext(value) {
  const date = new Date(value);
  return `${date.toLocaleDateString()}`;
}

export default function Dashboard({ match }) {
  const [loading, setLoading] = useState(false);
  const [loadingProductData, setLoadingProductData] = useState(false);
  const [loadingPlotData, setLoadingPlotData] = useState(false);
  const [product, setProduct] = useState(null);
  const [dateRange, setDateRange] = useState([0, 0]);
  const [dateRangeDisplayed, setDateRangeDisplayed] = useState([0, 0]);
  const [oldestDate, setOldestDate] = useState(0);
  const [newestDate, setNewestDate] = useState(Date.now() + DAY);
  const [isSuper, setIsSuper] = useState(false);
  const [productSummary, setProductSummary] = useState(null);
  const [headerData, setHeaderData] = useState({});
  const [amountPouredData, setAmountPouredData] = useState({});
  const [amountTemperature, setAmountTemperature] = useState({});
  const [temperatureChange, setTemperatureChange] = useState(false);
  const [amountChange, setAmountChange] = useState(weightOptions[0]?.value);
  const [popularDaysData, setPopularDaysData] = useState({});
  const [popularTimesData, setPopularTimesData] = useState([]);
  const [useMapData, setUseMapData] = useState({});
  const [timeBetweenUseData, setTimeBetweenUseData] = useState([]);
  const [agesData, setAgesData] = useState([]);
  const [ethnicityData, setEthnicityData] = useState([]);
  const [genderData, setGenderData] = useState([]);
  const [usesPerUserData, setUsesPerUserData] = useState([]);
  const [temp, setTemp] = useState(tempOptions[0]?.value);
  const [weight, setWeight] = useState(weightOptions[0]?.value);
  const [amountPouredDataOunce, setAmountPouredDataOunce] = useState();
  const [amountPouredDataMl, setAmountPouredDataMl] = useState();
  const [weightConvertorDisabled, setWeightConvertorDisabled] = useState(true);
  const [updated, setUpdated] = useState(false); // State variable to track if date range is updated
  const [tempDateRangeDisplayed, setTempDateRangeDisplayed] = useState([0, 0]);

  const [surveysCompleted, setSurveysCompleted] = useState(0);
  const [amountLeft, setAmountLeft] = useState(null);
  const [duration, setDuration] = useState(null);
  const [frequency, setFrequency] = useState(null);
  const [perLabel, setPerLabel] = useState(null);
  const [volumeUse, setVolumeUse] = useState(null);
  const [labelsHalfRefill, setLabelsHalfRefill] = useState('');
  const [labelsAtRefill, setLabelsAtRefill] = useState('');

  const [group, setGroup] = useState('none');
  const [usageOverTime, setUsageOverTime] = useState([]);

  useEffect(() => {
    if (product && !productSummary) {
      setLoadingProductData(true);  // Start loading when the data fetching starts
      const currentURL = window.location.pathname.split('/');
      if (!requestCheck(currentURL[currentURL.length - 1], product._id)) {
        return;
      }
      Networker.get({
        root: 'products',
        inner: 'summary/' + product._id,
      })
        .then(res => {
          const currentURL = window.location.pathname.split('/');
          if (!requestCheck(currentURL[currentURL.length - 1], product._id)) {
            return;
          }
          if (res.body.numMeasurements > 0) {
            const oldDate = Date.parse(res.body.oldestMeasurement);
            const newDate = Date.parse(res.body.newestMeasurement);
            setOldestDate(oldDate - DAY);
            setNewestDate(newDate + DAY);
            let dateRangeStart, dateRangeEnd;

            if (res.body.numMeasurements > MAX_MEASUREMENTS) {
              // Calculate the average time interval between measurements
              const avgTimePerMeasurement = (newDate - oldDate) / res.body.numMeasurements;
              // Calculate the display range for the last MAX_MEASUREMENTS measurements
              const displayRange = avgTimePerMeasurement * MAX_MEASUREMENTS;
              dateRangeStart = Math.max(newDate - displayRange, newDate - SIX_MONTH); // Default to show last six months if within that range
              dateRangeEnd = newDate + DAY;
            } else {
              dateRangeStart = Math.max(0, oldDate - DAY);
              dateRangeEnd = newDate + DAY;
            }
            setDateRange([dateRangeStart, dateRangeEnd]);
            setDateRangeDisplayed([dateRangeStart, dateRangeEnd]);
          }
          setProductSummary(res.body);
          setLoading(false);
          setLoadingProductData(false);
        })
        .catch(err => {
          console.error(err);
        });
    }
  }, [product, productSummary]);

  useEffect(() => {
    setLoading(true);
    setProduct(null);
    setProductSummary(null);
    getProduct(match.params.productId)
      .then(res => {
        const prod = res.body[0];
        const currentURL = window.location.pathname.split('/');
        if (requestCheck(currentURL[currentURL.length - 1], prod._id)) {
          setProduct(prod);
        }
      })
      .catch((err) => {
        console.error(err);
      })
  }, [match.params.productId]);

  useEffect(() => {
    setLoading(true);
    setLoadingPlotData(true);
    if (!productSummary || (dateRange[0] >= dateRange[1])) {
      setLoading(false);
      setLoadingPlotData(false);
      return;
    }

    getProductStats(product._id, dateRange[0], dateRange[1])
      .then(res => {
        const {
          daysOfWeek,
          hoursOfDayForDaysOfWeek,
          hoursOfDay,
          locations,
          ages,
          ethnicities,
          genders,
          usesPerUser,
          hoursBetweenUse,
          computedData,
          temperature,
        } = res.body;
        setUseMapData(locations);
        setTimeBetweenUseData(hoursBetweenUse);
        setPopularDaysData({ daysOfWeek, hoursOfDayForDaysOfWeek });
        setPopularTimesData(hoursOfDay);
        setAgesData(ages);
        setGenderData(genders);
        setEthnicityData(ethnicities);
        setUsesPerUserData(usesPerUser);
        let { amountPoured, surveysCompleted, amountPouredOunce, amountPouredMl, ...rest } = computedData;
        setAmountPouredDataMl(amountPouredMl)
        setHeaderData(rest);
        setSurveysCompleted(surveysCompleted);
        setAmountPouredData(amountPoured);
        setAmountPouredDataOunce(amountPouredOunce);
        setAmountTemperature(temperature);
        setLoading(false)
        setLoadingPlotData(false);
        setWeightConvertorDisabled(amountPoured.data.length <= 0);
      }).catch(err => {
        console.log(err);
        setHeaderData({});
        setAmountPouredData({});
        setAmountTemperature({});
        setPopularDaysData({});
        setUseMapData({});
        setPopularTimesData([]);
        setTimeBetweenUseData([]);
        setAgesData([]);
        setEthnicityData([]);
        setGenderData([]);
        setUsesPerUserData([]);
        setSurveysCompleted(0);
        setLoading(false);
        setLoadingPlotData(false);
        setWeightConvertorDisabled(true);
      });
  }, [dateRange, productSummary, product]);

  useEffect(() => {
    getSession().then(({ isSuperUser }) => {
      setIsSuper(isSuperUser);
    });
  })

  // Adding for Avg
  useEffect(() => {
    setLoading(true);
    setLoadingPlotData(true);
    if (!productSummary || (dateRange[0] >= dateRange[1])) {
      setLoading(false);
      return;
    }
    getProductAvg(product._id).then((res) => {
      setLoading(false)
      const { usageFrequency, usesPerLabel } = res.body;
      setFrequency(usageFrequency !== null ? Math.round(usageFrequency / 86400) : null);
      setPerLabel(usesPerLabel !== null ? Math.round(usesPerLabel) : null);
      setLoadingPlotData(false);
    }).catch((err) => {
      console.log(err);
      setLoading(false);
      setLoadingPlotData(false);
    });
  }, [product, productSummary, dateRange]);

  // Adding for Volumetric
  useEffect(() => {
    setLoading(true);
    setLoadingPlotData(true);
    if (!productSummary || (dateRange[0] >= dateRange[1])) {
      setLoading(false);
      return;
    }
    getProductVal(product._id).then((res) => {
      setLoading(true)
      const { volumePerUse, volumeRemaining, labelsAtHalfRefill, labelsAtRefill, lifetime, density } = res.body;
      setDuration(lifetime !== null ? Math.round(lifetime/ 86400) : null);
      setLabelsHalfRefill(labelsAtHalfRefill !== null ? Math.round(labelsAtHalfRefill) : null);
      setLabelsAtRefill(labelsAtRefill !== null ? Math.round(labelsAtRefill) : null);
      setAmountLeft(volumeRemaining !== null ? {
        ounce: ((volumeRemaining / density) * 0.033814).toFixed(2),
        ml: (volumeRemaining / density).toFixed(2),
        grams: volumeRemaining.toFixed(0)
      } : {})
      setVolumeUse(volumePerUse !== null ? {
        ounce: ((volumePerUse / density) * 0.033814).toFixed(2),
        ml: (volumePerUse / density).toFixed(2),
        grams: volumePerUse.toFixed(2)
      } : {});
      setLoadingPlotData(false);
    }).catch((err) => {
      console.log(err);
      setAmountLeft(null);
      setVolumeUse(null);
      setDuration(null);
      setLabelsHalfRefill(null);
      setLabelsAtRefill(null);
      setLoading(false);
      setLoadingPlotData(false);
    });
  }, [product, productSummary, dateRange]);

  // Adding for Summary 
  useEffect(() => {
    if (!productSummary || (dateRange[0] >= dateRange[1])) {
      setLoading(false);
      return;
    }
    getProductSummary(product._id, group, dateRangeDisplayed).then((res) => {
      setLoading(true);
      setLoadingPlotData(true);
      const body = res.body;
      // Extracting summary data and formatting it
      const data = body.flatMap(entry => {
        const summaryData = entry.summary;
        const parts = entry.day.split("-"); // Splitting the date string by "-"
        const year = parts[0];
        const month = parts[1];
        const date = parts[2];
        const formattedDate = `${date}/${month}/${year}`; // Formatting the date as "date/month/year"

        return Object.keys(summaryData).map(key => ({
          day: formattedDate,
          summary: key,
          count: summaryData[key]
        }));
      });
      // Sort the data array by day in ascending order
      const sortData = data.sort((a, b) => {
        const [dayA, monthA, yearA] = a.day.split('/').map(Number);
        const [dayB, monthB, yearB] = b.day.split('/').map(Number);
        return new Date(yearA, monthA - 1, dayA) - new Date(yearB, monthB - 1, dayB);
      });
      setUsageOverTime(sortData);
      setLoading(false);
      setLoadingPlotData(false);
    }).catch((err) => {
      console.log(err);
      setLoading(false);
      setLoadingPlotData(false);
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateRange, productSummary, product, group]);

  const handleStartEndChange = () => {
    Networker.interactionBeacon({
      message: 'product range change commit',
      data: {
        product: product._id
      }
    });
    if (tempDateRangeDisplayed[0] === tempDateRangeDisplayed[1]) {
      setDateRange([tempDateRangeDisplayed[0], tempDateRangeDisplayed[1] + DAY]);
    } else {
      setDateRange(tempDateRangeDisplayed);
    }
    setDateRangeDisplayed(tempDateRangeDisplayed); // Update dateRangeDisplayed with the temporary value
    setUpdated(false); // Reset updated to false after applying the date range
  };

  const handleRangeChange = (event, newValue) => {
    setTempDateRangeDisplayed(newValue); // Update temporary value of dateRangeDisplayed
    setUpdated(true); // Set updated to true when the slider value changes
  };

  // const handleSliderChangeCommitted = (event, newValue) => {
  //   // Do nothing here, as we want to update dateRangeDisplayed only when the "Update View" button is clicked
  // };


  const classes = withStyle();
  // const updated = dateRange[0] === dateRangeDisplayed[0] && (dateRange[1] === dateRangeDisplayed[1]);

  const temperatureCheck = () => {
    setTemperatureChange(true);
  }

  const handleChange = (event) => {
    setTemp(event.target.value);
    if (event.target.value === 'fahrenheit') {
      setTemperatureChange(true);
    } else {
      setTemperatureChange(false);
    }
  };

  const weightHandle = (event) => {
    setWeight(event.target.value);
    if (event.target.value === 'ounce') {
      setAmountChange('ounce');
    }
    if (event.target.value === 'ml') {
      setAmountChange('ml');
    }
    if (event.target.value === 'grams') {
      setAmountChange('grams');
    }
  };

  // Callback function to handle group selection changes
  const handleGroupChange = (selectedGroup) => {
    // Update the group state with the selected group 
    setGroup(selectedGroup);
  };

  return <div className={classes.root}>
    <CssBaseline />
    <AppContainer classes={classes}
      title={`Adrich Insights`}
      match={match}>
      <div className={classes.appBarSpacer} />
      <div>
        <ErrorBoundary>
          <Grid container justifyContent="center" alignItems="flex-start" spacing={3}>
            <Fade in={!loadingProductData} timeout={{ enter: 100, exit: 60 }}>
              <Grid item xs={10}>
                <Card>
                  <CardContent>
                    <Grid container spacing={4} justifyContent="center" alignItems="center">
                      <Grid item xs={10}>
                        <Typography id="range-slider" gutterBottom>
                          Date Range
                        </Typography>
                        {loadingProductData ? null : <Slider
                          disabled={loading}
                          defaultValue={dateRangeDisplayed}
                          valueLabelDisplay='on'
                          min={oldestDate}
                          max={newestDate}
                          ValueLabelComponent={ValueLabelComponent}
                          step={1000 * 60 * 60 * 24}
                          onChange={handleRangeChange}
                          // onChangeCommitted={handleSliderChangeCommitted} // Call handleSliderChangeCommitted when slider value is committed
                          aria-labelledby="range-slider"
                          getAriaValueText={valuetext}
                        />}
                      </Grid>
                      <Grid item>
                        <Button
                          disabled={!updated} // Disable the button if date range is not updated
                          onClick={handleStartEndChange} variant="contained"
                          color="secondary">
                          Update View
                        </Button>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
            </Fade>
            <Fade in={!loadingProductData} timeout={{ enter: 200, exit: 200 }}>
              <Grid item xs={10}>
                <ProductDataHeaderNoProcessing
                  isSuperUser={isSuper}
                  computedStatsData={headerData.data}
                  surveysCompleted={surveysCompleted}
                  N={headerData.N !== undefined ? headerData.N : 0}
                  since={dateRangeDisplayed[0]}
                  until={dateRangeDisplayed[1]}
                  summary={productSummary}
                  amountChange={amountChange}
                  product={product}
                  duration={duration}
                  frequency={frequency}
                  perLabel={perLabel}
                  volumeUse={volumeUse}
                  labelsHalfRefill={labelsHalfRefill}
                  labelsAtRefill={labelsAtRefill}
                  dateRangeDisplayed={dateRangeDisplayed}
                  amountLeft={amountLeft}
                />
              </Grid>
            </Fade>
          </Grid>
          <Fade in={!loadingProductData} timeout={{ enter: 500, exit: 200 }}>
            <Container maxWidth="lg">
              <Grid container spacing={4} justifyContent="center" alignItems="center">
                <Grid item style={{ marginRight: '16px' }}>
                  Weight/Volume Units :
                  <Select
                    value={weight}
                    onChange={weightHandle}
                    className={classes.selectEmpty}
                    inputProps={{ 'aria-label': 'Without label' }}
                    style={{ marginLeft: '16px' }}
                    disabled={weightConvertorDisabled}
                  >
                    {weightOptions.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
                <Grid item style={{ marginLeft: '16px' }}>
                  Temperature Units:
                  <Select
                    value={temp}
                    onChange={handleChange}
                    className={classes.selectEmpty}
                    inputProps={{ 'aria-label': 'Without label' }}
                    style={{ marginLeft: '16px' }}
                  >
                    {tempOptions.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
              </Grid>
              <Fade in={!loadingPlotData} timeout={{ enter: 500, exit: 200 }}>
                <Grid container spacing={4} justifyContent="center" alignItems="center">
                  <Grid item xs={12} md={12}>
                    <OverTime
                      title='Usage Over Time'
                      alert={usageOverTime && usageOverTime.length < 1 && 'This graph currently does not have any data'}
                      subTitle='This Product was most used on week 4 by Women and on Week 2 by Men'
                      data={usageOverTime}
                      onGroupChange={handleGroupChange} // Pass the callback function as a prop
                      group={group}
                    />
                  </Grid>
                </Grid>
              </Fade>
              {!loadingPlotData ? <Grid container spacing={4}
                justifyContent="center" alignItems="flex-start">
                {!popularDaysData.daysOfWeek || (popularDaysData && popularDaysData.daysOfWeek && sum(popularDaysData.daysOfWeek.map(day => day['uses'])) < 1) ? null : (
                  <Grid item xs={12} md={6} >
                    <PopularDaysNoProcessing daysOfWeek={popularDaysData.daysOfWeek} hoursOfDayForDaysOfWeek={popularDaysData.hoursOfDayForDaysOfWeek} />
                  </Grid>
                )}
                {popularTimesData && sum(popularTimesData.map(day => day['uses'])) < 1 ? null : (<Grid item xs={12} md={6}>
                  <PopularTimesNoProcessing data={popularTimesData} />
                </Grid>)}
                {usesPerUserData && usesPerUserData.length < 1 ? null : <Grid item xs={12} md={6}>
                  <MetricCurveNoProcessing
                    title="Uses"
                    positive
                    showAverage
                    hideAxis
                    averageString={"Average uses per user when product in use: "}
                    emptyAverageString={"Average uses per user when empty: "}
                    data={usesPerUserData}
                    isCategorical
                  />
                </Grid>}
                {agesData && agesData.length < 1 ? null : <Grid item xs={12} md={6}>
                  <MetricCurveNoProcessing
                    title="User Age"
                    positive
                    data={agesData}
                    isCategorical={agesData && Object.values(agesData).filter(el => typeof (el.value) === 'number').length <= 1}
                  />
                </Grid>}
                {genderData && genderData.length < 1 ? null : <Grid item xs={12} md={6}>
                  <MetricCurveNoProcessing
                    title="Gender"
                    positive
                    data={genderData}
                    isCategorical
                  />
                </Grid>}
                {ethnicityData && ethnicityData.length < 1 ? null : <Grid item xs={12} md={6}>
                  <MetricCurveNoProcessing
                    title="Ethnicity"
                    positive
                    data={ethnicityData}
                    isCategorical
                  />
                </Grid>}
                {amountPouredData && amountPouredData.data && amountPouredData.data.length < 1
                  ? null
                  : <Grid item xs={12} md={6}>
                    <InterMetricCurveNoProcessing
                      title="Average Amount Poured"
                      data={(amountChange === "ounce") ? amountPouredDataOunce.data : (amountChange === "ml") ? amountPouredDataMl.data : amountPouredData.data}
                      average={(amountChange === "ounce") ? (amountPouredDataOunce.average).toFixed(2) : (amountChange === "ml") ? amountPouredDataMl.average : amountPouredData.average}
                      showAverage
                      const unit={(amountChange === "ounce") ? "ounce" : (amountChange === "ml") ? "ml" : "gm"}
                      positive
                      temperatureCheck={temperatureCheck}
                      averageString="Amount Poured Per Use: " />
                  </Grid>}

                {!amountTemperature?.fahrenheit?.data?.length || !amountTemperature?.celsius?.data?.length
                  ? null
                  :
                  <Grid item xs={12} md={6}>
                    <InterMetricCurveNoProcessing
                      title="Average Ambient Temperature"
                      data={temperatureChange ? amountTemperature?.fahrenheit?.data : amountTemperature?.celsius?.data}
                      average={temperatureChange ? amountTemperature?.fahrenheit?.average.toFixed(2) : amountTemperature?.celsius?.average.toFixed(2)}
                      showAverage
                      unit={temperatureChange ? "°F" : "°C"}
                      xAxisLabel={"Temperature"}
                      yAxisLabel={"Number of Uses"}
                      positive
                      hoverSpacing={2}
                      averageString="Avg. Temp Per Use: " />
                  </Grid>}
                {!useMapData.all || (useMapData.all && useMapData.all.length < 1) ? null : <Grid item xs={12} md={6}>
                  <UseMapNoProcessing points={useMapData} />
                </Grid>}
                {timeBetweenUseData && timeBetweenUseData.length < 1 ? null : <Grid item xs={12} md={6}>
                  <TimeBetweenUseNoProcessing data={timeBetweenUseData} />
                </Grid>}
              </Grid> : <Typography variant="h4">Loading</Typography>
              }
            </Container>
          </Fade>
          <MadeWithLove />
        </ErrorBoundary>
      </div>
    </AppContainer>
  </div>;
}
