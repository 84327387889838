import React, {useState} from 'react';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import {default as MaterialTooltip} from '@material-ui/core/Tooltip';
import Avatar from '@material-ui/core/Avatar';
import Grid from '@material-ui/core/Grid';
import {
  BarChart,
  YAxis,
  XAxis,
  Bar,
  Label,
  ResponsiveContainer,
  Tooltip
} from 'recharts';
import {getCreatedAtFromObjectId} from '../Utils/DataUtils';

import withStyle from '../style';
import TextField from '@material-ui/core/TextField';
import Box from '@material-ui/core/Box';

const COLORS = [
  '#f00a',
  '#a40a',
  '#671a',
  '#494a',
  '#4f4f'
];

const getFillFromPercent = (percent) => {
  if (percent >= 100) {
    return COLORS[COLORS.length -1];
  }
  const roundedIndex = Math.floor(COLORS.length * percent / 100);
  return COLORS[roundedIndex];
};

export default function VolumetricItem(props) {
  const email = localStorage.getItem("userEmail");
  const isShowCalculator = email?.includes("adrich.io");
  const {
    mac, lastMeasurement, numMeasurements, maxAmount, onDelete,
  } = props;
  const [actualValue , setActualValue]=useState(null);

  const handleAccuracy = () =>{
    if(typeof actualValue !== "number") return ""
    const predictedValue = +props?.previousCompute?.amountLeft.toFixed(1);
    const errorPercentage =(100*Math.abs(actualValue-predictedValue)/maxAmount);
    return (100 - errorPercentage).toFixed(2)
  }

  const handleActualValue = (e)=>{
    if(e.target.valueAsNumber > maxAmount) return
    isNaN(e.target.valueAsNumber) ? setActualValue(null) :  setActualValue(e.target.valueAsNumber)
  }

  const maxFill = maxAmount || 1500;
  if (!lastMeasurement || !mac) {
    return null;
  }
  const prod = lastMeasurement.productId;
  const computedData = (lastMeasurement.computedData || {});
  const newAmountLeft = typeof (computedData || {}).amountLeft === 'undefined' ? +maxAmount : +(computedData || {}).amountLeft;
  const classes = withStyle();
  const datTransformed = [{
    percentLeft: (Math.min(1.0, newAmountLeft / maxFill) * 100).toFixed(2),
    mac
  },];
  const dateUsed = getCreatedAtFromObjectId(lastMeasurement._id);
  const dateStr = `${dateUsed.toLocaleString()}`;
  const amountLeftOZ = String(newAmountLeft.toFixed(1)) || '-';

  return (
  <>
  <Card className={classes.volumetricCard}>
      <Grid container spacing={2} alignItems="center" justifyContent="center">
        <Grid item xs={12} md={4} container alignItems="center" justifyContent="space-around">
          <Grid item xs={12}>
            <Typography variant="h4" color="textPrimary" gutterBottom>
              {prod.name}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <MaterialTooltip title="Reset label">
              <Avatar alt={prod.name} src={prod.iconURL}
                  style={{cursor: 'pointer'}}
                  className={classes.mediumAvatar} onClick={onDelete}>
                {prod.name.substr(0, 1)}
              </Avatar>
            </MaterialTooltip>
          </Grid>
          <Grid item xs={8} container justifyContent="center" alignItems="center">
            <Grid item xs={12}>
              <Typography variant="h5">
                {numMeasurements}
              </Typography>
              <Typography variant="subtitle1" color="textSecondary">
                Use{numMeasurements !== 1 ? 's' : ''}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h5">
                {amountLeftOZ}
              </Typography>
              <Typography variant="subtitle1" color="textSecondary">
                Grams left
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h5">
                {dateStr}
              </Typography>
              <Typography variant="subtitle1" color="textSecondary">
                Last Used
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={8} container
          justifyContent="center" alignItems="flex-end" style={{marginTop:"auto"}}>
          <Grid item xs={12} >
            <ResponsiveContainer width="99%" height={125} style={{minWidth: 100}}>
              <BarChart
                data={datTransformed}
                layout="vertical"
                margin={{ top: 25, right: 25, left: 100, bottom: 15 }}
                className={classes.paperChart}>
                <XAxis type="number" domain={[0, 100]}>
                  <Label value={`${datTransformed[0].percentLeft} % Left`}
                    offset={-15} position="insideBottom" />
                </XAxis>
                <YAxis dataKey="mac" type="category">
                  <Label value="Label ID"
                    offset={-15} position="insideTop" />
                </YAxis>
                <Tooltip />
                <Bar name="Remaining: "
                  unit="%"
                  minPointSize={0}
                  fill={getFillFromPercent(datTransformed[0].percentLeft)}
                  dataKey="percentLeft" />
              </BarChart>
            </ResponsiveContainer>
            {isShowCalculator && 
            <Box  className="amountConatiner">
            <Box  className="amountField">
            <Typography variant="h5">Actual Amount Left (g):</Typography>
            <TextField  type="number" name="actualValue" value={actualValue} onChange={handleActualValue} id="filled-basic" variant="filled"  className="amountTextFiled" InputProps={{
        disableUnderline: true, max:maxAmount
      }}  />  </Box>
            <Box className="accuracyContainer">
            <Typography variant="h5">Accuracy:</Typography>
            <Typography variant="p" style={{marginLeft:10, fontSize:"larger"}} >{handleAccuracy(+datTransformed[0]?.percentLeft)}</Typography>
            </Box>
            </Box> }
          </Grid>
        </Grid>
      </Grid>
    </Card>
    <style jsx>{`
    .amountField > div > div > input{
      padding: 1rem;
    }  
    .amountField{
      display:flex;
      align-items:center;
      width:50%;
    }
    .amountConatiner{
      display:flex; 
      align-items:center;
      width:100%;
      justify-content:space-evenly;
      padding-top:35px;
    }
    .amountTextFiled{
      width:7rem;
      border:none;
      outline:none;
      margin:0px 10px;
    }
    .accuracyContainer{
      display:flex;
      align-items:center;
      width:50%;
    }
    
    `}</style>
    </>
  )
}
