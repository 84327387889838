import React from 'react';

import { XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer  } from 'recharts';
import { BarChart, Bar } from 'recharts';

import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import Grid from '@material-ui/core/Grid';
import InfoIcon from '@material-ui/icons/Info';

import Typography from '@material-ui/core/Typography';

import withStyle from '../style';
import { useTheme } from '@material-ui/styles';

export default function TimeBetweenUseNoProcessing({data}) {
  
  const classes = withStyle();
  const theme = useTheme();
  const fillColor = theme.palette.secondary.light;
  const strokeColor = theme.palette.secondary.dark;
  
  return !data.data || data.data.length < 1 || data.average === undefined || data.sdev === undefined ? null : (<Card className={classes.paper}>
        <Typography variant="h5">
          Average Hours Between Use
        </Typography>
        <ResponsiveContainer height={ 220 }>
          <BarChart  data={ data.data }>
           <XAxis dataKey="value"/>
           <YAxis/>
           <CartesianGrid strokeDasharray='3 3'/>
           <Tooltip></Tooltip>
           <Bar dataKey="frequency" fill={fillColor} stroke={strokeColor}/>
          </BarChart >
        </ResponsiveContainer>
        <CardActionArea className={classes.chartInfo}>
          <Grid container alignItems="center" justifyContent="center">
            <InfoIcon /> &nbsp;
            This product is used every { data.average.toFixed(1) } +/- {data.sdev.toFixed(1)} hours.
            {data.N > 70 ? '' : ' (insufficient data) '}
          </Grid>
        </CardActionArea>
      </Card>
  );
}
