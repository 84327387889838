import React from 'react';

import {
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer
} from 'recharts';
import { AreaChart, Area } from 'recharts';

import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import CardActionArea from '@material-ui/core/CardActionArea';

import InfoIcon from '@material-ui/icons/Info';

import withStyle from '../style';
import { useTheme } from '@material-ui/styles';

export default function InterMetricCurveNoProcessing({
  title,
  data,
  average,
  showAverage,
  averageString,
  unit,
  xAxisLabel,
  yAxisLabel,
  hoverSpacing
}) {
  const isDate = false;

  const spacing =
    hoverSpacing ||
    (!data || data.length < 2 || isDate ? 1 : data[1].value - data[0].value);

  const classes = withStyle();
  const theme = useTheme();
  const fillColor = theme.palette.secondary.light;
  const strokeColor = theme.palette.secondary.dark;

  
  return !data || data.length < 1 ? null : (
    <Card className={classes.paper}>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Typography variant="h5">
          {title || (isDate ? 'Time Difference (hours)' : 'Inter Use Trend')}
        </Typography>
      </div>
      <ResponsiveContainer height={220}>
        <AreaChart data={data}>
          <XAxis
            dataKey="value"
            type="number"
            label={{ value: xAxisLabel, position: 'insideBottom', offset: '-3', dy: 0 }}
            axisLine
            tickFormatter={(val) => (val ? parseFloat(val).toFixed(2) : 0.0)}
            unit={` ${unit}`}
            domain={['dataMin', 'dataMax']}
          />
          <YAxis label={{ value: yAxisLabel, position: 'inside', angle: -90, dx: -15 }} />
          <CartesianGrid strokeDasharray="3 3" />
          <Tooltip
            labelFormatter={(val) => {
              return `${val} - ${(val + spacing)} ${unit || ''}`;
            }}
          ></Tooltip>
          <Area type="monotone" dataKey="frequency" fill={fillColor} stroke={strokeColor} interval="preserveStartEnd" />
        </AreaChart>
      </ResponsiveContainer>
      {showAverage && data?.length && average !== undefined ? (
        <CardActionArea className={classes.chartInfo}>
          <Grid container alignItems="center" justifyContent="center" style={{ marginTop: '8px' }}>
            <InfoIcon /> &nbsp;
            {averageString || 'Avg: '}&nbsp;{Math.round(average * 100) / 100}&nbsp; {unit}
          </Grid>
        </CardActionArea>
      ) : null}
    </Card>
  );
}
