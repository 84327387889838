import React from 'react';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Link from '@material-ui/core/Link';

import {Redirect} from 'react-router-dom';
import {Link as RouterLink} from 'react-router-dom';
import Networker from '../Utils/Networker';
import MadeWithLove from '../Components/MadeWithLove';

import withStyle from '../style';
import Loader from '../Components/Loader';

export default function ResetPassword({match}) {
  const [isLoggedIn, setIsLoggedIn] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [password, setPassword] = React.useState('');
  const [email, setEmail] = React.useState('');
  const token = match.params.token;

  const setField = (e) => {
    if (e.target.id === 'password') {
      setPassword(e.target.value);
    } else if (e.target.id === 'email') {
      setEmail(e.target.value);
    }
  };

  const onResetClick = (e) => {
    e.preventDefault();
    setLoading(true);
    Networker.resetPassword({email, password, token})
    .then((res) => {
      setIsLoggedIn(true);
      setLoading(false);
    })
    .catch((err) => {
      setIsLoggedIn(false);
      setLoading(false);
      Networker.sendError({
        message: 'reset password error',
        namespace: 'dashboard.resetpassword',
        data: err
      });
    });
  };

  const classes = withStyle();

  return isLoggedIn ? <Redirect to={"/account"}/> : (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
        <center style={{margin: '10vh'}}>
          <img src="/logo.png" width="100%" alt="Adrich Dashboard Logo">
          </img>
        </center>
        <div className={classes.paper}>
          <Typography component="h1" variant="h4">
            Sign In
          </Typography>
        <form className={classes.form} noValidate onSubmit={onResetClick}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Confirm Your Email Address"
            name="email"
            autoComplete="email"
            autoFocus
            onChange={setField}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Enter Your New Password"
            type="password"
            id="password"
            autoComplete="current-password"
            onChange={setField}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            Reset My Password
          </Button>
          <Grid container>
            <Grid item>
              <Link component={RouterLink} to="/register" variant="body2">
                Don't have an account? Sign Up
              </Link>
            </Grid>
          </Grid>
        </form>
      </div>
      <Box mt={5}>
        <MadeWithLove />
      </Box>
      <Loader show={loading}/>
    </Container>
  );
}
