import React from 'react';

import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import Button from '@material-ui/core/Button';
import CardHeader from '@material-ui/core/CardHeader';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Avatar from '@material-ui/core/Avatar';
import Divider from '@material-ui/core/Divider';
import EditIcon from '@material-ui/icons/Edit'
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import StarIcon from '@material-ui/icons/Star';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import Networker from '../Utils/Networker';
import withStyle from '../style';


const TIER_DESCRIPTIONS = {
  'CAPTURE': 'Basic package which provides basic usage data in real time and access to the basic dashboard',
  'CONNECT': 'Adds the ability to create consumer connect triggers',
  'VOLUMETRIC': 'Adds the ability to track volumetric usage data',
  'ANALYTICS': 'All inclusive package which provides in-depth analysis capabilities and the ability to merge with other data sources'
};

function saveOrg(orgId, tier, connectionStatsAccess) {
  if (Object.keys(TIER_DESCRIPTIONS).includes(tier)) {
    return Networker.put({
      root: 'organizations',
      body: {
        _id: orgId,
        tier,
        connectionStatsAccess
      }
    });
  }
  return Promise.reject();
}

export default React.memo(function OrganizationCard({org, onEdit, users, onUpgrade}) {
  const classes = withStyle();
  const numAdmins = org.admins.length;
  const numUsers = (users || []).length;
  const [isadmin, setisadmin] = React.useState(false);
  const [isSuperAdmin, setIsSuperAdmin] = React.useState(false);
  // const [connectionStatsAccess, setConnectionStatsAccess] = React.useState(org.connectionStatsAccess); 

  React.useEffect(() => {
    Networker.getUserSession()
    .then(res => {
      if (res.body.isSuperUser) {
        setIsSuperAdmin(true);
        setisadmin(true);
      } else {
        const userid = res.body._id;
        if (org.admins.includes(userid)) {
          setisadmin(true);
        }
      }
    });
  }, [org])

  return <Card className={classes.paper}>
    <CardContent>
      <CardHeader
        avatar={
          <Avatar alt={org.name} src={org.iconURL}>
            {org.name.substr(0, 1)}
          </Avatar>
        }
        title={<Typography variant="h5">{org.name}</Typography>}
        action={
          isSuperAdmin ? <Button className={classes.iconFill} onClick={onEdit} id={org._id}>
            Edit&nbsp;<EditIcon fontSize="small"/>
        </Button> : null
        }
        subheader={isadmin ? 'Admin' : 'Member'}>
      </CardHeader>
      <CardContent>
        <Typography variant="subtitle1">
          Contact: <a href={'mailto:' + org.email}>{org.email}</a>
        </Typography>
        <Typography variant="body1">
          {org.privacyPolicyURL}
        </Typography>
        <Divider />
        {isadmin ? <Typography variant="body2" >
        Product Users: ({numUsers - numAdmins})&nbsp; Admins: ({numAdmins})
        </Typography> : null}
      </CardContent>
      <CardActions>
        <List dense disablePadding>
          {Object.entries(TIER_DESCRIPTIONS).map(([tier, desc]) => {
            return (<ListItem
              key={tier}
              button={isSuperAdmin}
              disabled={!isSuperAdmin && (tier !== org.tier)}
              onClick={e => {
                if (!isSuperAdmin) {
                  return true;
                }
                if(window.confirm(`Are you sure you want to change ${
                  org.name
                } to ${tier}?`)) {
                  saveOrg(org._id, tier)
                  .then(() => {
                    onUpgrade();
                  })
                }
              }}>
              {org.tier === tier ? <ListItemIcon>
                <StarIcon />
              </ListItemIcon> : null}
              <ListItemText
                primary={tier}
                secondary={desc}>
              </ListItemText>
            </ListItem>);
          })}
        </List>
      </CardActions>
      
      <FormControlLabel
        control={<Checkbox id="connectionStatsAccess"
        checked={org.connectionStatsAccess}
        value="connectionStatsAccess"
        color="primary" />}
        onChange={(e)=>{
          if (!isSuperAdmin) {
            return true;
          }
          if(window.confirm(`Are you sure you want to change ${
            org.name
          } to ${e.target.checked ? 'have connection statistics access' : 'not have connection statistics access'}?`)) {
            saveOrg(org._id, org.tier, e.target.checked).then(() => {
              onUpgrade();
            });
          }
        }}
        label="Connection Statistics Access."
      />
      <br/>
      {isSuperAdmin ? <Typography variant="caption">ID: {org._id}</Typography> : null}
    </CardContent>
    </Card>;
});
