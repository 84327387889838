import React, {useEffect, useState} from 'react';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardActions from '@material-ui/core/CardActions';
import TextField from '@material-ui/core/TextField';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';

import withStyle from '../style';

const defaultFields = ['amountLeft','amountPoured'];

export default function MeasurementEditor({measurement, onEdit}) {
  const {
    _id, mac, measurementTimeUTC, measuredData, productId
  } = measurement;

  const [fields, setFields] = useState([]);
  const [fp, setFp] = useState(false);
  const [bg, setBg] = useState(false);

  useEffect(() => {
    const keys = Object.keys(Object.assign({}, measuredData));
    const needsKeys = !keys.every((elem, idx) => fields.includes(elem));
    if (needsKeys) {
      defaultFields.forEach(field => {
        if (!keys.includes(field))
          keys.push(field);
      });
      setFields(keys.sort());
    }
    const falsePositive = (measuredData || {}).falsePositive;
    setFp(falsePositive);
  }, [fields, measuredData]);

  useEffect(() => {
    setBg(fp ? '#e553' : '#fff');
  }, [fp]);

  const prod = productId;
  const classes = withStyle();

  return <Card className={classes.volumetricCard} style={{
      backgroundColor: bg
    }}>
    <CardHeader
      avatar={
          <Avatar alt={prod.name} src={prod.iconURL}
              className={classes.bigAvatar}>
            {prod.name.substr(0, 1)}
          </Avatar>
      }
      title={<Typography variant="h5">
        {prod.name}
      </Typography>}
      subheader={
        <React.Fragment>
            <Typography variant="subtitle1" color="textPrimary" gutterBottom>
              Label ID: {mac}
            </Typography>
            <Typography variant="body2" color="textSecondary" gutterBottom>
              Measurement ID: {_id.substr(16,18)} {fp ? '(false positive)' : ''}
            </Typography>
            <Typography variant="body2" color="textSecondary" gutterBottom>
              Time: {new Date(measurementTimeUTC).toLocaleString()}
            </Typography>
        </React.Fragment>
      }>
    </CardHeader>
    <CardActions>
      {fields.sort().filter(e => e !== 'falsePositive').map(name => {
        return <TextField label={name} key={name}
          id={name + measurement._id}
          variant="filled"
          onChange={(e) => {
            e.preventDefault();
            onEdit(name, e.target.value);
          }}
          onFocus={(e) => {
            e.target.select();
          }}
          defaultValue={(measuredData || {})[name] || ''}>
        </TextField>})}
          <Grid container justifyContent="center" alignItems="center">
            <Grid item xs={12}>
              <Button onClick={() => {
                  const fieldCopy = fields;
                  const hasAmount = fields.indexOf('amountLeft')> -1;
                  if (hasAmount) {
                    onEdit('amountLeft', 0.0);
                  } else {
                    const newFields = fieldCopy.concat('amountLeft');
                    setFields(newFields);
                  }
                }}>
                Reset Measured Amount
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Button onClick={() => {
                  const fieldCopy = fields;
                  const hasAmount = fields.includes('amountPoured');
                  if (hasAmount) {
                    onEdit('amountPoured', 0.0);
                  } else {
                    const newFields = fieldCopy.concat('amountPoured');
                    setFields(newFields);
                  }
                }}>
                Reset Poured Amount
              </Button>
            </Grid>
            <Grid item xs={12}>
              {fp ? null : <Button onClick={() => {
                setFp(true);
                onEdit('falsePositive', 1);
              }}>
              Mark False Positive
            </Button>}
            </Grid>
            <Grid item xs={12}>
              {fp ? <Button onClick={() => {
                setFp(false);
                onEdit('falsePositive', 0);
              }}>
                Mark Real Measurement
              </Button> : null}
            </Grid>
          </Grid>
    </CardActions>
    </Card>;
};
