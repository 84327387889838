import React, { useState, useEffect } from 'react';

import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import Tooltip from '@material-ui/core/Tooltip';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import DownloadIcon from '@material-ui/icons/CloudDownload'

import Loader from '../Components/Loader';
import AppContainer from '../Components/AppContainer';
import withStyle from '../style';

import Networker from '../Utils/Networker';

import {downloadObjectData, camelToRead} from '../Utils/DataUtils';


export default function Admin({match}) {
  const [stats, setStats] = useState(null);
  const [dashboardstats, setInteractions] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    Networker.get({
      root: 'admin',
      inner: 'stats',
      cache: true,
    })
    .then(res => {
      setLoading(false);
      setStats(res.body);
    })
    .catch(err => {
      console.error(err);
      setLoading(false);
      Networker.sendError({
        message: 'admin page error',
        namespace: 'dashboard.account',
        data: err
      });
    });
    Networker.get({
      root: 'admin',
      inner: 'dashboardstats',
      cache: true,
    })
    .then(res => {
      setInteractions(res.body);
    })
    .catch(err => {
      console.error(err);
      Networker.sendError({
        message: 'admin page error',
        namespace: 'dashboard.account',
        data: err
      });
    })
  }, []);

  const classes = withStyle();
  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppContainer classes={classes} title="Admin Stats" match={match}>
        <div className={classes.appBarSpacer} />
        {stats ? <Grid container alignItems="center" spacing={2}>
          <Grid item xs={12}>
          <a href="https://gitlab.com/adrichdev/software/adrich/commits/development">
            <img alt="gitlab pipeline status"
              src="https://gitlab.com/adrichdev/software/adrich/badges/development/pipeline.svg"
              /></a>
            </Grid>
            <Grid item xs={12}>
            <a
              href="https://gitlab.com/adrichdev/software/adrich/commits/development">
              <img alt="gitlab coverage report"
                src="https://gitlab.com/adrichdev/software/adrich/badges/development/coverage.svg" />
            </a>
          </Grid>
        <Typography variant="h4">Daily Statistics</Typography>
         <Grid item xs={12} container alignItems="flex-start" spacing={1}>
          {Object.entries(stats.stats).map(([key, num]) => {
            return <Grid item key={key}>
              <Card className={classes.paper}>
                <Typography variant="subtitle1">{camelToRead(key)}</Typography>
                <Typography variant="body1">{num}</Typography>
              </Card>
            </Grid>;
          })}
          </Grid>
          <Grid item xs={12} container alignItems="flex-start" spacing={2}>
            <Typography variant="h4">All-Time Dashboard Interactions</Typography>
             <Grid item xs={12} container alignItems="flex-start" spacing={1}>
           {(dashboardstats).map(({_id, userCount, total, users}) => {
             return <Grid item key={_id}>
               <Card className={classes.paper} style={{backgroundColor: '#8aa5'}}>
                 <Typography variant="subtitle1">{camelToRead(_id)}</Typography>
                 <Typography variant="body1">Total: {total}</Typography>
                   <Tooltip title={<List disablePadding subheader="Users">
                       {users.map(user => {
                         return <ListItem key={user}>
                           {user}
                         </ListItem>
                       })}
                     </List>}>
                     <Typography variant="body2">Unique Users: {userCount}</Typography>
                   </Tooltip>
               </Card>
             </Grid>;
           })}
           </Grid>
           </Grid>
          <Divider/>
          <Grid item xs={12} container alignItems="flex-start" spacing={2}>
            <Typography variant="h4">All-Time Statistics</Typography>
             <Grid item xs={12} container alignItems="flex-start" spacing={1}>
              {Object.entries(stats.counts).map(([key, num]) => {
                return <Grid item key={key}>
                  <Card className={classes.paper}>
                    <Typography variant="subtitle1">{camelToRead(key)}</Typography>
                    <Typography variant="body2">{num}</Typography>
                  </Card>
                </Grid>;
              })}
            </Grid>
          </Grid>
          <Grid item xs={12} container alignItems="flex-start" spacing={2}>
            <Typography variant="h4">User Lists</Typography>
             <Grid item xs={12} container alignItems="flex-start" spacing={1}>
              {Object.entries(stats.lists).map(([key, list]) => {
                return <Grid item key={key}>
                  <Card className={classes.paper}>
                    <Typography variant="subtitle1">{camelToRead(key)}</Typography>
                    <Typography variant="body2">Total: {list.length}</Typography>
                    <Button className={classes.iconFill} onClick={() => {
                        downloadObjectData(list, key);
                      }}
                      id={key}>
                      <DownloadIcon fontSize="large" id={key}/>
                    </Button>
                    <List disablePadding
                      style={{maxHeight: '50vh', overflow: 'auto'}}>
                        {list.map(user => {
                          return <ListItem key={user.email}>
                            {user.email}
                          </ListItem>
                        })}
                      </List>
                  </Card>
                </Grid>;
              })}
            </Grid>
          </Grid>
        </Grid> : null}
        <Loader show={loading} />
      </AppContainer>
    </div>);
}
