import React, {useState, useEffect} from 'react';
import _ from 'underscore';

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import List from '@material-ui/core/List';
import Checkbox from '@material-ui/core/Checkbox';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import Loader from '../Components/Loader';

import Networker from '../Utils/Networker';

const saveOrganization = (org) => {
  return Networker.put({
    root: 'organizations',
    body: org
  });
};

export default function OrganizationEditor({isSuperUser, allUsers, org, open, handleClose, onDelete}) {
  const [newOrg, setNewOrg] = useState(null);
  const [id, setid] = useState(null);
  const [userFilter, setUserFilter] = useState("");
  const [adminObj, setAdminObj] = useState({});
  const [admins, setAdmins] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (org) {
      const newid = org._id;
      setid(newid);
      setAdminObj({});
      setAdmins(org.admins);
      setUserFilter("");
      setLoading(true);
      const oldAdmins = allUsers.filter(({_id}) => {
        return org.admins.includes(_id);
      });
      setFilteredUsers(oldAdmins);
      setLoading(false);
    }
  }, [allUsers, org]);

  const refresh = React.useRef();

  const handleFilterUpdate = (e) => {
    setUserFilter(e.target.value);
    if (!refresh.current) {
      refresh.current = _.debounce((userFilter) => {
        setLoading(true);
        const newFilteredList = allUsers.filter((elem) => {
          return elem.email.includes(userFilter);
        });
        setFilteredUsers(newFilteredList);
        setLoading(false);
      }, 1000);
    }
    refresh.current(e.target.value);
  };

  const handleSave = () => {
    setLoading(true);
    saveOrganization(Object.assign({}, newOrg, {_id: id},
        admins && admins.length > 0 ? {admins} : null))
    .then(res => {
      window.alert('saved');
      setLoading(false);
    })
    .catch((err) => {
      console.error(err);
      window.alert(err);
      setLoading(false);
    })
  };
  const edit = (e) => {
    const objUpdate = {};
    const val = e.target.value;
    const id = e.target.id;
    if (org[id] !== val) {
      objUpdate[id] = val;
      const updateSend = Object.assign(newOrg || {}, objUpdate);
      setNewOrg(updateSend);
    }
  };

  const handleDelete = () => {
    if (
      window.confirm(`Are you sure you want to delete this organization? ${org.name}`)
    ) {
      setLoading(true);
      onDelete(org._id)
      .then(res => {
        handleClose();
        setLoading(false);
      })
      .catch(err => {
        console.error('Error deleting organization', err);
        setLoading(false);
        handleClose();
      })
    }
  };

  const handleToggle = id => () => {
    let adminsToSearch = Object.assign([], admins.length > 0 ? admins : org.admins);
    let newAdmins = [];
    const idx = adminsToSearch.indexOf(id);
    if (idx > -1) {
      newAdmins = adminsToSearch;
      newAdmins.splice(idx, 1);
    } else {
      newAdmins = adminsToSearch.concat(id);
    }
    if (newAdmins.length < 1) {
      window.alert('Organizations must have at least 1 admin!');
    } else {
      setAdmins(newAdmins);
    }
  };

  useEffect(() => {
    const newAdminObj = {};
    if (admins && admins.length > 0) {
      admins.forEach(admin => {
        newAdminObj[admin] = true;
      });
    } else if (org) {
      org.admins.forEach(admin => {
        newAdminObj[admin] = true;
      });
    }
    setAdminObj(newAdminObj);
  }, [admins, org])

  return org ? <Dialog fullScreen open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Edit {org.name}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            To edit your product, please fill in the fields below with the
            new information and click save.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Name"
            fullWidth
            onBlur={edit}
            defaultValue={org.name}
          />
          <TextField
            autoFocus
            margin="dense"
            id="email"
            label="Contact Email"
            onBlur={edit}
            defaultValue={org.email}
          />
          <TextField
            autoFocus
            margin="dense"
            id="privacyPolicyURL"
            label="Privacy Policy URL"
            onBlur={edit}
            defaultValue={org.privacyPolicyURL}
          />
          <TextField
            autoFocus
            margin="dense"
            id="iconURL"
            label="Icon URL"
            onBlur={edit}
            defaultValue={org.iconURL}
          />
        {isSuperUser ? <List
            autoFocus
            margin="dense"
            id="admins">
            <ListSubheader style={{backgroundColor: 'white'}}>
              Organization Administrators
              {isSuperUser ? <TextField
                autoFocus
                margin="dense"
                label="Filter"
                fullWidth
                onChange={handleFilterUpdate}
                value={userFilter}
                /> : null}
            </ListSubheader>
            <Loader show={loading} />
            {filteredUsers.map(({_id, email}) => {
              const labelId = `checkbox-list-label-${_id}`;
              return <ListItem key={_id} role={undefined}
                dense button onClick={handleToggle(_id)}>
                <ListItemIcon>
                  <Checkbox
                    edge="start"
                    checked={adminObj[_id] === true}
                    tabIndex={-1}
                    disableRipple
                    inputProps={{ 'aria-labelledby': labelId }}
                  />
                </ListItemIcon>
                <ListItemText id={labelId} primary={email} />
              </ListItem>
            })}
        </List> : null}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
          <Button onClick={handleSave} color="primary">
            Save
          </Button>
          {isSuperUser ? <Button variant="contained" onClick={handleDelete} color="secondary">
            Delete
          </Button> : null}
        </DialogActions>
      </Dialog> : null;
}
