import React from 'react';

import { XAxis, YAxis, CartesianGrid, Tooltip } from 'recharts';
import { LineChart, Line, ReferenceLine, ResponsiveContainer } from 'recharts';

import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import InfoIcon from '@material-ui/icons/Info';
import { useTheme } from '@material-ui/styles';

import {argMax} from '../Utils/DataUtils';
import withStyle from '../style';

export default function PopularTimesNoProcessing({title, data}) {
  const finalData = data ? [...data] : [];
  const bestHour = finalData && finalData.length>0 ? argMax(finalData, 'uses') : -1;
  const classes = withStyle();
  const theme = useTheme();
  const fillColor = theme.palette.secondary.light;
  const strokeColor = theme.palette.secondary.dark;
  
  return (<Card className={classes.paper}>
      <Typography variant="h5">
        {title || 'Most Popular Times'}
      </Typography>
            <ResponsiveContainer  height={ 220 }>
        <LineChart data={ finalData }>
         <XAxis dataKey='name' />
         <YAxis/>
         <CartesianGrid strokeDasharray='3 3'/>
         <Tooltip formatter={(value, name, props) => [value, "Uses: "]}/>
         <ReferenceLine x={ 12 } stroke={strokeColor} fillColor={fillColor} label='Noon' isFront={ true }/>
         <ReferenceLine x={ 24 } stroke={strokeColor} fillColor={fillColor} label='Midnight' isFront={ true }/>
         <Line
           type='monotoneX' dataKey='uses'
           fillColor={fillColor} strokeColor={strokeColor}
           activeDot={{r: 8}}/>
        </LineChart>
      </ResponsiveContainer>
      <CardActionArea className={classes.chartInfo}>
        <Grid container alignItems="center" justifyContent="center">
          <InfoIcon />&nbsp;
          This product is used most often between&nbsp;
          { (bestHour % 12) || 12 }&nbsp;
          {(bestHour > 12 ? 'pm' : 'am')}&nbsp;
          and {((bestHour + 1) % 12) || 12}&nbsp;
          {(bestHour + 1 > 12 && bestHour + 1 < 24 ? 'pm' : 'am')}.
        </Grid>
      </CardActionArea>
    </Card>);
}
