import React, { useState, useEffect, useCallback, useRef } from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import Loader from '../Components/Loader';
import MadeWithLove from '../Components/MadeWithLove';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import AppContainer from '../Components/AppContainer';
import InternalApi from '../Utils/InternalApi';
import CSVUploader from '../Components/CSVUploader';
import { TextField } from '@material-ui/core';
import withStyle from '../style';
import Networker from '../Utils/Networker';
import { downloadObjectData } from '../Utils/DataUtils';
import Button from '@material-ui/core/Button';
import ConsumersTable from '../Components/EmailConsumersStatTable';
import { IconButton } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';

const getAllAdminProducts = () => {
  return Networker.get({
    root: 'product',
    inner: 'admin',
    cache: true
  });
};

const getAllAdminOrganiztions = () => {
  return Networker.get({
    root: 'organizations',
    inner: 'admin',
    cache: true
  });
};

const getAllSUProducts = () => {
  return Networker.get({
    root: 'products',
    inner: 'all',
    cache: true
  });
};

const getUserSession = () => {
  return Networker.getUserSession();
};

// This is a silly thing to do.
const ProductIdSingletonFactory = (function () {
  function ProductIdClass() {
    this.setValue = (newValue) => { this.value = newValue; }
    this.getValue = () => { return this.value; }

    this.value = undefined;
  }

  let instance;

  return {
    getInstance: function () {
      if (!instance) {
        instance = new ProductIdClass();
        delete instance.constructor;
      }

      return instance;
    }
  }
})();

export default function ConnectionStatistics({ match, history }) {
  const [loading, setLoading] = useState(true);

  const [hasAccess, setHasAccess] = useState(false);
  const [sidebarVisible, setSidebarVisible] = useState(true);

  const [products, setProducts] = useState([]);
  const [query, setQuery] = useState('');
  const [displayProducts, setDisplayProducts] = useState(products);

  const [openConsumersStack, setOpenConsumersStack] = useState(false);
  const [consumersStackData, setConsumersStackData] = useState([]);

  // `tableData` is given as a download. `tableRows` has an extra `id` key added to allow the DataGrid to layout properly.
  const [tableHeaders, setTableHeaders] = useState([]);
  const [tableRows, setTableRows] = useState([]);
  const [rowCount, setRowCount] = useState(20);

  const [consumerData, setConsumerData] = useState(false)
  const [sortObject, setSortObject] = useState({ field: 'email', order: 1 });

  const [ShowMatchedEmails, setShowMatchedEmails] = React.useState(false);

  /* Store a reference to the active WebSocket */
  const socket = useRef(null);

  const currentTab = match.params.productId;
  const ConsumersListDetailsFromApi = useRef([]);

  ProductIdSingletonFactory.getInstance().setValue(currentTab);

  // Filter list of products by given query string.
  const filteredProducts = (products, query) => products.filter((product) => product.name.toLowerCase().includes(query.toLowerCase()));

  const handleChangeQuery = (event) => {
    setDisplayProducts(filteredProducts(products, event.target.value));
    setQuery(event.target.value);
  };

  const refreshProducts = useCallback(async () => {
    setLoading(true);

    try {
      const orgs = await getAllAdminOrganiztions();
      const res = await getUserSession();

      const access = orgs.body?.some((o) => !!o.connectionStatsAccess);

      // Set this only once since it's tied to the lifecycle of our websocket.
      if (!hasAccess && (access || res.body.isSuperUser)) {
        setHasAccess(true);
      } else {
        Networker.interactionBeacon({ message: 'non-upgraded consumer connect view' });
      }

      const prods = await (res.body.isSuperUser ? getAllSUProducts : getAllAdminProducts)();
      const filtered = Array.from(new Set(prods.body)).sort((a, b) => a.name > b.name ? 0 : -1);

      setProducts(filtered);
      setDisplayProducts(filteredProducts(filtered, query));
    } catch (e) {
      reportNetworkError(e, 'connection statistics products fetch error');
    } finally {
      setLoading(false);
    }
  // This is stupid.
  // eslint-disable-next-line
  }, []);

  useEffect(refreshProducts, [ refreshProducts ]);

  const reportNetworkError = (e, msg) => {
    window.alert('Network error: ' + msg);
    console.error(e);

    Networker.sendError({
      message: msg,
      namespace: 'dashboard.connectionstats',
      data: e
    });
  }

  const convertToJSON = (data, headers) => {
    const json = [];

    data.forEach((row) => {
      if (row.errors.length > 0) { return; }
      const consumer = { optional: {} }

      for (let j = 0; j < headers.length; j++) {
        const currHeader = headers[j].trim().toLowerCase();
        consumer[currHeader] = row.data[j];
      }

      if (consumer.email && consumer.group) {
        json.push(consumer);
      }
    });

    return json;
  }

  // This can get called before the above on a page reload since we can't await in the above useEffect.
  // This is a bug and should probably be fixed at some point.
  const loadConnectionData = useCallback(async function (productId, isConsumer) {
    try {
      const { info, ok } = await InternalApi.request(socket.current, '/product/users', {
        consumers: isConsumer,
        id: productId
      });

      if (!ok) {
          throw new Error('Request failed!');
      }

      const headers = [
        { email: "Email", width: 250 },
        { labels: "Macs", width: 150 },
        { lastInteractionDate: "Last Interaction Detected", width: 250 },
        { lastConnectionMs: "Last Connection (Days)", width: 250 },
        { registeredAt: "Registered Date", width: 200 },
        { connectedAt: "Connected Date", width: 200 },
        { firstUseAt: "First Use Detected Date", width: 250 },
        { lastUseAt: "Last Use Detected Date", width: 250 },
        { interactions: "Interaction Count", width: 200 },
        { uses: "Uses Count", width: 175 },
        { switchedCount: "Band Switches", width: 175 },
        { phone: "Phone Number", width: 175 },
        { mobileSystem: "Phone OS", width: 150 },
        { battery: "Battery Life", width: 150 },
        { userId: "User ID", width: 225 }
      ];

      // Add any extra headers (notifications, sent surveys, etc.) that are per-product.
      if (info?.length)
      {
        // Insert fields for volumetric products
        if (Object.hasOwn(info[0], 'remaining'))
        {
          headers.splice(9, 0,
            { remaining: 'Amount Remaining (G)', width: 225 },
            { isEmpty: 'Empty', width: 150 },
            { needsRefillCount: 'Needs Refill Count', width: 200 },
            { refilledCount: 'Refill Count', width: 200 },
          );
        }

        // Add this field if we have no connection notification for this product
        if (Object.hasOwn(info[0], 'lastNotifiedNoConnection')) {
          headers.splice(3, 0, { lastNotifiedNoConnection: 'Last No Connection Notified', width: 300 });
        }

        const cols = Object.keys(info[0]).filter((k) => ![ '_id' ].includes(k));
        const pre = headers.map((o) => Object.keys(o)[0]);

        headers.push.apply(headers, cols.filter((k) => !pre.includes(k)).map((k) => ({ [k]: k, width: 200 })));
      }

      const data = info.map((consumer, index) => {
        const entry = Object.assign({ id: index }, consumer);
        delete entry._id;

        if (consumer.labels) {
            entry.labels = consumer.labels.join(', ')
        }

        if (consumer.lastConnectionMs) {
            entry.lastConnectionMs = (consumer.lastConnectionMs / (24 * 60 * 60 * 1000)).toFixed(3);
        }

        if (consumer.remaining) {
            entry.remaining = consumer.remaining.toFixed(2);
        }

        if (consumer.battery) {
            entry.battery = consumer.battery.toFixed(4);
        }

        console.info('+', entry);
        return entry;
      });

      return {
          headers: headers,
          data: data
      };
    } catch (err) {
      console.error('Failed to fetch data:', err);
      alert('Internal error while fetching data!')

      return { headers: [], data: [] };
    }
  // eslint-disable-next-line
  }, []);

  const reloadData = useCallback(async function () {
      if (!currentTab || !hasAccess) { return; }

      if (!socket.current)
      {
          console.warn('Missing socket!');
          return;
      }

      console.info('Loading data.');
      setLoading(true);

      // First look for data with consumers.
      let res = await loadConnectionData(currentTab, true);

      // Otherwise look without consumers.
      if (!res.data?.length) {
          res = await loadConnectionData(currentTab, false);
          setConsumerData(false);
      } else {
          // We have consumer data
          setConsumerData(true);
      }

      // This allows the table to work properly
      res.data.forEach((obj, index) => obj.id = index);

      setTableHeaders(res.headers);
      setTableRows(res.data);

      setLoading(false);
  }, [ loadConnectionData, currentTab, hasAccess ]);

  useEffect(() => {
    if (!hasAccess) { return; }

    InternalApi.connect().then(async (ws) => {
        console.info('connected.')
        socket.current = ws;

        await reloadData();
    }).catch((err) => {
      console.error('Failed to connect to socket:', err);
    });

    return (() => {
        if (socket.current) { InternalApi.disconnect(socket.current); }
    });

    // This function will keep looping if we don't add this
    // eslint-disable-next-line
  }, [ hasAccess ]);

  // Reload page data when the current product is changed.
  // eslint-disable-next-line
  useEffect(reloadData, [ currentTab ])

  // Uplodaing CSV File Function
  const handleFileLoad = (data, productId) => {
    const headers = data[0].data.map((header) => header.trim().toLowerCase());

    if (!headers.includes('email') || !headers.includes('group'))
    {
        window.alert('CSV file must contain the email column and the group column!');
        return;
    }

    // Convert CSV to JSON
    const jsonData = convertToJSON(data.slice(1), headers);

    // Save the new data
    setConsumerData(true);

    // Save data and disable loading indicator when done.
    saveRecruitedConsumers(jsonData, productId).then(reloadData);
  };

  const saveRecruitedConsumers = async (consumers, productId) => {
    try {
      setLoading(true);

      await Networker.post({
        root: 'recruitedConsumers',
        inner: '',
        body: consumers,
        query: { productId: productId },
        cache: true
      });
    } catch (e) {
      reportNetworkError(e, 'Failed to save recruited consumers');
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteRecruitedConsumers = (productId) => {
    if (window.confirm('Are you sure you want to delete the recruited consumers for this product?')) {
      saveRecruitedConsumers([], productId).then(reloadData);
    }
  }

  const downloadEmailMismatchTable = (data, productId) => {
    // FIXME: because there is some misalignment in the EmailConsumersStatTable component, 
    // the headers have to be incorrectly mapped here for the headers to be correct 
    const headers = [
      { email: "Account Email" },
      { accountedEmail: "Recruited Email" },
      { phone: "Phone Number" },
      { macs: "Macs" },
      { userId: "User Id" }
    ];

    handleDownload(data, headers, productId, 2);
  }

  const handleDownload = (data, headers, productId, caseVal) => {
    if (caseVal === 1) headers.push({ email: "Email" }, { alert: "Alert" });
    const { field: sortField, order: sortOrder } = sortObject;

    const dataDownload = data.sort((a, b) => a[sortField] > b[sortField] ? sortOrder : -sortOrder).map(row => {
      const updatedRow = {};

      headers.forEach(headerObj => {
        const [headerKey, headerValue] = Object.entries(headerObj)[0];

        if (row[headerKey] === undefined) {
          updatedRow[headerValue] = "";
        } else {
          updatedRow[headerValue] = row[headerKey];
        }
      });

      return updatedRow;
    });

    if (dataDownload.length) {
      const currentProd = products.filter(prod => prod._id === productId)[0];
      downloadObjectData(dataDownload, currentProd.name);
    }
  }

  const handleSortByField = (field, order) => { setSortObject({ field, order }); }

  const getconsumersList = async () => {
    setShowMatchedEmails(false);
    let filterRes;

    const filterResData = await Networker.get({
      root: 'recruitedConsumers/filter',
      inner: '',
      query: { productId: currentTab },
      cache: true
    });

    let updatedPotentialMatchedEmails = [];
    if (filterResData && filterResData.body) {
      filterRes = {};
      filterRes = filterResData;
      ConsumersListDetailsFromApi.current = filterResData.body;
    }
    if (filterRes && filterRes.body && filterRes.body.emailList.length > 0) {
      // setLoading(true);
      filterRes.body.emailList.map((item, index) => {
        // newEmailList.push({ recruiteEmail: item.email })
        filterRes.body.emailList[index]['phoneNumberChecked'] = false;
        filterRes.body.emailList[index]['recruitedEmailChecked'] = false;
        filterRes.body.emailList[index]['matchedEmailChecked'] = false;
        if (!filterRes.body.emailList[index].hasOwnProperty('extraAccountedEmails'))
          filterRes.body.emailList[index]['AllEmailDataAreThere'] = true;
        if (item.matchedEmail && item.matchedEmail.indexOf(',') > 0) {
          filterRes.body.emailList[index]['potentialEmails'] = item.matchedEmail.split(',');
          if (filterRes.body.emailList[index]['potentialEmails'] && filterRes.body.emailList[index]['potentialEmails'].length > 0) {
            filterRes.body.emailList[index]['potentialEmails'].map((matchedEmail) => {
              updatedPotentialMatchedEmails.push({
                potentialEmailChecked: false,
                potentialEmail: matchedEmail
              });
              return true;
            });
            filterRes.body.emailList[index]['potentialEmails'] = updatedPotentialMatchedEmails;
            updatedPotentialMatchedEmails = [];
          } else {
            filterRes.body.emailList[index]['potentialEmails'] = [];
          }
        } else {
          filterRes.body.emailList[index]['potentialEmails'] = [];
        }
        return true
      });
      if (filterRes.body.emailList.length > 0 && filterRes.body.presentInvalidData.length === 0) {
        setLoading(false);
        setConsumersStackData(filterRes.body.emailList.length > 0 ? filterRes.body.emailList : []);
        setOpenConsumersStack(true);
      }
    } if (filterRes && filterRes.body && filterRes.body.presentInvalidData.length > 0) {
      let finalPresentInvalidData = [];
      // if (filterRes.body.presentInvalidData.length > 0 && filterRes.body.emailList.length === 0) finalPresentInvalidData = filterRes.body.presentInvalidData;
      filterRes.body.presentInvalidData.map((item) => {
        // newEmailList.push({ recruiteEmail: item.email });
        if (filterRes.body.emailList.length > 0) {
          let matchedIndex = filterRes.body.emailList.findIndex((emails) => emails.matchedEmail === item.email);
          const againMatchIndex = filterRes.body.emailList.findIndex((emails) => emails.accountedEmail === item.email);
          if (matchedIndex > -1) {
            filterRes.body.emailList[matchedIndex]['accountedEmail'] = item.email;
            filterRes.body.emailList[matchedIndex]['accountedEmailChecked'] = false;
          } else if (matchedIndex === -1) {
            let potentioalEmailIndex = -1;
            filterRes.body.emailList.map((emailItem, index) => {
              if (emailItem.potentialEmails && emailItem.potentialEmails.length > 0) {
                potentioalEmailIndex = emailItem.potentialEmails.findIndex((potential) => potential.potentialEmail === item.email);
                matchedIndex = index;
                const againMatchIndexToAdd = filterRes.body.emailList.findIndex((emails) => emails.accountedEmail === item.email);
                if (potentioalEmailIndex > -1 && againMatchIndexToAdd === -1 &&
                  !filterRes.body.emailList[index].accountedEmailMatchedEmail) {
                  filterRes.body.emailList[index]['accountedEmail'] = item.email;
                  filterRes.body.emailList[index]['accountedEmailChecked'] = false;
                  filterRes.body.emailList[index]['accountedEmailMatchedEmail'] = true;
                }
              }
              return true;
            });
            if (potentioalEmailIndex === -1 && matchedIndex === -1 && againMatchIndex === -1) {
              filterRes.body.emailList.push({
                'accountedEmail': item.email,
                'accountedEmailChecked': false,
                'extraAccountedEmails': true
              });
            }
            const againMatch = filterRes.body.emailList.findIndex((emails) => emails.accountedEmail === item.email);

            if (againMatch === -1 && againMatchIndex === -1) {
              filterRes.body.emailList.push({
                'accountedEmail': item.email,
                'accountedEmailChecked': false,
                'extraAccountedEmails': true
              });
            }
          }
        } else {
          finalPresentInvalidData.push({
            'accountedEmail': item.email,
            'accountedEmailChecked': false
          });
        }
        return true
      });
      setLoading(false);
      setConsumersStackData(filterRes.body.emailList.length > 0 ? filterRes.body.emailList : finalPresentInvalidData);
      setOpenConsumersStack(true);
    }
    else {
      setOpenConsumersStack(true);
      setLoading(false);
      setConsumersStackData([]);
    }
  };

  const getConsumersStatsList = () => {
    getconsumersList();
    setConsumersStackData([]);
  }

  useEffect(() => {
    if (!currentTab && products.length > 0) {
      history.push('/connectionstatistics/' + products[0]._id)
    } else if (products.length === 0) {
      refreshProducts();
    }
  }, [currentTab, history, products, refreshProducts]);

  // hide and show the right side bar
  const toggleSidebar = () => setSidebarVisible(!sidebarVisible);

  const handleTabChange = (val) => (_) => {
    if (val !== currentTab) {
      history.push('/connectionstatistics/' + val);
      setSortObject({ field: 'email', order: 1 });
    }
  };

  const closeConsumerStats = () => {
    setOpenConsumersStack(false);
    setConsumersStackData([]);
  }

  const classes = withStyle();

  // I made this too fancy for no reason. Technically you could just compute the right hand side's height directly, but I'm too lazy.
  const maxListHeight = (() => {
      const tableHeight = (tableRows.length > rowCount ? rowCount : tableRows.length) * 37.5;
      const windowHeight = window.innerHeight - 250;

      return (windowHeight > tableHeight) ? windowHeight : tableHeight;
  })();

  return (<div className={classes.root}><CssBaseline /><Loader show={loading} />
    <AppContainer classes={classes} title="Connection Statistics" match={match}>
      <Grid container direction="row" style={{ marginTop: 64, marginBottom: 16 }} alignItems="stretch" justifyContent="flex-start" spacing={2}>
        {/* Left Section */}
        {sidebarVisible && (<Grid item xs={12} md={4}>
          <Paper style={{ paddingLeft: 8, paddingRight: 8 }}  elevation={3}>
            {/* List Header/Search Bar */}
            <Grid container direction="row" spacing={1} style={{ marginTop: 0, paddingTop: 8 }} className={classes.drawerHeader}>
              <Grid item xs={2}><IconButton edge="left" color="inherit" onClick={toggleSidebar}><MenuIcon /></IconButton></Grid>
              <Grid item xs={10}>
                <TextField fullWidth type="text" placeholder="Search..." value={query} onChange={handleChangeQuery} variant="outlined" />
              </Grid>
            </Grid>
            {/* Displayed list */}
            <List style={{ overflowY: 'auto', maxHeight: maxListHeight, height: '100%' }} dense>
              {displayProducts.map(prod => (
                <ListItem key={prod._id} button divider dense selected={currentTab === prod._id} onClick={handleTabChange(prod._id)}>
                  <ListItemAvatar><Avatar alt={prod.name.substr(0, 3)} src={prod.iconURL} /></ListItemAvatar>
                  <ListItemText primary={prod.name} />
                </ListItem>
              ))}
            </List>
          </Paper>
        </Grid>)}
        {/* Right Section */}
        <Grid item xs={12} md={sidebarVisible ? 8 : 12}>
          <Paper style={{ paddingLeft: 8, paddingRight: 8, paddingBottom: 8 }} elevation={3}>
            {/* Right Sidebar Content */}
            <Grid container direction="row" justifyContent="flex-start" alignItems="center" spacing={2} style={{ marginTop: 0, paddingBottom: 8 }}>
              {!sidebarVisible ? (
                  <Grid item xs={0.5}><IconButton edge="left" color="inherit" onClick={toggleSidebar}><MenuIcon /></IconButton></Grid>
              ) : null}
              {/* Additional Buttons */}
              <Grid item xs={4}><CSVUploader onLoad={(data) => { handleFileLoad(data, currentTab); }} /></Grid>
              <Grid item xs={3}>
                <Button fullWidth disabled={!consumerData} type="submit" variant="contained" color="primary"
                  onClick={() => { setLoading(true); getConsumersStatsList() }}>
                  Consumer Info
                </Button>
              </Grid>
              <Grid item xs={4}>
                <Button fullWidth disabled={!consumerData} type="submit" variant="contained" color="primary"
                  onClick={ () => handleDeleteRecruitedConsumers(currentTab) }>
                  Delete Consumers
                </Button>
              </Grid>
            </Grid>
            {/* Connection Data View */}
            {(!!tableRows?.length ? <DataGrid
              rows={tableRows}
              columns={tableHeaders.map((header) => ({
                  headerName: header[Object.keys(header)[0]],
                  field: Object.keys(header)[0],
                  width: header.width
              }))}
              rowsPerPageOptions={[ 20, 30, 50, 100]}
              pageSize={rowCount}
              onPageSizeChange={(val) => setRowCount(val)}
              disableSelectionOnClick checkboxSelection
              autoHeight
              density="compact"
              onSortByField={handleSortByField}
              sortObject={sortObject}
              components={{ Toolbar: GridToolbar }}
            /> : (<Typography variant="h5" align="center" style={{ margin: 32 }}>{(
                loading ? 'Loading Product Data...' : 'No Data Available'
            )}</Typography>))}
          </Paper>
          {/* left section */}
          {/* You can place your DataGrid component here (Depend on Review) */}
        </Grid>
      </Grid>
      {/* consumers stack modal along with table */}
      {
        openConsumersStack && <ConsumersTable
          show={openConsumersStack}
          consumersStackList={consumersStackData.length > 0 ? consumersStackData : []}
          currentTabId={currentTab}
          downloadEmailMismatchTableApi={downloadEmailMismatchTable}
          ShowMatchedEmailsList={ShowMatchedEmails}
          closePopup={closeConsumerStats}
          ConsumersListDetailsDataFromApi={ConsumersListDetailsFromApi.current}
          exportCosntStatsToCSVApi={downloadEmailMismatchTable}
        />
      }
      {/*end of consumers table  */}
      {/* match filter */}
    <MadeWithLove />
    </AppContainer >
  </div>);
}
