import React, {useState} from 'react';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import {Link as RouterLink} from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import withStyle from '../style';
import Container from '@material-ui/core/Container';
import Fade from '@material-ui/core/Fade';

import {Redirect} from 'react-router-dom';

import Networker from '../Utils/Networker';
import MadeWithLove from '../Components/MadeWithLove';

import Loader from '../Components/Loader';



const emailRX = /^([-!#-'*+/-9=?A-Z^-~]+(\.[-!#-'*+/-9=?A-Z^-~]+)*|"[^"]+")@[0-9A-Za-z]([0-9A-Za-z-]{0,61}[0-9A-Za-z])?(\.[0-9A-Za-z]([0-9A-Za-z-]{0,61}[0-9A-Za-z])?)+$/;
export default function Register() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [fname, setFname] = useState('');
  const [lname, setLname] = useState('');
  const [notificationsEnabled, setNotificationsEnabled] = useState(false);
  const [optInAgreed, setOptInAgreed] = useState(true);
  const [loading, setLoading] = useState(false);

  const setField = (e) => {
    switch (e.target.id) {
      case 'email':
        setEmail(e.target.value);
        break;
      case 'password':
        setPassword(e.target.value);
        break;
      case 'fname':
        setFname(e.target.value);
        break;
      case 'lname':
        setLname(e.target.value);
        break;
      case 'notificationsEnabled':
        setNotificationsEnabled(Boolean(e.target.checked));
        break;
      case 'optInAgreed':
        setOptInAgreed(Boolean(e.target.checked));
        break;
      default:
    }
  };
  const submitRegistration = (e) => {
    e.preventDefault();
    setLoading(true);
    if (!emailRX.test(email)) {
      window.alert(email + ' is not a valid email address');
      setLoading(false);
      return;
    }
    Networker.register({
      email,
      password,
      notificationsEnabled,
      optInAgreed,
      meta: {
        fname,
        lname
      }})
    .then((res) => {
      setIsLoggedIn(true);
      setLoading(false);
    })
    .catch((err) => {
      console.error(err);
      window.alert('Error, someone might already have that email');
      setLoading(false);
      Networker.sendError({
        message: 'registration error',
        namespace: 'dashboard.register',
        data: err
      });
    });
  };
  const classes = withStyle();

  return isLoggedIn ? <Redirect to="/account"/> : (
    <div>
    <Fade in={!loading} timeout={{
        enter: 1000,
        exit: 500
      }}>
    <Container component="main" maxWidth="sm">
      <CssBaseline />
        <center style={{margin: '5vh'}}>
          <img src="/logo.png" width="100%" alt="Adrich Dashboard Logo">
          </img>
        </center>
        <div className={classes.paper}>
          <Typography component="h1" variant="h4">
            Sign In
          </Typography>
        <form className={classes.form} noValidate onSubmit={submitRegistration}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                autoComplete="firstName"
                name="firstName"
                variant="outlined"
                required
                fullWidth
                id="fname"
                label="First Name"
                autoFocus
                onChange={setField}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                variant="outlined"
                required
                fullWidth
                id="lname"
                label="Last Name"
                name="lastName"
                autoComplete="lastName"
                onChange={setField}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                InputProps={{
                  classes: {
                    input: classes.largeInput,
                  },
                }}
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                onChange={setField}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                InputProps={{
                  classes: {
                    input: classes.largeInput,
                  },
                }}
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
                onChange={setField}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={<Checkbox id="notificationsEnabled"
                value="notificationsEnabled" color="primary" />}
                onChange={setField}
                label="I agree to receive notifications."
              />
            </Grid>
          </Grid>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="secondary"
            className={classes.submit}
          >
            Sign Up
          </Button>
          <Typography variant="subtitle2">
            By clicking sign up, you agree to our <Link component={RouterLink}
              to="/terms" target="_blank" rel="noopener">Terms</Link> and
            you agree to our <Link component={RouterLink} to="/privacy"
            target="_blank" rel="noopener">Privacy Policy</Link>.
          </Typography>
          <hr/>
          <Grid container justifyContent="flex-end">
            <Grid item>
              <Link component={RouterLink} to="/login" variant="body2">
                Already have an account? Sign in
              </Link>
            </Grid>
          </Grid>
        </form>
      </div>
      <Box mt={5}>
        <MadeWithLove />
      </Box>
    </Container>
    </Fade>
    <Loader show={loading}/>
    </div>
  );
}
