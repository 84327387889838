import React, {useState, useEffect} from 'react';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import TextField from '@material-ui/core/TextField';
import Networker from '../Utils/Networker';

import InputTransformers from '../Utils/InputTransformers.json';
import OutputTransformers from '../Utils/OutputTransformers.json';
import Endpoints from '../Utils/Endpoints.json';

import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';

const saveMiddleware = (mid) => {
  return Networker.put({
    root: 'middleware',
    body: mid
  });
};

export default function MiddlewareEditor({middleware, open, handleClose}) {
  const [newMiddleware, setNewMiddleware] = useState(middleware);

  const handleSave = () => {
    saveMiddleware(newMiddleware)
    .then((res) => {
      window.alert('saved');
    })
    .catch((err) => {
      console.error(err);
      window.alert(err);
    });
  };

  useEffect(() => {
    setNewMiddleware(middleware);
  }, [middleware]);

  const edit = (e) => {
    const objUpdate = {};
    const val = e.target.value;
    const id = e.target.id || e.target.name;
    objUpdate[id] = val;
    const updateSend = Object.assign({}, newMiddleware, objUpdate);
    setNewMiddleware(updateSend);
  };
  return newMiddleware ? <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Edit {newMiddleware.name}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            To edit this middleware object, please edit the items below
          </DialogContentText>
            <TextField
              fullWidth
              id="name"
              value={(newMiddleware).name}
              onChange={edit}
              label="Name"
            >
            </TextField>
            <TextField
              fullWidth
              select
              value={(newMiddleware).inputTransformerURL}
              onChange={edit}
              label="Input Transformer Presets"
              SelectProps={{
                name: 'inputTransformerURL',
                id: 'input-transformer',
              }}
            >
            {InputTransformers.map(trans => <MenuItem key={trans.title} value={trans.url}>{trans.title}</MenuItem>)}
            </TextField>
            <TextField
              fullWidth
              id="inputTransformerURL"
              value={(newMiddleware).inputTransformerURL}
              onChange={edit}
              label="Input Transformer"
            >
            </TextField>
            <TextField
              fullWidth
              select
              value={(newMiddleware).APIendpoint}
              onChange={edit}
              label="API Endpoint"
              SelectProps={{
                name: 'APIendpoint',
                id: 'api-endpoint',
              }}
            >
            {Endpoints.map(point => <MenuItem key={point.title} value={point.url}>{point.title}</MenuItem>)}
            </TextField>
            <TextField
              autoFocus
              margin="dense"
              id="APIendpoint"
              label="API endpoint"
              fullWidth
              onChange={edit}
              value={(newMiddleware).APIendpoint}
            />
            <InputLabel htmlFor="api-method">API Request Method</InputLabel>
            <Select
              fullWidth
              value={(newMiddleware).APImethod}
              onChange={edit}
              inputProps={{
                name: 'APImethod',
                id: 'api-method',
              }}
            >
              <MenuItem value="get">GET</MenuItem>
              <MenuItem value="post">POST</MenuItem>
              <MenuItem value="put">PUT</MenuItem>
            </Select>
            <TextField
              fullWidth
              select
              value={(newMiddleware).outputTransformerURL}
              onChange={edit}
              label="Output Transformer Presets"
              SelectProps={{
                name: 'outputTransformerURL',
                id: 'output-transformer',
              }}
            >
            {OutputTransformers.map(trans => <MenuItem key={trans.title} value={trans.url}>{trans.title}</MenuItem>)}
            </TextField>
            <TextField
              fullWidth
              id="outputTransformerURL"
              value={(newMiddleware).outputTransformerURL}
              onChange={edit}
              label="Output Transformer"
            >
            </TextField>
            <TextField
              autoFocus
              margin="dense"
              id="timeout"
              label="timeout"
              type="number"
              fullWidth
              onChange={edit}
              value={newMiddleware.timeout}
            />
            <Select
              fullWidth
              value={(newMiddleware).dataField}
              onChange={edit}
              inputProps={{
                name: 'dataField',
                id: 'data-field',
              }}
            >
              <MenuItem value="data">Data (parsing)</MenuItem>
              <MenuItem value="computedData">Computed Data (Additional Fields)</MenuItem>
              <MenuItem value="hiddenComputedData">Hidden Computed Data (Additional Fields)</MenuItem>
              <MenuItem value="meta">Meta (Metadata)</MenuItem>
            </Select>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleSave} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog> : null;
}
