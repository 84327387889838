import React, { useState, useEffect, useCallback } from 'react';
import _ from 'lodash';

import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Avatar from '@material-ui/core/Avatar'

import ProductCard from '../Components/ProductCard';
import ProductEditor from '../Components/ProductEditor';
import ExternalLinkEditor from '../Components/ExternalLinkEditor';
import AppContainer from '../Components/AppContainer';
import Loader from '../Components/Loader';
import MadeWithLove from '../Components/MadeWithLove';

import Networker from '../Utils/Networker';
import withStyle from '../style';

const getAllMiddleware = (org) => {
  return Networker.get({
    root: 'middleware',
    query: {owner: org},
    cache: true
  });
}

const getAllAdminProducts = (org) => {
  return Networker.get({
    root: 'products',
    inner: 'admin',
    cache: true
  });
};

const getAllSUProducts = () => {
  return Networker.get({
    root: 'products',
    inner: 'all',
    cache: true
  });
};

const getAllSUOrgs = () => {
  return Networker.get({
    root: 'organizations',
    inner: 'all',
    cache: true
  });
};

const getAllOrganizations = (userId) => {
  return Networker.get({
    root: 'organizations',
    query: {userId},
    cache: true
  });
}

export default function Products({match}) {
  const [user, setUser] = useState({});
  const [products, setProducts] = useState([]);
  const [organizations, setOrganizations] = useState([]);
  const [newproduct, setNewproduct] = useState(null);
  const [orgObject, setOrgObject] = useState({});
  const [middlewareObj, setMiddlewareObject] = useState({});
  const [isSuperUser, setSuperUser] = useState(false);
  const [loading, setLoading] = useState(false);
  const [openSurveyEditor, setOpenSurveyEditor] = useState(false);
  const [productNameFilter, setProductNameFilter] = useState('');
  const [Sensors, setSensorList] = useState([]);
  const [density, setDensity] = useState(1);
  const refresh = useCallback(() => {
    if (user && user._id) {
      if (user.isSuperUser) {
        setLoading(true);
        getAllSUOrgs()
        .then(async res => {
          const newOrgObject = {};
          res.body.forEach(org => {
            newOrgObject[org._id] = org;
          });
          setOrgObject(newOrgObject);
          setOrganizations(res.body);
          const middleware = await Promise.all(res.body.map(org => getAllMiddleware(org._id)));
          const newMiddlewareObject = {};
          middleware.forEach(middsForOrg => {
            middsForOrg.body.forEach(midd => newMiddlewareObject[midd._id] = midd);
          });
          setMiddlewareObject(newMiddlewareObject);

            const prods = await getAllSUProducts();
  
            setProducts(prods.body.sort((a, b) => {
              return a.name > b.name ? 0 : -1;
            }));
            setLoading(false);
          });
      } else {
        setLoading(true);
        getAllOrganizations(user._id)
        .then(async (orgreq) => {
          const newOrgObject = {};
          orgreq.body.forEach(org => {
            newOrgObject[org._id] = org;
          });
          setOrgObject(newOrgObject);
          setOrganizations(orgreq.body);
          const prods = await getAllAdminProducts();
          const middleware = await Promise.all(user.organizations.map(org => getAllMiddleware(org)));
          const newMiddlewareObject = {};
          middleware.forEach(middsForOrg => {
            middsForOrg.body.forEach(midd => newMiddlewareObject[midd._id] = midd);
          });
          setMiddlewareObject(newMiddlewareObject);
          const prodObject = {};
          const removedDuplicates = prods.body.map(p => {
            if (prodObject[p._id]){
                return null;
            }
            prodObject[p._id] = true;
            return p;
          }).flat().filter(e => e).sort((a, b) => {
            return a.name > b.name ? 0 : -1;
          });
          setProducts(removedDuplicates);
          setLoading(false);
        })
        .catch((err) => {
          console.error(err);
          setLoading(false);
          Networker.sendError({
            message: 'product refresh error',
            namespace: 'dashboard.products',
            data: err
          });
        })
      }
    }
  }, [user]);

  useEffect(() => {
    setLoading(true);

    Networker.get({
      root: 'admin',
      inner: 'sensors'
    }).then(({body}) => {
      setSensorList(body);
    }).catch((err) => {
      console.error(err);
    })

    Networker.getUserSession()
    .then(res => {
      setUser(res.body);
      setSuperUser(res.body.isSuperUser || false);
      setLoading(false);
      refresh();
    })
    .catch(err => {
      setLoading(false);
    })
  }, [refresh]);


  const handleProdAdd = (orgId) => {
    setLoading(true);
    Networker.post({
      root: 'products',
      body: {
        name: `New Product (Created:${new Date().toDateString()})`,
        organizations: [orgId],
        sensor: Sensors[0]._id
      }
    })
      .then(async (res) => {
        setLoading(false);
        setNewproduct(res.body);
        refresh();
      })
      .catch(err => {
        console.error(err);
        setLoading(false);
        Networker.sendError({
          message: 'product create error',
          namespace: 'dashboard.products',
          data: err
        });
      });
  };

  const showEditor = (e) => {
    let node = e.target;
    let i = 0;
    while (node.nodeName !== 'BUTTON' && i < 4) {
      node = node.parentNode;
      i += 1;
    }
    const id = node.id;
    const prod = products.find((elem) => elem._id === id);
    setNewproduct(prod);
  };

  const showSurveyEditor = (e) => {
    let node = e.target;
    let i = 0;
    while (node.nodeName !== 'BUTTON' && i < 4) {
      node = node.parentNode;
      i += 1;
    }
    const id = node.id;
    const prod = products.find((elem) => elem._id === id);
    setOpenSurveyEditor(prod);
  };

  const onDelete = (e) => {
    let node = e.target;
    let i = 0;
    while (node.nodeName !== 'BUTTON' && i < 4) {
      node = node.parentNode;
      i += 1;
    }
    const id = node.id;
    const prod = products.find((elem) => elem._id === id);
    if (window.confirm(`Are you sure you want to delete ${prod.name}`)) {
      Networker.delete({
        root: 'products',
        query: {
          _id: id
        }
      })
      .then(res => {
        refresh();
      })
      .catch(err => {
        refresh();
      });
    }
  }

  const closeEditor = () => {
    try {
      if (newproduct && newproduct.middleware.length < 1) {
        window.alert('warning, this product has no parser set');
      }
    } catch (e) {
      console.warn('middleware check error', e);
    }
    setNewproduct(null);
    setOpenSurveyEditor(false);
    refresh();
  };

  const handleFilterChange = _.debounce((val) => {
    setProductNameFilter(val);
  }, 150);

  const classes = withStyle();

  return <div className={classes.root}>
    <CssBaseline />
    <AppContainer classes={classes} title="Products" match={match}>
      <div className={classes.appBarSpacer} />
      <Container maxWidth="xl" className={classes.container}>
        <Grid container spacing={3} justifyContent="center" alignItems="flex-start">
          <Grid item xs={12} container spacing={1}>
            <Grid item xs={12} md={11}>
              <TextField label="Filter" defaultValue="" onChange={(e) => handleFilterChange(e.target.value)} fullWidth autoFocus></TextField>
            </Grid>
            {user.isSuperUser ? <Grid item xs={12} md={1}>
              <Select value="new" onChange={(e) => handleProdAdd(e.target.value)} >
                <MenuItem value="new">New Product</MenuItem>
                {organizations.map((org) => {
                  return <MenuItem value={org._id} key={org._id}>
                    <Grid container spacing={0} alignItems="center" justifyContent="space-between">
                      <Grid item><Typography className={classes.title} color="textSecondary" gutterBottom>{org.name}</Typography></Grid>
                      <Grid item><Avatar alt={org.name} src={org.iconURL} className={classes.avatar}>title.substr(0, 1)</Avatar></Grid>
                    </Grid>
                  </MenuItem>
                })}
              </Select>
            </Grid> : null}
          </Grid>
          {products.filter(({ name, productId }) => {
            return (name + productId || '').toLowerCase().includes(productNameFilter.toLowerCase() || '');
          }).map((prod) => <Grid item key={prod._id} xs={12} md={6} lg={4}>
            <ProductCard
              prod={prod}
              orgs={prod.organizations.map(org => orgObject[org]).filter(e => e)}
              isSuperUser={isSuperUser}
              middleware={prod.middleware.map(id => middlewareObj[id])}
              onEdit={showEditor}
              onSurveyEdit={showSurveyEditor}
              onDelete={onDelete}
            />
          </Grid>)}
        </Grid>
      </Container>
      {user.isSuperUser ? <ProductEditor
        prod={newproduct}
        isSuperUser={user.isSuperUser}
        middleware={Object.values(middlewareObj)}
        open={Boolean(newproduct)}
        setDensity={setDensity}
        density={density}
        handleClose={closeEditor} /> : null}
      {user.isSuperUser ? <ExternalLinkEditor
        prod={openSurveyEditor}
        isSuperUser={user.isSuperUser}
        open={Boolean(openSurveyEditor)}
        handleClose={closeEditor} /> : null}
      <MadeWithLove />
      <Loader show={loading} />
    </AppContainer>
  </div>;
}
