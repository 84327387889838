import React from 'react';
import Typography from '@material-ui/core/Typography';

export default class Terms extends React.Component {
  render() {
    return (
      <div style={{
          minWidth: '400px', width: '50vw', textAlign: 'justify', paddingLeft: 'calc(50vw - 200px)', maxWidth: '600px'
        }}>
        <Typography variant="h1" style={{textAlign: 'center'}}>Terms and Conditions of Usage</Typography>
        <p>
          These Terms and Conditions govern the use of products, related services and technologies (collectively, "Adrich Products") provided by Adrich Inc. (“Adrich”) located at 100 S Commons Suite 102 Pittsburgh, PA 15212 to Customer. The Customer, Adrich Products and payments for the Adrich Products are described in Consumer Test Agreement (the “Contract”) submitted to Adrich that outline project parameters and scope of services to be rendered. The contents of any Contract and these Terms and Conditions are together referred to as the "Agreement". The Agreement overrides any Customer Purchase Order terms and conditions.
        </p>
        <ol>
          <li>
            Contract:  Each Contract shall incorporate these terms and conditions. At Adrich's discretion, Adrich may reject any Contract that attempts to alter the terms and conditions of this Agreement or establish additional terms and conditions.  This Agreement shall become effective when signed by the Customer and upon Adrich's execution thereof by an authorized representative of Adrich.
          </li>
          <li>
            Term and Termination: The Term shall commence upon the Commencement Date, as specified in the Contract. The Term will automatically renew for additional Terms equal in time to the original Term unless either party gives the other party notice of non-renewal at least 90 days prior to the end of the relevant Term.  The Term of the Agreement shall be the period specified in the Contract unless earlier terminated (a) by Adrich at any time upon 30 days written notice, (b) by either party if the other party files bankruptcy or is adjudicated as bankrupt, (c) by either party if the other party commits a material breach of any of the terms of this Agreement and such breach remains uncured within 30 days after the other party receives written notice of such breach.  The Customer may not terminate or cancel the Agreement for any reason whatsoever, except as expressly provided herein or in this Agreement.  Termination of this Agreement for any reason shall not relieve either party from any obligation relating to the treatment by either party of the confidential or proprietary information of the other party, which obligations shall survive any such termination, nor shall it relieve Customer of any payment obligations under the Agreement, incurred or accrued prior to termination.
          </li>
          <li>
          Payment Terms: Customer shall pay to Adrich the amount specified in the Contract, with 50% of the contract value due upon signing and the remaining 50% of the contract value due upon final project delivery. Adrich shall invoice the customer at the address set forth in the Contract.  All charges will be due and payable upon receipt of the invoice. Any amounts payable by Customer under this Agreement that remain unpaid after receipt of invoice will be subject to a late charge equal to the lesser of one percent (1%) per month or the maximum amount allowed by law from the due date until such amount is paid. Customer will reimburse Adrich for reasonable attorney’s fees and other costs associated with collecting delinquent payments. All payments provided in the Contract shall be payable at the office of Adrich set forth in the Contract. <br/>

          Travel costs related to flights, hotels, car, meals and time to apply tags and train Customer employees will be billed separately and are NOT included in the cost of services. Daily cost to train Customer employees and/or to apply tags on site, will be billed at $600/day/Engineer. Travel costs will be billed for actual amounts incurred.
          </li>
          <li>
          Delivery: Adrich is responsible for delivering any products outlined in the Contract to the Customer, obtaining all appropriate shipping documents, assuring that the products are insured for its replacement cost. Customer will reimburse Adrich for all shipping and insurance costs incurred by Adrich.
          </li>
          <li>
            Data Support and Software: Adrich tech enabled usage tags and post analytic dashboards and communication portals provide a two-way link between products and consumers.  Software pricing includes two (2) months of unlimited connections to be used for data analytics.  After two months, access to data dashboard and communication portal will be billed.  Pricing will be based on the number of consumer connections as specifically outlined in the Contract.  A retainer of 50% of the estimated cost of Customer’s annual consumer connections will be invoiced and paid in advance. Each month, Adrich will supply Customer a Statement of Account noting retainer balance and whether any additional funds are due based on consumer connections.

            Adrich will provide hardware and software technology to the Customer.  Data support for one month is included in the cost of the Contract. Any additional support after this period will be invoiced at rates specified and agreed to in the Contract.
          </li>
          <li>
            Taxes: Customer will pay or reimburse Adrich for amounts equal to any sales, use or other taxes, however designated or levied based upon such charges, the Adrich Products, or this Agreement, including state and local taxes, and any taxes or amounts in lieu thereof paid or payable by Adrich that are timely invoiced to Customer. Such taxes will exclude franchise taxes, employment taxes and taxes based on net income of Adrich. Each party will cooperate with the other in minimizing any applicable tax and, in connection therewith, Customer will provide Adrich with notice of any resale certificates, information regarding out of state use of materials, services or sales, or other exemption certificate, or information reasonably requested by Adrich.
          </li>

          <li>
            Ownership of Intellectual Property: Customer and Adrich agree that Adrich owns all proprietary rights, title and interest, including patent, copyright, trade secret, trademark and other proprietary rights, in and to the Adrich Products, and any bug fixes, enhancements, error corrections, updates, upgrades or other modifications, including custom modifications to the Adrich Products, whether made by Adrich or any third party (collectively, the “Adrich Property”) and nothing herein shall confer on Customer any right of ownership or interest in the Adrich Property.  The parties further agree that all right, title, and interest in and to any joint works created under the Agreement shall vest in and be assigned to Adrich, and to the extent any such rights vest in Customer, Customer hereby assigns all such rights, title, and interest to Adrich.  Customer may provide, or may be asked to provide, feedback to Adrich regarding the Adrich Products. Such feedback may include, among other things, suggestions with respect to methodology, types of features needed, additional functionality and applicability. Any such feedback received from Customer (the “Feedback”) shall be deemed a grant of permission by Customer for Adrich to adopt and use such ideas or suggestions without additional consideration. Unless agreed to in advance in writing by Adrich and Customer, by providing such Feedback, Customer grants Adrich a royalty-free, perpetual, irrevocable, exclusive license to use, reproduce, modify, any information contained in the Feedback alone or as part of other works in any form, media, or technology, whether now known or hereafter developed.
          </li>

          <li>
            Transmission of Customer Data: Customer understands that the technical processing and transmission of certain data of users of the Adrich Products (the “Customer Data”) is fundamentally necessary to the functionality of the Adrich Products. Customer expressly consents to Adrich’s access, use and storage of Customer Data, and Customer acknowledges and understands that Customer Data will involve transmission over the Internet, and over various networks, only part of which may be owned and/or operated by Adrich or Customer. Customer acknowledges and understands that Customer Data may be accessed by unauthorized parties when communicated across the Internet, network communications facilities, telephone or other electronic means. Customer agrees that Adrich is not responsible for any Customer Data which is delayed, lost, altered, intercepted or stored during the transmission of any data whatsoever.
          </li>

          <li>
            Customer Data Security and Integrity: Adrich shall maintain reasonable administrative, physical and technical safeguards for the protection, confidentiality and integrity of Customer Data. Subject to the limited rights granted by Customer hereunder, Adrich acquires no right, title or interest from Customer under this Agreement in or to Customer Data. Customer hereby grants to Adrich the right to perform reviews and general analyses as required of characteristics, usage and quality of Customer Data.  Adrich reserves the right to use data analytics to analyze data across all of Adrich’s affiliates, and any information, patterns discovered, and data generated from use of such analytics shall be owned by Adrich. Adrich shall not disclose Customer Data to third parties except as required by law or as expressly permitted in writing by Customer.
          </li>

          <li>
            Reference Partner: Customer shall serve as a Reference Partner for Adrich.  Under separate cover, Customer will license its logo to Adrich to be used in presentations, case studies and white papers to investors, potential partners and customers.  In addition, Customer agrees that Adrich may share Customer’s contact information (as specified on the first page of the Customer’s Consumer Test Agreement or as provided by Customer) with prospective clients who wish to communicate with current customers and partners of Adrich.
          </li>

          <li>
            Confidentiality and Covenant Not to Employ:<br/>

            a.	Each party agrees at all times to keep strictly confidential all Confidential Information (as hereafter defined) belonging to the other party.  “Confidential Information” shall mean any information, technical data or know-how including, but not limited to, that which comprises or relates to the other party’s confidential and proprietary trade secrets, hardware, software (source code and object code), screens, specifications, designs, plans, drawings, data, prototypes, discoveries, research, developments, processes, procedures, intellectual property, market research, marketing techniques and plans, business plans and strategies, customer names and other information related to customers, price lists, pricing policies and financial information or other business and/or technical information and materials, in oral, demonstrative, written, electronic, graphic or machine-readable form and any analyses, compilations, studies or documents related thereto. Confidential Information shall also expressly include Customer Data and Adrich Property as defined herein.<br/>

            b.	Each party shall at all times protect and safeguard the Confidential Information of the other and agrees not to, in whole or in part, sell, lease, license, assign, transfer, or disclose the Confidential Information to any third party and shall not copy, reproduce or distribute the Confidential Information except as expressly permitted in this Agreement.  Each party shall take every reasonable precaution to prevent the theft, disclosure, and the unauthorized copying, reproduction or distribution of the Confidential Information.  The parties agree, however, that Adrich may disclose Confidential Information on a confidential basis to an independent contractor in connection with any work that the independent contractor is performing on behalf of Adrich in connection with this Agreement.<br/>

            c.	Each party acknowledges that the other party shall have the right to take all reasonable steps to protect its Confidential Information, including, but not limited to, seeking injunctive relief and any other remedies as may be available at law or in equity in the event the other party does not fulfill its obligations under this Section.<br/>

            d.	Each party agrees to restrict access to the other party’s Confidential Information only to those employees or independent contractors who (i) require access in the course of their assigned duties and responsibilities, and (ii) have agreed in writing to be bound by provisions no less restrictive than those set forth in this Section.<br/>

            e.	Without granting any right or license, the obligations of the parties hereunder shall not apply to any material or information that:  (i) is, or at any time becomes, a part of the public domain through no act or omission of the receiving party; (ii) is independently discovered or developed by the receiving party without use of the disclosing party’s Confidential Information;  (iii) is rightfully obtained from a third party without any obligation of confidentiality; or (iv) is already known by the receiving party without any obligation of confidentiality prior to obtaining the Confidential Information from the disclosing party.  In addition, neither party shall be liable for disclosure of Confidential Information if made in response to a valid order of a court or authorized agency of government, provided that notice is promptly given to the party whose Confidential Information is to be so disclosed so that such party may seek a protective order and/or engage in other efforts to minimize the required disclosure.  The parties shall cooperate in seeking the protective order and engaging in such other efforts.<br/>

            f.	Subject to Adrich’s confidentiality obligations, Adrich may use any general know-how, techniques, or procedures acquired by Adrich by virtue of its performance of obligations hereunder.<br/>

            g.	Upon termination, each party shall surrender and/or destroy the other party’s confidential information.<br/>

            h.	During the term of this Agreement and for a period of one (1) year after the date of termination hereof, Customer and Adrich agree that each will not, directly or indirectly, hire, or solicit the hiring of, any employee of the other party hereto or its subsidiaries or affiliates.<br/>

            i.	Customer and Adrich expressly agree and understand that the remedy at law for any breach by it of this Section 11 will be inadequate and that the damages flowing from such breach are not readily susceptible to being measured in monetary terms.  Accordingly, it is acknowledged that on adequate proof of a violation of any legally enforceable provision of this Section 11, each party will be entitled to immediate injunctive relief and may obtain a temporary order restraining any threatened or further breach.  Nothing in this Section 11 will be deemed to limit either party’s remedies at law or in equity for any breach of any of the provisions of this Section 11.
          </li>

          <li>
            Indemnification:<br/>
            a.	Customer agrees to defend, indemnify and hold Adrich harmless from and against any and all third party claims, losses, costs (including reasonable attorneys’ fees), damages, suits or judgments, of any kind or nature, arising out of or relating to any actual or alleged violation or infringement of third party rights related to information or materials provided by Customer.  Customer shall have the right to reasonable notice of, and to defend and settle (but without imposing any ongoing obligation on Adrich) indemnified claims, but if Customer fails to defend, Adrich may defend and settle at Customer’s expense.<br/>

            b.	Adrich agrees to defend, indemnify and hold Customer harmless from and against any and all third party claims, losses, costs (including reasonable attorneys’ fees), damages, suits or judgments, of any kind or nature, arising out of or relating to any actual or alleged violation or infringement of third party rights related to information or materials provided by Adrich.  Adrich shall have the right to reasonable notice of, and to defend and settle (but without imposing any ongoing obligation on Customer) indemnified claims, but if Adrich fails to defend, Customer may defend and settle at Adrich’s expense.<br/>
          </li>

          <li>
            Survival:  Those sections, which by their nature are intended to survive termination, shall survive, including, but not limited to Paragraphs 2, 3, 7, 8, 9, 10, 11 and 12.
          </li>

          <li>
            Independent Contractor:  Adrich’s relationship with Customer under this Agreement is that of an independent contractor.  Neither party is an agent, servant, employee, legal representative, partner or joint venture of the other.  Nothing herein shall be deemed or construed as creating a joint venture or partnership between Adrich and Customer.  Neither party has the power or authority to bind or commit the other. Adrich will be responsible for the supervision, control, compensation, withholdings, health, and safety of its employees and agents.  The parties also expressly acknowledge and agree that, with respect to any fees paid by Customer to Adrich under this Agreement, Customer shall not be responsible or liable for any withholding taxes or contributions to state workers’ compensation, unemployment, or other funds or programs.  Adrich also acknowledges and agrees that, as an independent contractor, employees and agents will not have the right to participate in any benefit or insurance plan or any other fringe benefit, which is provided by Customer for its employees.
          </li>

          <li>
            Notices:  Notices required hereunder shall be in writing and shall be deemed to have been given when delivered by hand, on the date sent by facsimile or e-mail (with confirmation of transmission) if sent before 5:00 p.m. eastern time, and on the next business day if sent after such time, or upon receipt when mailed by registered or certified mail (return receipt requested) or overnight delivery service, postage prepaid to the parties at the following addresses (or at such other address as may be specified by like notice):<br/><br/>

            If to Adrich:<br/><br/>
            Adhithi Aji<br/>
            100 S Commons Suite 102<br/>
            Pittsburgh PA, 15212<br/><br/>

            If to Customer:<br/>
            _______________________<br/>
            _______________________<br/>
            _______________________<br/><br/>
          </li>

          <li>
            Governing Law:  This Agreement shall be governed by and construed in accordance with the laws of the Commonwealth of Pennsylvania (excluding the conflicts of laws rules). Any action or proceeding in connection herewith shall be brought exclusively in the state or federal courts located in Allegheny County, Pennsylvania.
          </li>

          <li>
            Counterparts:  This Agreement may be executed in one or more counterparts, each of which shall constitute an original and all of which together shall constitute one and the same instrument and the exchange of signature pages by facsimile transmission, by electronic mail in “portable document format” (“.pdf”) form, or by any other electronic means shall constitute effective execution and delivery of this Agreement as to the parties and shall be deemed to be original signatures for all purposes.
          </li>

          <li>
            Modification and Waiver:  This Agreement may only be amended or modified by a written agreement signed by both parties.  The failure to enforce any provision of this Agreement shall not constitute or be construed as a waiver of any provision or of the right to enforce it at a later time.
          </li>

          <li>
            Limitation of Damages:  THE ADRICH PRODUCTS ARE PROVIDED “AS IS.”   ADRICH’S CUMULATIVE LIABILITY TO CUSTOMER OR ANY PARTY RELATED TO CUSTOMER FOR ANY LOSS OR DAMAGES ARISING OUT OF OR RELATING TO THIS AGREEMENT, OR USE OF THE APP SHALL NOT EXCEED THE GREATER OF THE AMOUNT OF FEES PAID TO ADRICH UNDER THIS AGREEMENT AND TEN ($10.00) DOLLARS. THIS LIMITATION APPLIES TO ALL CAUSES OF ACTION OR CLAIMS IN THE AGGREGATE, INCLUDING, WITHOUT LIMITATION, BREACH OF CONTRACT, BREACH OF WARRANTY, INDEMNITY, NEGLIGENCE, STRICT LIABILITY, MISREPRESENTATION, AND OTHER TORTS.  UNDER NO CIRCUMSTANCES WILL ADRICH BE LIABLE FOR ANY INDIRECT, INCIDENTAL, CONSEQUENTIAL, SPECIAL, PUNITIVE OR EXEMPLARY DAMAGES (INCLUDING, WITHOUT LIMITATION, LOST PROFITS OR REVENUES OR DAMAGES FOR BUSINESS INTERRUPTION ) ARISING OUT OF OR IN ANY WAY CONNECTED WITH THIS AGREEMENT OR THE PROVISION OF THE APP, REGARDLESS OF THE TYPE OF CLAIM AND EVEN IF SUCH PARTY HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.
          </li>

          <li>
            Disclaimer of Warranties:  EXCEPT AS PROVIDED IN THIS AGREEMENT, ADRICH HEREBY DISCLAIMS ANY AND ALL OTHER WARRANTIES, EXPRESS OR IMPLIED, RELATING TO THE APP, INCLUDING WITHOUT LIMITATION, WARRANTIES OF MERCHANTABILITY, CONDITION AND FITNESS FOR A PARTICULAR PURPOSE.
          </li>

          <li>
            Force Majeure: Excluding payment obligations, neither party shall be liable under this Agreement because of any failure or delay in the performance of its obligations (except for payment of money) on account of strikes, shortages, riots, fire, flood, storm, earthquake, acts of God, hostilities, or any other cause beyond its reasonable control.
          </li>
          <li>
            Assignment:  Neither Customer nor Adrich may assign its rights or obligations under this Agreement without the prior written consent of the other party; provided, however, that either party may assign its rights or delegate its obligations, in whole or in part, without such consent and upon fifteen (15) days prior written notice to the other party, to (a) one of its wholly-owned subsidiaries or affiliates, or (b) an entity that acquires all or substantially all of the business or assets of such party to which this Agreement pertains, whether by merger, reorganization, acquisition, sale, or otherwise.
          </li>
          <li>
            Entire Agreement and Severability:  This Agreement (including the Contract) constitute the entire understanding between Adrich and Customer.  All previous representations and undertakings, whether oral or written, have been merged herein.  If any provision of this Agreement is held invalid, illegal, or unenforceable for any reason, this Agreement shall otherwise remain in full force apart from such provision or partial provision, which shall be deemed deleted.
          </li>
        </ol>

      </div>
    );
  }
}
