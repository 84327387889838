import React, { useState, useEffect } from 'react';
import _ from 'lodash';

import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Switch from '@material-ui/core/Switch';
import Container from '@material-ui/core/Container';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import AppContainer from '../Components/AppContainer';
import withStyle from '../style';
import MeasurementEditor from '../Components/MeasurementEditor';
import MadeWithLove from '../Components/MadeWithLove';

import io from 'socket.io-client';
import {HOST, BACKEND_PORT} from '../Utils/Networker';

import Networker from '../Utils/Networker';

const DAY = 1000 * 60 * 60 * 24;

const getUserMeasurements = (userId) => {
  return Networker.get({
    root: 'measurements',
    query: {
      userId,
      since: new Date(Date.now() - DAY).valueOf()},
      limit: 25,
    cache: false,
  });
};

const saveMeasurementValue = ({id, key, value}) => {
  return Networker.put({
    root: 'measurements',
    query: {id, key, value},
  });
};


export default function Demo({match}) {
  const [user, setUser] = useState(null);
  const [measurements, setMeasurements] = useState([]);
  const [newMeasurements, setNewMeasurements] = useState([]);
  const [connected, setConnected] = useState(false);
  const [edited, setEdited] = useState(false);
  const [needsFetch, setNeedsFetch] = useState(false);
  const [fetchedInitial, setFetchedInitial] = useState(false);

  useEffect(() => {
    if (fetchedInitial && needsFetch && user) {
      setNeedsFetch(false);
      getUserMeasurements(user._id)
      .then((res) => {
        const ids = new Set();
        measurements.forEach(({_id}) => {
          ids.add(_id);
        });
        setNewMeasurements(res.body.filter((item) => {
          return !ids.has(item._id);
        }).sort((a,b) => {
          const adate = a.measurementTimeUTC;
          const bdate = b.measurementTimeUTC;
          return new Date(bdate) - new Date(adate);
        }));
        setEdited(false);
      })
      .catch(err => {
        setMeasurements([]);
        Networker.sendError({
          message: 'dataentry dashboard error',
          namespace: 'dashboard.dataentry',
          data: err
        });
        window.alert('There was an error refreshing, please log out and back in');
      })
    }
  }, [fetchedInitial, measurements, needsFetch, user])

  useEffect(() => {
    if (!user) {
      setMeasurements([]);
      Networker.getUserSession()
      .then(async res => {
        setUser(res.body);
        const measurementReq = await getUserMeasurements(res.body._id)
        setMeasurements(measurementReq.body);
        setFetchedInitial(true);
      })
      .catch(err => {
        console.error(err);
      })
    }
  }, [user]);

  useEffect(() => {
    const socket = io(`${HOST}:${BACKEND_PORT}/measurements`, {
      transports: ['websocket']
    });
    socket.on('connect', function() {
      setConnected(true);
      setNeedsFetch(true);
    });
    socket.on('disconnect', function(err) {
      setConnected(false);
    });
    socket.on('update', _.debounce(async function(data) {
      setNeedsFetch(true);
      console.log('update')
      Networker.interactionBeacon({
        message: 'data entry update',
        data,
      });
    }, 1000, {trailing: true, leading: false}));
    socket.on('error', function(data) {
      console.error('error in socket', data);
      Networker.sendError({
        message: 'data entry dashboard socket error',
        namespace: 'dashboard.dataentry',
        data: data
      });
    });
    return () => {
      socket.close();
    }
  }, []);

  const onEdit = _.debounce(async (id, key, value) => {
    try {
      const meas = measurements.concat(newMeasurements).find(e => e._id === id);
      const diff = String((meas.measuredData || {})[key]) !== String(value);
      if (diff && edited) {
        setEdited(false);
        await saveMeasurementValue({id, key, value});
      }
    } catch (e) {
      if (e.status === 401) {
        window.alert('you are not authorized to edit measurements for this product');
      } else {
        console.error(e);
        setNeedsFetch(true);
      }
    }
  }, 2500, {leading: false, trailing: true});

  const classes = withStyle();
  return <div className={classes.root}>
      <CssBaseline />
      <AppContainer classes={classes} title="Data Entry" match={match}>
        <div className={classes.appBarSpacer} />
        <h3>Synced Measurements: {newMeasurements.length}</h3>
        <Container maxWidth="md">
        <Grid container spacing={4} className={classes.container} justifyContent="center" alignItems="center">
          <Grid item xs={12}>
            <FormGroup row>
              <FormControlLabel
                disabled
                control={
                  <Switch checked={connected} disableRipple={true} size="small" />
                }
                label="Connected"
              />
            </FormGroup>
            <FormGroup row>
              <FormControlLabel
                control={
                  <Switch checked={edited}
                    disableRipple size="small"
                    color="secondary"/>
                }
                label={edited ? 'Modified' : 'Saved'}
              />
            </FormGroup>
          </Grid>
          <Grid item xs={12} container spacing={4} justifyContent="center" alignItems="center">
            {newMeasurements.concat(measurements).map((meas) => {
              return <Grid item key={meas._id} xs={12}>
                <MeasurementEditor
                  measurement={meas}
                  onEdit={(key, value) => {
                    setEdited(true);
                    onEdit(meas._id, key, value);
                  }}/>
            </Grid>})}
          </Grid>
        </Grid>
        </Container>
        <MadeWithLove />
      </AppContainer>
    </div>;
}
