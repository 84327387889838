import React, { useState, useEffect } from 'react';

import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Popover from '@material-ui/core/Popover';
import PopularTimesNoProcessing from '../Components/PopularTimesNoProcessing';

import InfoIcon from '@material-ui/icons/Info';
import { useTheme } from '@material-ui/styles';

import { argMax, sum } from '../Utils/DataUtils';
import Networker from '../Utils/Networker';
import withStyle from '../style';

const daysAbbr = ['Su', 'M', 'Tu', 'W', 'Th', 'F', 'Sa'];
const daysFull = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

export default function PopularDaysNoProcessing(props) {
  const [dayTitle, setDayTitle] = useState([]);
  const [hoursOfDay, setHoursOfDay] = useState([]);
  const [nDayMeasurements, setNDayMeasurements] = useState(0);
  const [bestDay, setBestDay] = useState([]);

  useEffect(() => {

    setBestDay(daysFull[argMax(props.daysOfWeek || [{ name: 'NA', 'uses': 0 }], 'uses')] || 'NA');

  }, [props.daysOfWeek, props.hoursOfDayForDaysOfWeek]);
  

  const showDayTimeChart = ({ name }) => {
    const dayIdx = daysAbbr.indexOf(name);

    if (dayIdx !== -1) {
      setHoursOfDay(props.hoursOfDayForDaysOfWeek[daysFull[dayIdx]]);
      setNDayMeasurements(sum((props.hoursOfDayForDaysOfWeek[daysFull[dayIdx]]).map((obj) => obj.uses)));

      setDayTitle(`${daysFull[dayIdx]} Use Times`);

      Networker.interactionBeacon({
        message: 'day measurement click',
      });
    } else {
      setNDayMeasurements(0);
      setDayTitle('');
      setHoursOfDay([]);
    }
  };

  const classes = withStyle();
  const theme = useTheme();
  const fillColor = theme.palette.secondary.light;
  const strokeColor = theme.palette.secondary.dark;
  return (<Card className={classes.paper}>
    <Typography variant="h5">
      {props.title || 'Most Popular Days'}
    </Typography>
    <ResponsiveContainer height={ 220 }>
      <BarChart data={ props.daysOfWeek }>
        <CartesianGrid strokeDasharray='3 3'/>
        <XAxis dataKey='name'/>
        <YAxis/>
        <Tooltip/>
        <Bar dataKey='uses'
          style={{ cursor: 'pointer' }}
          fill={fillColor}
          stroke={strokeColor}
          onClick={showDayTimeChart} />
      </BarChart>
    </ResponsiveContainer>
    <Popover
      open={nDayMeasurements > 0}//TODO: revisit this logic
      onClose={showDayTimeChart}
      anchorReference="anchorPosition"
      anchorPosition={{ top: 200, left: 400 }}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
    >
      <PopularTimesNoProcessing data={hoursOfDay} title={dayTitle}></PopularTimesNoProcessing>
    </Popover>
    <CardActionArea className={classes.chartInfo}>
      <Grid container alignItems="center" justifyContent="center">
        <InfoIcon /> &nbsp;
        This product is used most often on &nbsp;{bestDay}.
      </Grid>
    </CardActionArea>
  </Card>);
}