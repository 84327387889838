import React, {useState, useEffect} from 'react';
import Typography from '@material-ui/core/Typography';
import brown from '@material-ui/core/colors/brown';
import gold from '@material-ui/core/colors/amber';
import silver from '@material-ui/core/colors/blueGrey';
import platinum from '@material-ui/core/colors/blueGrey';

import Slider from '@material-ui/core/Slider';
import Tooltip from '@material-ui/core/Tooltip';
import { withStyles } from '@material-ui/core/styles';

const marks = [{
  value: 0,
  label: <Tooltip
    title="Insufficient data for quality analysis">
      <Typography variant="subtitle1">
      Raw
    </Typography>
  </Tooltip>,
}, {
  value: 1,
  label: <Tooltip
    title="There is enough data to observe early trends, but patterns may not emerge until more measurements are collected">
      <Typography variant="subtitle1">
      Refined
    </Typography>
  </Tooltip>,
}, {
  value: 2,
  label: <Tooltip
    title="A sufficient number of measurements have been collected in order to observe trends">
      <Typography variant="subtitle1">
      Polished
    </Typography>
  </Tooltip>,
}, {
  value: 3,
  label: <Tooltip
    title="This data has been individually analyzed and cleaned by data engineers at Adrich (Requires Analytics Package)">
      <Typography variant="subtitle1">
      Platinum
    </Typography>
  </Tooltip>,
}];

const getColor = (quality) => {
  const colors = [brown['A400'], silver['A200'], gold['A100'], platinum['50']];
  return colors[quality];
}

function ThumbComponent(props) {
  const color = getColor(props['aria-valuenow']);
  return (
      <span  {...props}>
        <div style={{
            width: '100%', height: '100%', backgroundColor: color,
          }}>
        </div>
      </span>
  );
}

const HEIGHT = 150;

const PrettoSlider = withStyles({
  root: {
    height: 8,
    backgroundSize: 'auto ' + HEIGHT + 'px',
  },
  thumb: {
    height: 16,
    width: 16,
    left: 8,
    borderRadius: 0,
    color: 'currentColor',
    backgroundColor: 'currentColor',
    border: '1.5px solid black',
    boxShadow: '#ebebeb 0px 2px 2px',
    '&:focus,&:hover,&$active': {
      boxShadow: '#ccc 0px 2px 3px 1px',
    },
    '& .bar': {
      // display: inline-block !important;
      height: 9,
      width: 1,
      backgroundColor: 'currentColor',
      marginLeft: 1,
      marginRight: 1,
    },
  },
  active: {},
  valueLabel: {
    left: 10,
  },
  track: {
    height: 8,
    borderRadius: 4,
    backgroundImage: 'linear-gradient(to bottom,currentColor,transparent)',
  },
  rail: {
    height: 8,
    borderRadius: 4,
    opacity: 1.0,
    backgroundImage: 'linear-gradient(to bottom,white,gold,brown,black)',
  },
  mark: {
    backgroundColor: 'white',
    border: '1px solid black',
    transform: 'translateY(8px)',
    opacity: 0.5,
    left: 4,
    width: 14,
    height: 14
  },
  markActive: {
    backgroundColor: '#fff',
    opacity: 0.0,
  },
})(Slider);

export default function DataQualityNoProcessing({platinum, summary, N}) {
  const [quality, setQuality] = useState(0);

  useEffect(() => {
    if (N && N > 0 && summary) {
      let q = 0;
      if (N > summary.numUsers * 10
        && summary.numMeasurements > 1000) {
          q = 2;
        } else if (N > 100) {
          q = 1;
        }
        if (platinum) {
          q = 3;
        }
        if (q !== quality) {
          setQuality(q);
        }
    }
  }, [N, platinum, quality, summary]);

  return <div>
    <Typography id="vertical-slider" gutterBottom color="textSecondary" variant="caption">
      Current View Data Quality
    </Typography>
    <div style={{height: HEIGHT, margin: 15}}>
      <PrettoSlider orientation="vertical"
          marks={marks}
          min={0}
          max={3}
          ThumbComponent={ThumbComponent}
          color="primary"
          valueLabelDisplay="auto"
          aria-label="quality slider"
          value={quality} />
    </div>
  </div>
}
