import React from 'react';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Avatar from '@material-ui/core/Avatar';
import EditIcon from '@material-ui/icons/Edit'
import DuplicateIcon from '@material-ui/icons/ControlPointDuplicate';
import DeleteIcon from '@material-ui/icons/Delete';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';

import UnarchiveIcon from '@material-ui/icons/Unarchive';
import FunctionsIcon from '@material-ui/icons/Functions';

import withStyle from '../style';

function getMiddlewareIcon(field) {
  return field === 'data' ? <UnarchiveIcon /> : <FunctionsIcon/>;
}

export default function MiddlewareCard({middleware, org, onEdit, onDelete, onDuplicate}) {
  const classes = withStyle();
  return <Card className={classes.paper}>
    <CardHeader
      avatar={
        <Avatar classes={classes.avatar} style={{backgroundColor:'#fff'}}>
          {getMiddlewareIcon(middleware.dataField)}
        </Avatar>
      }
      title={<Typography variant="h5">
        {middleware.name}
      </Typography>}
      subheader="Middleware"
      action={
        <React.Fragment>
            <IconButton onClick={onEdit} id={middleware._id}>
              <EditIcon id={middleware._id}/>
            </IconButton>
              <IconButton onClick={onDuplicate} id={middleware._id}>
                <DuplicateIcon id={middleware._id}/>
              </IconButton>
            <IconButton onClick={onDelete} id={middleware._id} color="secondary">
              <DeleteIcon id={middleware._id} />
            </IconButton>
        </React.Fragment>
      }
      >
    </CardHeader>
    <CardContent>
      <Typography variant="subtitle2" component="div">
        In: {middleware.inputTransformerURL}
      </Typography>
      <Typography variant="body2" component="div">
        {middleware.APImethod.toUpperCase()} {middleware.APIendpoint}
      </Typography>
      <Typography variant="subtitle2" component="div">
        Out: {middleware.outputTransformerURL}
      </Typography>
      <Typography variant="caption" component="div">
        Timeout: {middleware.timeout} ms
      </Typography>
    </CardContent>
    </Card>;
}
