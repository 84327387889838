import React from 'react';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { camelToRead } from '../../../../Utils/DataUtils';

// props.dataSummary, N, props.amountChange 
export default function CurrentView(props) {
  function formatDate(milliseconds, separator) {
    const date = new Date(milliseconds);
    const year = date.getFullYear();
    let month = (date.getMonth() + 1).toString().padStart(2, '0');
    let day = date.getDate().toString().padStart(2, '0');

    if (separator === '-') {
      // Date format with dashed separator (e.g., YYYY-MM-DD)
      return `${year}-${month}-${day}`;
    } else if (separator === '/') {
      // Date format with slash separator (e.g., YYYY/MM/DD)
      return `${year}/${month}/${day}`;
    } else {
      // Default to dashed separator if separator is not provided or invalid
      return `${year}-${month}-${day}`;
    }
  }
  // Define the unit based on the value of props.amountChange
  let unit;
  switch (props.amountChange) {
    case "ounce":
      unit = "oz";
      break;
    case "ml":
      unit = "ml";
      break;
    case "grams":
      unit = "g";
      break;
    default:
      // Handle the case when props.amountChange is none of the specified values
      // You may want to provide a default value or show an error message
      unit = "";
      break;
  }

  return <Grid container justifyContent="center" alignItems="flex-start" spacing={3}>
    <Grid item xs={12}>
      <Divider></Divider>
    </Grid>
    <Grid item>
      <Card elevation={2}>
        <CardHeader
          title="Currently Viewing"
          subheader={
            <React.Fragment>
              <Typography variant="body2" color="textSecondary">
                {props.N}
              </Typography>
              <Typography variant="caption">
                <Typography fontWeight="fontWeightBold">
                  Product Uses of dates :
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  {formatDate(props.dateRangeDisplayed[0], '/')} - {formatDate(props.dateRangeDisplayed[1], '/')}
                </Typography>
              </Typography>
            </React.Fragment>
          }
        >
        </CardHeader>
      </Card>
    </Grid>
    {props.dataSummary
      .filter(item => item.name === 'usageDuration' || item.name === 'usageCount' || item.name === 'refillBeforeReplenishment') // Filter the dataSummary array to get the object with name equal to "amountLeft"
      .map(({ name, mean, dMean, isSparse, total, dMeanO, meanO, dMeanMl, meanMl }) => {
        // const dMeanValue = props.amountChange ? dMeanO : dMean;
        // Assuming dMeanO, dMeanML, and dMeanGM are already defined with the appropriate values
        let dMeanValue;
        switch (props.amountChange) {
          case "ounce":
            dMeanValue = dMeanO?.toFixed(2);
            break;
          case "ml":
            dMeanValue = dMeanMl?.toFixed(2);
            break;
          case "grams":
            dMeanValue = dMean;
            break;
          default:
            // Handle the case when props.amountChange is none of the specified values
            // You may want to provide a default value or show an error message
            break;
        }

        // Now, dMeanValue will be assigned the appropriate value based on the props.amountChange value
        let unit = name.endsWith('Time') ? 's' :
          name.toLowerCase().includes('amount') ? 'g' : '';
        if (props.amountChange === "ounce") {
          unit = "oz";
        }
        if (props.amountChange === "ml") {
          unit = "ml";
        }
        return <Grid item key={name}>
          <Card elevation={1}>
            <CardHeader
              disableTypography
              title={<Typography variant="h5">{camelToRead(name)}</Typography>}
              subheader={
                <React.Fragment>
                  {isSparse ? null : <Typography variant="body1" fontWeight="fontWeightBold">
                    Average / Use
                    <Typography variant="body2" color="textSecondary">
                      {(dMeanValue || 0.0)} {unit}
                    </Typography>
                  </Typography>
                  }
                  <Typography variant="body1">
                    {isSparse ? 'Measurements' : 'Average'}
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    {isSparse ? total : (props.amountChange === "ounce") ? meanO.toFixed(2) : (props.amountChange === "ml") ? meanMl : mean} {!isSparse && unit}
                  </Typography>
                </React.Fragment>
              }
            ></CardHeader>
          </Card>
        </Grid>
      })}
    {props.dataSummary.some(item => item.name === 'floorMin' || item.name === 'floorSec' || item.name === 'handMin' || item.name === 'handSec' || item.name === 'totalMin' || item.name === 'totalSec') && (
      <Grid className='MuiGrid-item'>
        <Card elevation={1}>
          <CardHeader
            disableTypography
            title={<Typography variant="h5">Custom Usage Metrics</Typography>}
            subheader={
              <React.Fragment>
                {props.dataSummary
                  .filter(item => item.name === 'floorMin' || item.name === 'floorSec' || item.name === 'handMin' || item.name === 'handSec' || item.name === 'totalMin' || item.name === 'totalSec')
                  .map(({ name, mean }) => (
                    <React.Fragment key={name}>
                      <Typography variant="body1" fontWeight="fontWeightBold">
                        {name}
                        <Typography variant="body2" color="textSecondary">
                          {(mean || 0.0)}
                        </Typography>
                      </Typography>
                    </React.Fragment>
                  ))}
              </React.Fragment>
            }
          ></CardHeader>
        </Card>
      </Grid>
    )}
    <Grid item>
      {props.volumeUse?.[props.amountChange] || props.amountLeft?.[props.amountChange] || typeof props.perLabel === 'number' || typeof props.duration === 'number' ||
        typeof props.frequency === 'number' ? (
        <Card elevation={1}>
          <CardHeader
            disableTypography
            title={<Typography variant="h5">Usage Trends</Typography>}
            subheader={
              <React.Fragment>
                {props.volumeUse?.[props.amountChange] && (
                  <Typography variant="caption">
                    <Typography fontWeight="fontWeightBold">
                      Avg. Volume per Use
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      {props.volumeUse[props.amountChange]} {unit}
                    </Typography>
                  </Typography>
                )}
                {props.amountLeft?.[props.amountChange] && (
                  <Typography variant="caption">
                    <Typography fontWeight="fontWeightBold">
                      Avg. Amount left per container
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      {props.amountLeft[props.amountChange]} {unit}
                    </Typography>
                  </Typography>
                )}
                {typeof props.perLabel === 'number' && (
                  <Typography variant="caption">
                    <Typography fontWeight="fontWeightBold">
                      Avg. Uses per container
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      {props.perLabel}
                    </Typography>
                  </Typography>
                )}
                {typeof props.duration === 'number' && (
                  <Typography variant="caption">
                    <Typography fontWeight="fontWeightBold">
                      Avg. Lifetime per Container
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      {props.duration} {'days'}
                    </Typography>
                  </Typography>
                )}
                {typeof props.frequency === 'number' && (
                  <Typography variant="caption">
                    <Typography fontWeight="fontWeightBold">
                      Avg. Frequency of Use (Time)
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      {props.frequency} {'days'}
                    </Typography>
                  </Typography>
                )}
                {/* Add more content as needed */}
              </React.Fragment>
            }
          ></CardHeader>
        </Card>
      ) : null}
    </Grid>

  
    {props.dataSummary.some(item => item.name === 'needsRefill' || item.name === 'needsRefill_50' || item.name === 'refilled') &&
      <Grid item>
        <Card elevation={1}>
          <CardHeader
            disableTypography
            title={<Typography variant="h5">Fill Level</Typography>}
            subheader={
              <React.Fragment>
                {/* Conditionally display the replenishment data summary values */}
                {props.dataSummary.some(item => item.name === 'needsRefill_50') && (
                  <Typography variant="caption">
                    <Typography fontWeight="fontWeightBold">
                      Containers at 50% Fill Level
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      {props.dataSummary.map(item => item.name === 'needsRefill_50' && item.total)}
                    </Typography>
                  </Typography>
                )}
                {props.dataSummary.some(item => item.name === 'needsRefill') && (
                  <Typography variant="caption">
                    <Typography fontWeight="fontWeightBold">
                      Containers at Point of Replenishment
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      {props.dataSummary.map(item => item.name === 'needsRefill' && item.total)}
                    </Typography>
                  </Typography>
                )}
                {props.dataSummary.some(item => item.name === 'refilled') && (
                  <Typography variant="caption">
                    <Typography fontWeight="fontWeightBold">
                      Containers Replenished
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      {props.dataSummary.map(item => item.name === 'refilled' && item.total)}
                    </Typography>
                  </Typography>
                )}
                {/* Add more content as needed */}
              </React.Fragment>
            }
          ></CardHeader>
        </Card>
      </Grid>
    }
  </Grid>
}
