import Networker from './Networker';
import ColumnNameMap from './ColumnNameMap.json';
import {hashId} from './Cryptography';
import moment from 'moment';

export function camelToRead(str) {
  return str.charAt(0).toUpperCase() + str.slice(1).replace(/([A-Z])/g, " $1");
}

export function argMax(array, key) {
  return array.map((x, i) => [x[key], i]).reduce((r, a) => (a[0] > r[0] ? a : r))[1];
}

export const isInt = (x) => x % 1 === 0;

export const isDate = (value) => {
  const d = new Date(value);
  return typeof value === 'string' && d instanceof Date && value.endsWith('Z');
};

export const sum = (arr) => {
  return arr.reduce((sum, elem) => {
    return sum + elem;
  }, 0.0);
};

export const mean = (arr, dataKey, isDate) => {
  return arr.reduce((sum, elem) => {
    const val = dataKey ? elem[dataKey] : elem;
    const num = isDate ? Date.parse(val) : parseFloat(val);
    return sum + (Number.isFinite(num) ? num : 0.0);
  }, 0.0) / (arr.length || 1);
};

export const meanCounter = (arr, dataKey) => {
  const counter = {};
  arr.forEach((elem) => {
    if (counter[elem[dataKey]]) {
      counter[elem[dataKey]] += 1;
    } else {
      counter[elem[dataKey]] = 1;
    }
  });
  return mean(Object.values(counter));
};

export const std = (arr, dataKey, isDate, precomputedMean) => {
  const avg = precomputedMean !== undefined ? precomputedMean : mean(arr, dataKey, isDate);
  return Math.sqrt(arr.reduce((sum, elem) => {
    const val = dataKey ? elem[dataKey] : elem;
    const num = isDate ? Date.parse(val) : parseFloat(val);
    return sum + (num - avg)**2;
  }, 0.0) / (arr.length || 1));
};

const transformSurveyHeader = (header) => {
  const startsWithSurveyId = header.replace('surveyResponses_', '').replace('meta', '');
  const splitString = startsWithSurveyId.split('_');
  const id = splitString[0];
  const field = splitString[splitString.length - 1];

  return `"${camelToRead(field)} (Survey #${id})"`;
};

function columnSorter(a, b) {
  const aTrim = a.replace(/[_]+/g, '_').replace(/^[_]/g, '');
  const bTrim = b.replace(/[_]+/g, '_').replace(/^[_]/g, '');
  const aIsNested = aTrim.includes('_');
  const bIsNested = bTrim.includes('_');
  const aDepth = (bTrim.match(/[_]/g) || []).length;
  const bDepth = (bTrim.match(/[_]/g) || []).length;

  if (a === 'outlier') {
    return 1;
  }
  if (b === 'outlier') {
    return -1;
  }

  if (aIsNested && bIsNested && aDepth === bDepth) {
    return aTrim > bTrim ? 1 : -1;
  } else if (aIsNested && bIsNested) {
    return aDepth - bDepth;
  } else if (aIsNested && !bIsNested) {
    return 1;
  } else if (bIsNested && !aIsNested) {
    return -1;
  }
  return aTrim > bTrim ? 1 : -1;
}

export const downloadObjectData = (array, name, hideMac) => {
  const keys = new Set();

  const arrayWithGeneratedFields = array.map(meas => {
    if(meas.mac !== undefined){
      return {...meas, labelId:hashId(meas.mac)};
    }else{
      return meas;
    }
  });  

  arrayWithGeneratedFields.forEach(meas => {
    Object.keys(meas).forEach(key => keys.add(key));
  });
  
  const allKeys = Array.from(keys).filter(key => {
      return !(key.endsWith('URL') ||
        (hideMac ? key.startsWith('location') : false) ||
        key === 'measurementTimeZoneOffset' ||
        (hideMac ? key === 'mac' : false) ||
        (hideMac ? key === 'outlier' : false));
    })
    .sort(columnSorter);
  const dataStrings = arrayWithGeneratedFields.map(meas => {
    let str = '';
    allKeys.forEach((key, idx) => {
      let enc = '';
      if (String(meas[key]).includes(',')) enc = '"';
      if (key === 'measurementTimeLocal') {
        // clean up the time parse method and use moment.js to get the correct time
        var d = moment(meas[key]).utc();

        var dateStr = d.format('YYYY-MM-DD');
        var timeStr = d.format("hh:mm:ss a");
        var dayStr = d.format('dddd');

        str += `${dateStr},${timeStr},${dayStr},`;
      } else if (key === 'measurementTimeUTC') {
        str += `${String(meas[key]).replace('Z','')}${idx < allKeys.length - 1 ? ',' : ''}`;
      } 
      else {
        str += `${enc}${String(meas[key])}${enc}${idx < allKeys.length - 1 ? ',' : ''}`;
      }
    });
    return str;
  });

  const now = new Date(Date.now());
  const datestr = now.toString();
  const header = allKeys.map(key => {
    const transformedKey = ColumnNameMap[key] || key;
    if ((key.includes(' ') || key.includes(',')) &&
      !key.startsWith('surveyResponses')) {
      return `"${transformedKey}"`;
    } else if (key === 'measurementTimeLocal') {
      return [
        '"Date"',
        '"Time"',
        '"Day of Week"'
      ];
    } else if (key.startsWith('surveyResponses')) {
      return `${transformSurveyHeader(key)}`;
    }
    return transformedKey;
  }).flat().join(',') + '\n';
  saveString(header + dataStrings.join('\n'),
    `${name}_download_${datestr}.csv`);
  Networker.interactionBeacon({
    message: 'download csv',
    data: dataStrings.length,
  });
};

export const saveString = (str, filename) => {
  // build a link and trigger a download
    const text = str;
    const blob = new Blob([text]);

    if (window.navigator.msSaveOrOpenBlob) { // compat: ie10
      window.navigator.msSaveOrOpenBlob(blob, filename.replace(/[ ]/g, '_'));
    } else {
      const a = document.createElement("a");
      a.setAttribute("href", URL.createObjectURL(blob, {type: 'text/csv'}));
      a.setAttribute("download", filename.replace(/[ ]/g, '_'));
      a.setAttribute("target", "_blank");
      a.style.display = "none";
      document.body.appendChild(a);
      a.click();
      setTimeout(() => {
        document.body.removeChild(a);
      }, 0);
    }
};

export const unwindObject = (dat, prefix, ignoreKeys) => {
  const ignoreMap = {};
  ignoreKeys.forEach(key => ignoreMap[key] = true);

  const vals = Object.keys(dat || {}).map(key => {
    if (typeof dat[key] === 'object' && !ignoreMap[key]) {
      return unwindObject(dat[key], (prefix ? prefix + '_' : '') + key, ignoreKeys);
    } else if (!Array.isArray(dat[key]) && !ignoreMap[key]) {
      return {[`${prefix ? prefix + '_' : ''}${key}`]: dat[key]};
    }
    return null;
  }).filter((elem) => elem);

  let newobj = {};
  vals.forEach(elem => {
       Object.keys(elem).forEach(key => {
        newobj[key] = elem[key];
      });
  });
  return newobj;
};

export const getCreatedAtFromObjectId = (oid) => {
  return new Date(parseInt(oid.substring(0, 8), 16) * 1000);
};

export function not(a, b) {
  return a.filter(value => b.indexOf(value) === -1);
}

export function intersection(a, b) {
  const bIds = {};
  b.forEach(val => bIds[val._id] = true);
  return a.filter(value => bIds[value._id]);
}

export const tempOptions = [
  { value: 'celsius', label: '°C' },
  { value: 'fahrenheit', label: '°F' },
];

export const weightOptions = [
  { value: 'grams', label: 'Grams' },
  { value: 'ounce', label: 'Ounce' },
  { value: 'ml', label: 'ML' }
];