const crypto = require('crypto-js');

// perform sha256 followed by md5 hashing to hide id, 
// but still get the same result for the same id
export const hashId = (str) => {
  const sha256 = crypto.createHash('sha256');
  const md5 = crypto.createHash('md5');
  const sha256Digest = sha256.update(str).digest('hex');
  const md5Digest = md5.update(sha256Digest).digest('hex');
  return md5Digest;
}