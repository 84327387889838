import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

// MUI Icons
import WarningIcon from "@material-ui/icons/Warning";
import DeleteIcon from "@material-ui/icons/Delete";
import { Typography } from "@material-ui/core";

const DeleteDialog = (props) => {
  const { dltAlert, onDelete, handleCloseDltAlert, dltName, pageName } = props;

  const alertBoxStyle = {
    display: "flex",
    height: "100px",
    minWidth: "400px",
    borderLeft: "5px solid #ff906b",
    padding: "20px",
    alignItems: "start",
    backgroundColor: "#ff000038",
  }

  return (
    <div>
      <Dialog
        open={dltAlert}
        onClose={handleCloseDltAlert}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" style={{ textAlign: "center" }}>
          {`Delete ${pageName ? pageName : "Record"}`}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description" style={{ textAlign: "center" }}>
            {`Are you sure you want to delete ${dltName ? dltName : ""}?`}
          </DialogContentText>

          <div style={alertBoxStyle}>
            <WarningIcon style={{ width: "40px" }} color="error" />
            <div>
              <Typography>
                <b>Warning</b>
              </Typography>
              <Typography>This process will not be reversed.</Typography>
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={onDelete} color="primary" variant="outlined">
            Delete <DeleteIcon fontSize="small" />
          </Button>

          <Button onClick={handleCloseDltAlert} color="secondary" variant="contained">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default DeleteDialog;