import React, { useState } from 'react';
import Avatar from '@material-ui/core/Avatar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Networker from '../Utils/Networker';
import MadeWithLove from '../Components/MadeWithLove';
import withStyle from '../style';

const emailRX = /^([-!#-'*+/-9=?A-Z^-~]+(\.[-!#-'*+/-9=?A-Z^-~]+)*|"[^"]+")@[0-9A-Za-z]([0-9A-Za-z-]{0,61}[0-9A-Za-z])?(\.[0-9A-Za-z]([0-9A-Za-z-]{0,61}[0-9A-Za-z])?)+$/;
export default function EmailCheck() {
  const classes = withStyle();
  const [email, setEmail] = useState('');
  const [sent, setSent] = useState('');
  const emailSetter = (e) => {
    setEmail(e.target.value);
  };

  const generateToken = (e) => {
    if (!emailRX.test(email)) {
      window.alert(email + ' is not a valid email address');
      setSent(false);
      return;
    }
    Networker.generatePasswordResetToken({ email })
      .then(res => {
        setSent(true);
      })
      .catch(err => {
        setSent(true);
      });
  };

  return (
    <Container component="main" maxWidth="sm">
      <CssBaseline />
      <div className={classes.paper}>
        <center style={{ margin: '10vh' }}>
          <img src="/logo.png" width="100%" alt="Adrich Dashboard Logo" />
        </center>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <TextField
          id="email"
          fullWidth
          label="Enter Your Email"
          onChange={emailSetter}
        />
        <br />
        {sent ? (
          <Typography component="h1" variant="h5">
            Check Your Email For A Unique Password Reset Link!
          </Typography>
        ) : (
          <Button onClick={generateToken} variant="contained">
            Recover My Account
          </Button>
        )}
      </div>
      <Box mt={5}>
        <MadeWithLove />
      </Box>
    </Container>
  );
}
