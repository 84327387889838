import React, {useState, useEffect} from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import IconButton from '@material-ui/core/IconButton';
import Chip from '@material-ui/core/Chip';
import Divider from '@material-ui/core/Divider';
import ReactJson from 'react-json-view';
import DeleteDialog from './DeleteDialog';
import FileCopyIcon from '@material-ui/icons/FileCopy';

import {
  getAllTags,
  getTriggerDescriptions
} from '../Utils/NotificationUtils';
import {
  camelToRead
} from '../Utils/DataUtils';

import Networker from '../Utils/Networker';

import withStyle from '../style';

const matchTags = (message, tags) => {
  const getTagRx = (tag) => {
    return new RegExp('[[](' + tag + ')[\\]]', 'gm');
  }

  const tagmatches = tags.map((tag, i) => {
    const regex = getTagRx(tag);
    return message.match(regex);
  });
  return tagmatches.map((matched, i) => {
    return [tags[i], !!matched];
  });
}


export default React.memo(function MessageViewer({ notification, onDelete, onEdit, onCopy }) {
  const [tagMatches, setTagMatches] = useState([]);
  const [allTags, setAllTags] = useState([]);
  const [triggerDescriptions, setTriggerDescriptions] = useState({});
  const [dltAlert, setDltAlert] = useState(false);

  const classes = withStyle();

  useEffect(() => {
    getAllTags()
    .then(tags => {
      setAllTags(tags);
    })
    .catch((err) => {
      console.error(err);
      Networker.sendError({
        message: "Tag description fetch error",
        namespace: "dashboard.messageviewer",
        data: err.toString()});
    });
    getTriggerDescriptions()
    .then(desc => {
      setTriggerDescriptions(desc);
    })
    .catch((err) => {
      console.error(err);
      Networker.sendError({
        message: "Trigger description fetch error",
        namespace: "dashboard.messageviewer",
        data: err.toString()});
    });
  }, []);

  const handleOpenDltAlert = () => {
    setDltAlert(true);
  }

  const handleCloseDltAlert = () => {
    setDltAlert(false);
  }

  useEffect(() => {
    setTagMatches(matchTags(notification.message, allTags));
  }, [allTags, notification.message]);
  const descriptor = triggerDescriptions[notification.trigger.type] || {};
  let jsonvalue = notification.trigger.value;
  if (descriptor) {
    if (descriptor.value === 'json' &&
      typeof jsonvalue === 'string') {
      try {
        jsonvalue = JSON.parse(notification.trigger.value) || []
      } catch (e) {
        console.error('value is not valid json', e);
        jsonvalue = {};
      }
    }
  }
  const isActive = notification.active;
  return notification ? <Paper style={{
      padding: 15,
    }} elevation={isActive ? 4 : 0}>
      <Grid container alignItems="center" justifyContent="flex-start" spacing={1}>
        <Grid item xs={8}>
          <Typography variant="h5">
            {camelToRead(notification.notificationType)} {isActive ? '' : '(Inactive)'}
          </Typography>
        </Grid>
        <Grid item xs={4}>
        <IconButton id={notification._id} onClick={handleOpenDltAlert} style={{ float: 'right' }}>
            <DeleteIcon id={notification._id} fontSize="small" color="secondary"/>
          </IconButton>
          <IconButton id={notification._id} onClick={onEdit} style={{float: 'right'}}>
            <EditIcon id={notification._id} fontSize="small" color="primary"/>
          </IconButton>
        <IconButton id={notification._id} onClick={(e) => onCopy(e, notification)} style={{ float: 'right' }}>
          <FileCopyIcon id={notification._id} fontSize="small" color="primary" />
        </IconButton>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body1" >
            <strong>Trigger</strong>: {descriptor.description}
          </Typography>
        </Grid>
        {descriptor.field ? <Grid item>
            <Typography variant="body2">
              <strong>Key</strong>: {camelToRead(notification.trigger.field)}
            </Typography>
        </Grid> : null}
        {descriptor.value ? <Grid item xs={12}>
          {descriptor.value === 'json' ?
            <ReactJson
              displayDataTypes={false}
              theme="solarized"
              indentWidth={2}
              sortKeys={true}
              enableClipboard={false}
              name="Rules"
              validationMessage="Check json-rules-engine npm package for more information"
              src={jsonvalue}>
            </ReactJson>
           : <Typography variant="body1">
             <strong>Value</strong>: {notification.trigger.value}
           </Typography>}
        </Grid> : null}
        <Grid item xs={12}>
          <Divider variant="middle"></Divider>
          {notification.notificationType === 'email' ?
            <Typography variant="subtitle1"
              gutterBottom
              align="left" className={classes.notificationSubject}>
              Subject: {notification.subject}
            </Typography> : null}
            <Typography variant="body1"
          align="justify" className={classes.notificationMessage}>
          {notification.notificationType !== "text" ? <div dangerouslySetInnerHTML={{ __html: notification.message }}></div> : notification.message} 
            </Typography>
        </Grid>
        <Grid item xs={12}>
          {tagMatches.map(([key, match]) => {
            return <Chip variant="outlined" key={key}
              color={match ? 'secondary' : 'primary'}
              size="small"
              label={camelToRead(key)}/>
          })}
        </Grid>
      <Grid item xs={12}>
        {notification?.actionButton?.map((button) => {
          return <Chip variant="outlined" key={button}
            color={'secondary'}
            size="small"
            style={{ margin: "0.1rem" }}
            label={button} />
        })}
      </Grid>
      </Grid>

    {dltAlert && <DeleteDialog
      onDelete={(e) => onDelete(e, notification._id)}
      dltAlert={dltAlert}
      handleCloseDltAlert={handleCloseDltAlert}
      handleOpenDltAlert={handleOpenDltAlert}
      dltName={"this trigger"}
      pageName={"Trigger"}
    />}
    </Paper>: null;
});
