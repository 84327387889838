import { fileUploaderAWS } from "./s3Upload";

export const insertImage = (editor, url) => {
    const image = editor.selection.j.createInside.element('img');
    image.src = url
    editor.selection.insertNode(image);
}


export const imageUpload = (editor, AWS, setLoading, setQuillValue) => {
    try {

        const input = document.createElement('input');
        input.setAttribute('type', 'file');
        input.setAttribute('multiple', "");
        input.setAttribute('accept', 'image/png,image/jpeg');
        input.click();

        input.onchange = async function () {
            const imageFile = input.files


            for (let i = 0; i < imageFile.length; i++) {
                const file = imageFile[i]
                const { size } = file
                if (size > 1000000) {
                    const alert = editor.alert("Please Upload Image less than 1 MB", "Image Limit")
                    alert.dialogbox_content.style.display = "grid"
                    alert.dialogbox_content.style.placeItems = "center"
                    continue
                }
                setLoading(true)
                let fileUrl = await fileUploaderAWS(file, AWS)
                setQuillValue(prev => ({ ...prev, imageUrl: fileUrl }))
                setLoading(false)
                insertImage(editor, fileUrl);
            }
        }
    } catch (error) {
        console.error(error)
    }
}