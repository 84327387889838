import React from 'react';
import { CSVReader } from 'react-papaparse';
import Button from '@material-ui/core/Button';

const buttonRef = React.createRef();

const handleOpenDialog = (e) => {
  if (buttonRef.current) { buttonRef.current.open(e); }
};

const handleOnError = (err) => { console.log(err); };

export default function CSVUploader({ onLoad }) {
  const onFileLoad = (data) => {
      onLoad(data);
      buttonRef.current.removeFile();
  };

  return (<CSVReader style={{ margin: 0 }} ref={buttonRef} header={true} onFileLoad={onFileLoad} onError={handleOnError} noClick noDrag>
    {() => (<Button fullWidth type="submit" onClick={handleOpenDialog} variant="contained" color="secondary">Upload Consumers</Button>)}
  </CSVReader>);
}
