import React from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';


export default function SummaryData({ summary, hasSurvey, isSuperUser, product, sizeEstimate, amountChange }) {
    if (!summary || !product) {
      return <Typography variant="h4">Loading</Typography>;
    }
    return <Grid container alignItems="center" justifyContent="center" spacing={3}>
      <Grid item xs={12} md={3}>
        <Typography variant="h5" display="block" component="div" align="center" color="textPrimary">
          {summary.numMeasurements}
          <Typography variant="body1" component="div" color="textSecondary">
            Total Lifetime Product Uses
          </Typography>
        </Typography>
      </Grid>
      <Grid item xs={12} md={3}>
        <Typography variant="h5" display="block" component="div" align="center" color="textPrimary">
          {summary.numMacs}
          <Typography variant="body2" color="textSecondary">
            Tag{summary.numMacs !== 1 ? 's' : ''} Detected
          </Typography>
        </Typography>
      </Grid>
      <Grid item xs={12} md={3}>
        <Typography variant="h5" display="block" component="div" align="center" color="textPrimary">
          {summary.numUsers}
          <Typography variant="body2" color="textSecondary">
            Unique Users
          </Typography>
        </Typography>
      </Grid>
      <Grid item xs={12} md={3}>
        <Tooltip title={hasSurvey !== false && hasSurvey >= 0 ? '' : 'Requires Connect Package'}>
          <Typography variant="h5" align="center" color="textPrimary">
            {hasSurvey !== false && hasSurvey >= 0 ? hasSurvey : '-'}
            <Typography variant="body2" color="textSecondary">
              Surveys Completed
            </Typography>
          </Typography>
        </Tooltip>
      </Grid>
    </Grid>;
  }