import Networker, { HOST, BACKEND_PORT } from '../../../../Utils/Networker';

const MAX_FETCH = 100000;

export default function  handleDirectDownload (productId, since, until, currentViewSize)  {
    Networker.interactionBeacon({
        message: 'product direct download',
        data: {
            product: productId
        }
    });
    const timezoneOffset = new Date().getTimezoneOffset();
    const timeSplit1 = new Date().toString().split(')');
    const timeSplit2 = timeSplit1 && timeSplit1.length > 1 ? timeSplit1[0].split('(') : [];
    const timezoneString = timeSplit2 && timeSplit2.length > 1 ? timeSplit2[1] : '';
    const url = `${HOST}:${BACKEND_PORT}/measurements/download?productId=${productId}&limit=${MAX_FETCH}&since=${since}&until=${until}&timezoneOffset=${timezoneOffset}&timezoneString=${timezoneString}`;

    // this will trigger the download as soon as the browser receives the http response
    window.location.assign(url);
    // if(currentViewSize >= 1000){
    window.alert("Your file is being processed. The download will start automatically when the file is ready.");
    // }
};
