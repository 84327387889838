import React, { useState, useEffect } from 'react';

import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';
import Divider from '@material-ui/core/Divider';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import ReactJson from 'react-json-view';
import Networker from '../Utils/Networker';

import {
  camelToRead
} from '../Utils/DataUtils';

import {
  getTriggerDescriptions
} from '../Utils/NotificationUtils';

// TODO
// const validateRule = (rule) => {
//   if (rule.conditions && rule.event) {
//     if (rule.conditions.all || rule.conditions.any) {
//       if ((rule.conditions.any || rule.conditions.all).every(((condition) => {
//         const fact = condition.fact;
//         if (fact) {
//           return ['product', 'measurement', 'notification'].includes(fact) ||
//           fact.startsWith('user.') || fact.startsWith('product.');
//         } else {
//           console.error('Every condition must have a fact', condition);
//           return false;
//         }
//       }))) {
//         if (rule.event.type === 'send') {
//           return true;
//         }
//       }
//       console.error('Fact is undefined', (rule.conditions.any || rule.conditions.all));
//       return false;
//     }
//     console.error(
//       'Rule must have either any or all at top level condition', rule.conditions);
//     return false;
//   }
//   console.error(
//     'Rule needs conditions and event', rule);
//   return false;
// }


const getDefaultRule = () => {
  return [{
    conditions: {
      all: [{
        all: [{
          fact: 'user',
          operator: 'equal',
          value: '',
          path: '$'
        }, {
          fact: 'product',
          operator: 'equal',
          path: '$'
        }, {
          fact: 'measurement',
          operator: 'equal',
          path: '$'
        }]
      }]
    },
    event: {
      type: 'send'
    }
  }];
}

function updateClipboard(newClip) {
  navigator.clipboard.writeText(newClip).then(() => {
    /* clipboard successfully set */
  }, function () {
    /* clipboard write failed */
  });
}
const clipEnable = (copy) => {
  try {
    navigator.permissions.query({ name: "clipboard-write" })
      .then(result => {
        if (result.state === "granted" || result.state === "prompt") {
          /* write to the clipboard now */
          updateClipboard(JSON.stringify(copy.src));
        }
      });
  } catch (e) {
    console.error('copy failed');
  }
};


export default function ApiMessageEditor({
  notification, handleSaveNotification
}) {
  const [triggerDescriptions, setTriggerDescriptions] = useState({});
  const [newHeaderName, setNewHeaderName] = useState('');
  const [newHeaderValue, setNewHeaderValue] = useState('');
  const [newNotification, setNewNotification] = useState(notification);

  useEffect(() => {
    getTriggerDescriptions()
      .then(desc => {
        setTriggerDescriptions(desc);
      })
      .catch((err) => {
        console.error(err);
        Networker.sendError({
          message: "Trigger description fetch error",
          namespace: "dashboard.messageeditor",
          data: err.toString()
        });
      });
  }, []);

  const removeHeader = (index) => {
    var headers = newNotification.apiConfiguration?.headers;
    headers.splice(index, 1);
    setNewNotification({
      ...newNotification,
      apiConfiguration: {
        ...newNotification.apiConfiguration,
        headers: headers
      }
    });
  }
  const addHeader = (name, value) => {
    setNewNotification({
      ...newNotification,
      apiConfiguration: {
        ...newNotification.apiConfiguration,
        headers: [...newNotification.apiConfiguration?.headers, { headerName: name, headerValue: value }]
      }
    });
    setNewHeaderName('');
    setNewHeaderValue('');
  }

  const handleChange = name => event => {
    if (name.indexOf('apiConfiguration') > -1) {
      setNewNotification({
        ...newNotification,
        apiConfiguration: {
          ...newNotification.apiConfiguration,
          [name.split('.')[1]]: event.target.value
        }
      });
    } else if (name.indexOf('.') > -1) {
      setNewNotification({
        ...newNotification,
        trigger: {
          ...newNotification.trigger,
          [name.split('.')[1]]: event.target.value
        }
      });
    } else if (event.target.hasOwnProperty('checked')) {
      setNewNotification({
        ...newNotification,
        [name]: event.target.checked
      });
    } else {
      setNewNotification({
        ...newNotification,
        [name]: event.target.value
      });
    }
  };
  const handleJSONChange = name => ({
    updated_src, name, namespace, new_value, existing_value
  }) => {
    console.log(updated_src)
    setNewNotification({
      ...newNotification,
      trigger: {
        ...newNotification.trigger,
        value: updated_src,
      }
    });
    try {
      const str = JSON.stringify(updated_src);
      const obj = JSON.parse(str);
      if (!(obj instanceof Object)) {
        console.error('Rules must be object');
        return false;
      }
      if (!Array.isArray(obj)) {
        console.error('Rule must be array of rules');
        return false;
      }
      return obj.every((rule) => {
        return true;
        // return validateRule(rule);
      });
    } catch (e) {
      console.error(e);
      return false;
    }
  };
  const handleSave = () => {
    handleSaveNotification(newNotification);
  };
  const handleCancel = () => {
    handleSaveNotification(null);
  };
  const descriptor = triggerDescriptions[newNotification.trigger.type];
  let jsonvalue = newNotification.trigger.value;
  if (descriptor) {
    if (descriptor.value === 'json' &&
      typeof jsonvalue === 'string') {
      try {
        jsonvalue = JSON.parse(newNotification.trigger.value) || getDefaultRule()
      } catch (e) {
        console.error('value is not valid json', e);
        jsonvalue = {};
      }
    }
  }
  return descriptor && newNotification ? <Grid container spacing={3}
    alignItems="center" justifyContent="space-around">
    <Grid item xs={12}>
      <FormControlLabel
        control={<Switch
          checked={!!newNotification.active}
          onChange={handleChange('active')}
          value={newNotification.active}
          inputProps={{ 'aria-label': 'secondary checkbox' }}
        />}
        label="Active"
      />
      <Grid item xs={12}>
        {descriptor.field ?
          <TextField select value={newNotification.trigger.field}
            label="N" onChange={handleChange('trigger.field')}>
            {descriptor.field.map((dataKey) => {
              return <MenuItem key={dataKey} value={dataKey}>
                {camelToRead(dataKey)}
              </MenuItem>
            })}
            <MenuItem value={''}>?</MenuItem>
          </TextField>
          : null}
      </Grid>
      <Grid item xs={12}>
        <TextField fullWidth select label="trigger"
          value={newNotification.trigger.type}
          onChange={handleChange('trigger.type')}>
          {Object.keys(triggerDescriptions).sort().map((trig) => {
            return <MenuItem key={trig} value={trig}>{
              triggerDescriptions[trig].description
            }</MenuItem>
          })}
        </TextField>
      </Grid>
      <Grid item xs={12}>
        {descriptor.value && descriptor.value !== 'json' ?
          <TextField type={descriptor.value} label="Value"
            value={newNotification.trigger.value}
            onChange={handleChange('trigger.value')}>
          </TextField> : null}
        {descriptor.value && descriptor.value === 'json' ?
          <ReactJson
            displayDataTypes={false}
            theme="solarized"
            indentWidth={2}
            sortKeys={true}
            enableClipboard={clipEnable}
            name="Rules (array)"
            onEdit={handleJSONChange('trigger.value')}
            onAdd={handleJSONChange('trigger.value')}
            onDelete={handleJSONChange('trigger.value')}
            defaultValue={{}}
            validationMessage="Check json-rules-engine npm package for more information"
            src={jsonvalue}>
          </ReactJson>
          : null}
      </Grid>
    </Grid>
    <Grid item xs={12} style={{ margin: 15 }}>
      <Divider variant="middle">
      </Divider>
    </Grid>
    <Grid item xs={8}>
      <Grid item xs={12}
        style={{ marginBottom: 15 }}
      >
        <strong>Custom Headers</strong>
        {newNotification.apiConfiguration?.headers?.map((header, index) => {
          return <Grid container>
            <Grid item xs={3}>
              <TextField fullWidth
                label="name"
                value={header.headerName}
              //onChange={handleChange('apiConfiguration.httpMethod')}
              ></TextField>
            </Grid>
            <Grid item xs={7}>
              <TextField fullWidth
                label="value"
                value={header.headerValue}
                //onChange={handleChange('apiConfiguration.httpMethod')}
                style={{ marginLeft: 5 }}
              ></TextField>
            </Grid>
            <Grid item xs={1}>
              <IconButton aria-label="delete"
                style={{ marginTop: 12 }}
              >
                <DeleteIcon
                  size="small"
                  color="secondary"
                  onClick={() => removeHeader(index)}
                />
              </IconButton>
            </Grid>
          </Grid>
        })}
        <Grid container>
          <Grid item xs={3}>
            <TextField fullWidth
              label="name"
              placeholder="Header-Name"
              value={newHeaderName}
              onChange={(e) => { setNewHeaderName(e.target.value); }}
            ></TextField>
          </Grid>
          <Grid item xs={7}>
            <TextField fullWidth
              label="value"
              placeholder="header value"
              value={newHeaderValue}
              onChange={(e) => { setNewHeaderValue(e.target.value); }}
              style={{ marginLeft: 5 }}
            ></TextField>
          </Grid>
          <Grid item xs={1}>
            {newHeaderName && newHeaderValue ?
              <IconButton aria-label="delete"
                style={{ marginTop: 12 }}>
                <AddIcon
                  size="small"
                  color="secondary"
                  onClick={() => addHeader(newHeaderName, newHeaderValue)}
                />
              </IconButton> : null}
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}
        alignItems="top"
      >
        <TextField fullWidth select
          label="HTTP Method/Verb"
          value={newNotification.apiConfiguration?.httpMethod}
          onChange={handleChange('apiConfiguration.httpMethod')}
          style={{ marginBottom: 15 }}
        >
          <MenuItem key="GET" value="HEAD">GET</MenuItem>
          <MenuItem key="POST" value="POST">POST</MenuItem>
          <MenuItem key="PATCH" value="PATCH">PATCH</MenuItem>
          <MenuItem key="PUT" value="PUT">PUT</MenuItem>
          <MenuItem key="DELETE" value="DELETE">DELETE</MenuItem>
          <MenuItem key="HEAD" value="HEAD">HEAD</MenuItem>
        </TextField>
      </Grid>
      <TextField
        fullWidth
        label="Endpoint URL"
        size="small"
        variant="outlined"
        style={{ marginBottom: 15 }}
        value={newNotification.apiConfiguration?.endpointUrl}
        onChange={handleChange('apiConfiguration.endpointUrl')}>
      </TextField>
      <TextField multiline minRows="6"
        fullWidth maxRows="32" label="Request Body (JSON)"
        size="small"
        variant="outlined"
        style={{ marginBottom: 15 }}
        value={newNotification.apiConfiguration?.requestBody}
        onChange={handleChange('apiConfiguration.requestBody')}></TextField>
    </Grid>
    <Grid item xs={4} >
      <p><strong>Custom Attributes</strong></p>
      <p>You can add the following <em>tags</em> into either the URL or the request body JSON:</p>
      <p>&raquo; <strong>[email]</strong>: The user's email address.</p>
      <p>&raquo; <strong>[timestamp]</strong>: Measurement timestamp (UTC).</p>
      <p>&raquo; <strong>[productId]</strong>: The product id (mac address) of the label used.</p>
    </Grid>

    <Grid item xs={12} style={{ margin: 15 }}>
      <Divider variant="middle">
      </Divider>
    </Grid>
    <Grid item xs={12}>
      <Button fullWidth color="primary" onClick={handleCancel}>Cancel</Button>
      <Button fullWidth color="secondary" variant="contained" onClick={handleSave}>Save</Button>
    </Grid>
  </Grid> : null;
}
