import React, { useState, useEffect } from 'react';
import _ from 'lodash';

import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';

import {Redirect} from 'react-router-dom';

import AddCard from '../Components/AddCard';
import MiddlewareCard from '../Components/MiddlewareCard';
import MiddlewareEditor from '../Components/MiddlewareEditor';
import MadeWithLove from '../Components/MadeWithLove';

import AppContainer from '../Components/AppContainer';
import Loader from '../Components/Loader';
import withStyle from '../style';

import Networker from '../Utils/Networker';


const getAllMiddleware = (org) => {
  return Networker.get({
    root: 'middleware',
    query: {owner:org}
  });
};

const getAllOrganizations = (userId) => {
  return Networker.get({
    root: 'organizations',
    query: {userId}
  });
};

const getAllSUOrgs = () => {
  return Networker.get({
    root: 'organizations',
    inner: 'all'
  });
};

const deleteMiddleware = (id) => {
  return Networker.delete({
    root: 'middleware',
    query: {
      _id: id
    }
  });
}


export default function Products({match}) {
  const [user, setUser] = useState({});
  const [middleware, setMiddleware] = useState([]);
  const [organizations, setOrganizations] = useState([]);
  const [newMiddleware, setNewMiddleware] = useState(null);
  const [middlewareNameFilter, setMiddlewareNameFilter] = useState(null);
  const [isLoggedIn, setIsLoggedIn] = useState(true);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    Networker.getUserSession()
    .then(res => {
      setIsLoggedIn(true);
      setUser(res.body);
      setLoading(false);
    })
    .catch(err => {
      setIsLoggedIn(false);
      setLoading(false);
      Networker.sendError({
        message: 'middleware error',
        namespace: 'dashboard.middleware',
        data: err
      });
    })
  }, [user._id]);

  const handleFilterChange = _.debounce((val) => {
    setMiddlewareNameFilter(val);
  }, 150);

  useEffect(() => {
    if (user && user._id) {
      setLoading(true);
      const orgGet = user.isSuperUser ? getAllSUOrgs : getAllOrganizations;
      orgGet(user._id)
      .then(async (orgreq) => {
        setOrganizations(orgreq.body);
        const requestedMiddleware = await Promise.all(orgreq.body.map(async org => {
          const res = await getAllMiddleware(org._id);
          return res.body;
        }));
        setMiddleware(requestedMiddleware.flat());
        setLoading(false);
      })
      .catch((err) => {
        console.error(err);
        setLoading(false);
        Networker.sendError({
          message: 'middleware error',
          namespace: 'dashboard.middleware',
          data: err
        });
      })
    }
  }, [user, user.organizations, newMiddleware]);

  const handleMiddlewareAdd = (orgId, defaultBody) => {
    setLoading(true);
    if (defaultBody) {
      delete defaultBody._id;
      delete defaultBody.__v;
      defaultBody.name += ' (copy)';
    }
    Networker.post({
      root: 'middleware',
      body: defaultBody || {
        name: 'New Middleware',
        owner: orgId,
        inputTransformerURL: 'http://middleware:3001/transformers/input/volumetric',
        outputTransformerURL: 'http://middleware:3001/transformers/output/volumetric',
        APIendpoint: 'http://compute:5000/volumetric',
        APImethod: 'post',
        retries: 0,
        timeout: 5000
      }
    })
    .then(async (res) => {
      const midds = await Promise.all(
        organizations.map(async ({_id}) => getAllMiddleware(_id))
      );
      setMiddleware(midds.map(m => m.body).flat());
      setLoading(false);
    })
    .catch(err => {
      console.error(err);
      setLoading(false);
      Networker.sendError({
        message: 'middleware create error',
        namespace: 'dashboard.middleware',
        data: err
      });
    });
  };

  const handleAddClick = (e) => {
    let node = e.target;
    let i = 0;
    while (node.nodeName !== 'BUTTON' && i < 4) {
      node = node.parentNode;
      i += 1;
    }
    const orgId = node.id;
    handleMiddlewareAdd(orgId);
  };

  const handleDelete = (e) => {
    let node = e.target;
    let i = 0;
    while (node.nodeName !== 'BUTTON' && i < 4) {
      node = node.parentNode;
      i += 1;
    }
    const id = node.id;
    const midd = middleware.find((elem) => elem._id === id);
    if (midd) {
      deleteMiddleware(id)
      .then(() => {
        setLoading(true);
        const orgGet = user.isSuperUser ? getAllSUOrgs : getAllOrganizations;
        orgGet(user._id)
        .then(async (orgreq) => {
          setOrganizations(orgreq.body);
          const requestedMiddleware = await Promise.all(orgreq.body.map(async org => {
            const res = await getAllMiddleware(org._id);
            return res.body;
          }));
          setMiddleware(requestedMiddleware.flat());
          setLoading(false);
        })
        .catch((err) => {
          console.error(err);
          setLoading(false);
          Networker.sendError({
            message: 'middleware delete error',
            namespace: 'dashboard.middleware',
            data: err
          });
        });
      })
      .catch(err => {
        window.alert(err);
      });
    }
  }

  const showEditor = (e) => {
    let node = e.target;
    let i = 0;
    while (node.nodeName !== 'BUTTON' && i < 4) {
      node = node.parentNode;
      i += 1;
    }
    const id = node.id;
    const midd = middleware.find((elem) => elem._id === id);
    setNewMiddleware(midd);
  };

  const closeEditor = () => {
    setNewMiddleware(null);
  };

  const classes = withStyle();
  return isLoggedIn ? (
    <div className={classes.root}>
      <CssBaseline />
      <AppContainer classes={classes} title="Middleware" match={match}>
        <div className={classes.appBarSpacer} />
        <TextField label="Filter"
          defaultValue=""
          onChange={(e) => {
            handleFilterChange(e.target.value);
          }}
          fullWidth autoFocus></TextField>
        <Container maxWidth="xl" className={classes.container}>
          <Grid container spacing={2} justifyContent="center" alignItems="center">
            {middleware.filter(({name}) => {
              return (name || '').toLowerCase().includes(middlewareNameFilter || '');
            }).map((midd) => <Grid item xs={12} md={6} lg={4}
              key={midd._id}>
              <MiddlewareCard
                middleware={midd}
                org={organizations.find(org => org._id === midd.owner)}
                onEdit={showEditor}
                onDelete={handleDelete}
                onDuplicate={() => {
                  handleMiddlewareAdd(midd.owner, midd);
                }}
                />
            </Grid>)}
            {organizations.map((org) => {
              return <Grid item xs={12} md={4}
              key={org._id}>
                <AddCard
                  id={org._id}
                  title={`New middleware for ${org.name}`}
                  onClick={handleAddClick}/>
              </Grid>
            })}
          </Grid>
        </Container>
        <MiddlewareEditor
          middleware={newMiddleware}
          open={Boolean(newMiddleware)}
          handleClose={closeEditor}/>
        <MadeWithLove />
      <Loader show={loading} />
      </AppContainer>
    </div>
  ) : <Redirect to="/login"></Redirect>;
}
