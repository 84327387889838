import React, {useState, useEffect} from 'react';
import ErrorBoundary from './ErrorBoundary';
import { MapContainer, TileLayer, CircleMarker } from 'react-leaflet';
import { HeatmapLayer } from 'react-leaflet-heatmap-layer-v3';
import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';
import withStyle from '../style';

import {std, mean} from '../Utils/DataUtils';

export default function MapExample({points}){
  const classes = withStyle();
    const [pointList, setPointList] = useState([]);
    const [average, setAverage] = useState(0);
    const [stdDev, setStdDev] = useState(0);
    const [center, setCenter] = useState([40,-79]);
    const [showTransit, setShowTransit] = useState(false);
    const [hasTransitMarked, setHasTransitMarked] = useState(false);

    useEffect(() => {
      const {all, noTransit} = points;
      if (all && all.length > 0) {
        let points = showTransit ? all : noTransit;
        if (points.length === 1) {
          setCenter(points[0]);
          // circle marker expects a latlng, rather than array
          const transformedPoints = points.map(p => { return {lat: p[0], lon: p[1]}});
          setPointList(transformedPoints);
        } else {
          const avg = mean(points, 2);
          const sdev = std(points, 2, false, avg);
          setAverage(avg);
          setStdDev(sdev);
          setPointList(points);
          if(all && noTransit && all.length > noTransit.length){
            setHasTransitMarked(true);
          }
        }
      }
    }, [showTransit, points]);

    const latitudeExtractor = (m) => {
      return m[0];
    };
    const longitudeExtractor = (m) => {
      return m[1];
    };
    const intensityExtractor = (m) => {
      return m[2];
    };

    return <Card className={classes.paper}>
        <ErrorBoundary>
          {hasTransitMarked ? <button onClick={e => {
              setShowTransit(!showTransit);
            }}>Transit Data: {showTransit ? 'Shown' : 'Hidden'}</button> : null}
          <MapContainer className={classes.map} center={center} zoom={12}>
            {pointList.length === 0 ?
              <Typography>
                No location data available
              </Typography> :
            (pointList.length > 1 ?
              <ErrorBoundary>
              <HeatmapLayer
                fitBoundsOnLoad
                fitBoundsOnUpdate
                points={pointList}
                max={average + 2 * stdDev}
                minOpacity={0.3}
                gradient={{
                  0.0: 'blue',
                  0.7: 'orange',
                  1.0: 'red'
                }}
                longitudeExtractor={longitudeExtractor}
                latitudeExtractor={latitudeExtractor}
                intensityExtractor={intensityExtractor}/></ErrorBoundary> :
              <CircleMarker
                center={pointList[0]}
                color={'#ffa688'}
                opacity={0.5}
                fillOpacity={0.7}
                radius={30}/>)
            }
            <TileLayer
              url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            />
          </MapContainer>
        </ErrorBoundary>
      </Card>
}
