/* eslint-disable */
import React, { useEffect, useMemo, useRef } from "react";
import JoditEditor from "jodit-react";
import { imageUpload } from "../Utils/ImageJodit";
import AWS from 'aws-sdk';

export default function TextEditor(props) {
    const { quillValue, setQuillValue, setLoading, setOpenMobilePreview } = props
    const joditRef = useRef(null)
    const imageError = useRef(null)

    useEffect(() => {
        if (!quillValue.tag) return
        setTimeout((tag) => joditRef.current.selection.insertHTML(tag), 0, quillValue.tag)
    }, [quillValue.toggle])

    const config = {
        readonly: false,
        addNewLineOnDBLClick: false,
        uploader: {
            insertImageAsBase64URI: true,
        },
        autofocus: true,
        imageProcessor: {
            replaceDataURIToBlobIdInView: false
        },
        events:
        {
            afterInit: (instance) => joditRef.current = instance
        },
        toolbarAdaptive: false,
        buttons: [
            "bold", "italic", "underline", "strikethrough", "ul", "ol", "font", "fontsize", "paragraph", "lineHeight", "superscript", "subscript",
            {
                name: 'image',
                exec: (async (editor) => {
                    imageUpload(editor, AWS, setLoading, setQuillValue)
                })
            },
            "cut", "copy", "paste", "table", "hr", "link", "symbols", "indent", "outdent", "left", "brush", "undo", "redo", "find", "source", "fullsize", "print",
            {
                name: 'preview',
                exec: (async (editor) => {
                    setOpenMobilePreview(true)
                })
            },
        ],
        hidePoweredByJodit: true,
        enableDragAndDropFileToEditor: true,
        resizer: {
            useAspectRatio: false, // don't save,
            min_width: 300,
            min_height: 200,
        },
        createAttributes: {
            td: {
                style: "border:1px solid black;"
            },
            th: {
                style: "border:1px solid black;"
            },
            table: {
                style: "border-collapse:collapse;width: 100%;"
            },
            img: {
                style: "min-height: 200px;max-width:2000px;width:300px;height:200px;"
            },

        },
        style: {
            background: 'white',
        },

    };

    const handleSetContext = value => {
        let target = document.querySelector('.jodit-drag-and-drop__file-box');
        if (target) {
            const inputElem = target.children[2]
            inputElem.accept = `image/png,image/jpeg`
        }
        const height = joditRef.current.get("__plugins.resizer.element.height")
        const width = joditRef.current.get("__plugins.resizer.element.width")
        const ratio = width / height
        imageError.current = ratio > 2 ? "Aspect Ratio should be less than 2" : null
        setQuillValue(prev => ({ ...prev, editorHtml: value, error: imageError.current }))
    };


    return useMemo(
        () => (
            <JoditEditor
                ref={joditRef}
                value={quillValue?.editorHtml}
                config={config}
                tabIndex={1}
                onBlur={handleSetContext}
                onChange={handleSetContext}
            />
        ),
        []
    );
}
