import React, { useState, useEffect } from 'react';
import { Grid } from '@material-ui/core';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { withStyles } from '@material-ui/core/styles';
import { Alert } from '@material-ui/lab';
import Button from '@material-ui/core/Button';

const RedRadio = withStyles({
  root: {
    color: 'black', // Unchecked color
    outline: 'black',
    '&$checked': {
      color: 'red', // Checked color
    },
    '& .MuiIconButton-root': {
      borderColor: 'black', // Outline color
    },
  },
  checked: {},
})((props) => <Radio color="default" {...props} />);

export default function OverTime(props) {
  const groupByValue = props.group;
  const [timelineValue, setTimelineValue] = useState('');
  const [filteredData, setFilteredData] = useState([]);
  const [showAlert, setShowAlert] = useState(false);

  useEffect(() => {
    if (timelineValue === 'Day') {
      setShowAlert(true);
      // Close the alert after 5 seconds
      const timeoutId = setTimeout(() => {
        setShowAlert(false);
      }, 5000);

      // Clear the timeout if the component unmounts or if the timeline changes
      return () => clearTimeout(timeoutId);
    } else {
      setShowAlert(false);
    }
  }, [timelineValue, groupByValue]);

  // Function to aggregate data on a monthly basis
  const aggregateMonthlyData = (data) => {
    // Group data by month and summary
    const monthlyData = data.reduce((result, entry) => {
      const dateParts = entry.day.split('/');
      if (dateParts.length !== 3) {
        console.error("Invalid date format:", entry.day);
        return result;
      }
      const [day, month, year] = dateParts.map(Number);
      if (isNaN(day) || isNaN(month) || isNaN(year)) {
        console.error("Invalid date components:", entry.day);
        return result;
      }
      const date = new Date(year, month, day);
      if (isNaN(date.getTime())) {
        console.error("Invalid date:", entry.day);
        return result;
      }
      const yearMonth = `${year}-${(month).toString().padStart(2, '0')}`; // Get year-month format
      let key;
      // Creating a different key for grouping by age
      if (groupByValue === 'age') {
        const ageBracket = categorizeAge(entry.summary, 9);
        key = `${yearMonth}_${ageBracket}`;
      } else {
        key = `${yearMonth}_${entry.summary}`;
      }

      if (!result[key]) {
        result[key] = 0; // Initialize count for each month and summary combination
      }
      result[key] += entry.count; // Add count to the corresponding month and summary
      return result;
    }, {});

    // Convert the grouped data into an array of objects
    return Object.entries(monthlyData).map(([key, count]) => {
      const [yearMonth, summary] = key.split('_');
      const [year, month] = yearMonth.split('-');
      return { id: key, date: `${year}/${month}`, summary, count };
    });
  };

  // Function to aggregate data on a week basis
  const aggregateWeeklyData = (data) => {
    // Group data by week and summary
    const weeklyData = data.reduce((result, entry) => {
      const dateParts = entry.day.split('/'); // Split the date string by '/'
      const date = new Date(`${dateParts[2]}-${dateParts[1]}-${dateParts[0]}`);
      if (isNaN(date.getTime())) {
        console.error("Invalid date:", entry.day);
      } else {
        const weekNumber = getWeekNumber(date);
        const year = date.getFullYear();
        let key;
        // Creating a different key for grouping by age
        if (groupByValue === 'age') {
          const ageBracket = categorizeAge(entry.summary, 9);
          key = `${year}-Week-${weekNumber}_${ageBracket}`;
        } else {
          key = `${year}-Week-${weekNumber}_${entry.summary}`;
        }

        if (!result[key]) {
          result[key] = 0; // Initialize count for each week and summary combination
        }
        result[key] += entry.count; // Add count to the corresponding week and summary
      }
      return result;
    }, {});

    // Convert the grouped data into an array of objects
    return Object.entries(weeklyData).map(([key, count]) => {
      // Example format of weeklyData key: '2023-Week-46_50-59'
      const [yearWeek, summary] = key.split('_');
      const [year, week, weekNo] = yearWeek.split('-');
      return { id: key, date: `${year}-${week}-${weekNo}`, summary, count };
    });
  };

  // Function to aggregate data on daily basis
  const aggregateDailyData = (data) => {
    // Group data by day and summary
    const dailyData = data.reduce((result, entry) => {
      const dateParts = entry.day.split('/'); // Split the date string by '/'
      const date = new Date(`${dateParts[2]}-${dateParts[1]}-${dateParts[0]}`);
      if (isNaN(date.getTime())) {
        console.error("Invalid date:", entry.day);
      } else {
        const dayString = date.toISOString().slice(0, 10); // Get YYYY-MM-DD format
        // Creating a different key for grouping by age
        let key;
        if (groupByValue === 'age') {
          const ageBracket = categorizeAge(entry.summary, 9);
          key = `${dayString}_${ageBracket}`
        } else {
          key = `${dayString}_${entry.summary}`;
        }

        if (!result[key]) {
          result[key] = 0; // Initialize count for each day and summary combination
        }
        result[key] += entry.count; // Add count to the corresponding day and summary
      }
      return result;
    }, {});

    // Convert the grouped data into an array of objects
    return Object.entries(dailyData).map(([key, count]) => {
      // Example format of dailyData key: '2023-11-15_50-59'
      const [date, summary] = key.split('_');
      return { id: key, date, summary, count };
    });
  };

  // Function to get the week number of a date
  const getWeekNumber = (date) => {
    const firstDayOfYear = new Date(date.getFullYear(), 0, 1);
    const pastDaysOfYear = (date - firstDayOfYear) / 86400000;
    return Math.ceil((pastDaysOfYear + firstDayOfYear.getDay() + 1) / 7);
  };

  // Function to categorize age into groups
  const categorizeAge = (age, numGroups) => {
    const groupSize = Math.ceil(90 / numGroups); // Calculate the size of each age group
    let group = 'Unknown'; // Default group

    // Returns no response since this does not need to grouped
    if(age === '0') {
      return 'No response';
    }

    // Determine the group based on the age
    for (let i = 1; i <= numGroups; i++) {
      if (age >= (i - 1) * groupSize && age <= i * groupSize - 1) {
        group = `${(i - 1) * groupSize}-${i * groupSize - 1}`;
        break;
      }
    }
    return group;
  };

  useEffect(() => {
    // This effect runs only once when the component mounts
    setTimelineValue('Month')
    if (props.data && timelineValue) {
      let aggregatedData;
      switch (timelineValue) {
        case 'Day':
          aggregatedData = aggregateDailyData(props.data);
          break;
        case 'Week':
          aggregatedData = aggregateWeeklyData(props.data);
          break;
        case 'Month':
          aggregatedData = aggregateMonthlyData(props.data);
          break;
        default:
          aggregatedData = [];
      }
      setFilteredData(aggregatedData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // TODO: This can be written as one useEffect
  useEffect(() => {
    let aggregatedData;
    switch (timelineValue) {
      case 'Day':
        aggregatedData = aggregateDailyData(props.data);
        break;
      case 'Week':
        aggregatedData = aggregateWeeklyData(props.data);
        break;
      case 'Month':
        aggregatedData = aggregateMonthlyData(props.data);
        break;
      default:
        aggregatedData = [];
    }
    setFilteredData(aggregatedData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.data, timelineValue]); // Add props.data and timelineValue as dependencies

  const handleGroupByChange = (event) => {
    const selectedGroup = event.target.value;
    props.onGroupChange(selectedGroup);
  };

  // Handle timeline change
  const handleTimelineChange = (event) => {
    const selectedTimeline = event.target.value;
    setTimelineValue(selectedTimeline);
  };

  // Assign Initialize values for each groupByValue
  function initializeSeriesMap(groupByValue) {
    if (groupByValue === "gender") {
      return [{ date: '0', M: 0, F: 0, "No response": 0 }];
    } else if (groupByValue === "age") {
      return [{ date: '0', '20-29': 0, '30-39': 0, '40-49': 0, '50-59': 0, '60-69': 0, "No response": 0 }];
    } else if (groupByValue === "ethnicity") {
      return [{ date: '0', 'African American': 0, 'Asian': 0, 'Caucasian': 0, 'Hispanic or Latino': 0, 'No response': 0, 'European': 0 }];
    } else if (groupByValue === "none") {
      return [{ date: '0', 'count': 0 }];
    } else {
      // Handle other cases or default
      return [{ date: '0' }];
    }
  }

  // Creates the data object for format accepeted by the graph component
  const transformDataToSeries = (data) => {
    const seriesMap = initializeSeriesMap(groupByValue);
    data.forEach((entry, index) => {
      const { summary, date, count } = entry;
      if (seriesMap.length === 0) {
        seriesMap.push({ date: date, [summary]: count });
      }
      // Find index if date exists
      const found = seriesMap.findIndex((o) => o.date === date)

      // If found append the object otherwise push a new object
      if (found >= 0) {
        seriesMap[found] = { ...seriesMap[found], [summary]: count };
      } else {
        seriesMap.push({ date: date, [summary]: count });
      }
    })
    return seriesMap;
  };

  // Checks all keys exist in data object 
  const validateObject = (data) => {
    const dataAggregated = transformDataToSeries(data);
    // Fields
    const defaultEntry = Object.keys(dataAggregated[0]);
    defaultEntry.forEach((key) => {
      dataAggregated.forEach((entry) => {
        if(!(key in entry)) {
          entry[key] = 0;
        }
      })
    })
    return dataAggregated;
  }

  const sortedSeries = validateObject(filteredData);
  const getColor = (index) => {
    const colors = ['#8884d8', '#82ca9d', '#ffc658', '#ff7300', '#ff85a2', '#ff6347']; // Define an array of colors
    return colors[index % colors.length]; // Return a color from the array based on the index
  };

  return (<Card>
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6}>
        {/* Left side content */}
        <Typography variant="h5" style={{ margin: '20px' }}>
          {props.title || 'Usage Over Time'}
        </Typography>
      </Grid>
      <Grid item xs={12} sm={6} container justifyContent="flex-end">
        {/* Right side content */}
        <Grid container alignItems="center">
          <Typography variant="h6" style={{ marginRight: '1rem', fontSize: '15px' }}>
            Timeline:
          </Typography>
          <RadioGroup value={timelineValue} onChange={handleTimelineChange} row>
            <FormControlLabel
              value="Day"
              control={<RedRadio />}
              label="Day"
            />
            <FormControlLabel
              value="Week"
              control={<RedRadio />}
              label="Week"
            />
            <FormControlLabel
              value="Month"
              control={<RedRadio />}
              label="Month"
            />
          </RadioGroup>
        </Grid>
        <Grid container alignItems="center">
          <Typography variant="h6" style={{ marginRight: '1rem', fontSize: '15px' }}>
            Group By:
          </Typography>
          <RadioGroup value={groupByValue} onChange={handleGroupByChange} row>
            <FormControlLabel
              value="none"
              control={<RedRadio />}
              label="None"
            />
            <FormControlLabel
              value="age"
              control={<RedRadio />}
              label="Age"
            />
            <FormControlLabel
              value="gender"
              control={<RedRadio />}
              label="Gender"
            />
            <FormControlLabel
              value="ethnicity"
              control={<RedRadio />}
              label="Ethnicity"
            />
          </RadioGroup>
        </Grid>
      </Grid>
      {props.alert && (
        <Grid item container >
          <Typography variant="h6" style={{ width: '100%', margin: 'auto 20px', textAlign: 'center' }} justifyContent='center'>
            {props.alert}
          </Typography>
        </Grid>
      )}
      <Grid item container style={{ marginTop: '5px' }} justifyContent='center'>
        {showAlert && !props.alert && (
          <Alert severity="warning" style={{ marginTop: '10px' }}
            action={
              <Button color="inherit" size="small" onClick={() => setShowAlert(false)}>
                Close
              </Button>
            }>
            If you'd like a better representation, slide the data range bar above to reduce the timeline of data being analyzed.
          </Alert>
        )}
      </Grid>
    </Grid>
    <ResponsiveContainer width="100%" height={220}>
      <LineChart
        width={500}
        height={300}
        data={sortedSeries}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: groupByValue === "none" ? 25 : 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="date" />
        <YAxis />
        <Tooltip />
        {/* Conditionally render the Legend only if groupByValue is not 'none' */}
        {groupByValue !== "none" && <Legend />}
        {groupByValue === "gender" && !props.alert && (
          <>
            <Line type="monotone" dataKey="F" stroke={getColor(0)} connectNulls activeDot={{ r: 8 }} />
            <Line type="monotone" dataKey="M" connectNulls stroke={getColor(1)} />
            <Line type="monotone" dataKey="No response" connectNulls stroke={getColor(2)} />
          </>
        )}

        {groupByValue === "ethnicity" && !props.alert && (
          <>
            <Line type="monotone" dataKey="African American" connectNulls stroke={getColor(1)} />
            <Line type="monotone" dataKey="Asian" connectNulls stroke={getColor(2)} />
            <Line type="monotone" dataKey="Caucasian" connectNulls stroke={getColor(3)} />
            <Line type="monotone" dataKey="Hispanic or Latino" connectNulls stroke={getColor(4)} />
            <Line type="monotone" dataKey="European" connectNulls stroke={getColor(6)} />
            <Line type="monotone" dataKey="No response" connectNulls stroke={getColor(5)} />
          </>
        )}

        {groupByValue === "age" && !props.alert && (
          <>
            <Line type="monotone" dataKey="20-29" connectNulls stroke={getColor(2)} />
            <Line type="monotone" dataKey="30-39" connectNulls stroke={getColor(1)} />
            <Line type="monotone" dataKey="40-49" connectNulls stroke={getColor(3)} />
            <Line type="monotone" dataKey="50-59" connectNulls stroke={getColor(4)} />
            <Line type="monotone" dataKey="60-69" connectNulls stroke={getColor(5)} />
            <Line type="monotone" dataKey="No response" connectNulls stroke={getColor(6)} />
          </>
        )}
        {groupByValue === "none" && !props.alert && (
          <>
            <Line type="monotone" dataKey="count" connectNulls stroke={getColor(1)} />
          </>
        )}
      </LineChart>

    </ResponsiveContainer>
    <Grid container alignItems="center" justifyContent="center" style={{ marginRight: '1rem', fontSize: '15px' }}>
      &nbsp;
    </Grid>
  </Card>
  );
}
