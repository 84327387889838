import { Dialog, DialogContent, DialogContentText } from '@material-ui/core'
import React from 'react'
import '../../css/mobilePreview.css';

const MobilePreview = ({ actionButton, quillValue, openMobilePreview, setOpenMobilePreview }) => {

    return (
        <Dialog
            open={openMobilePreview}
            onClose={() => setOpenMobilePreview(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description">
            <DialogContent className='p-0 overflow-hidden'>
                <DialogContentText id="alert-dialog-description" style={{ backgroundColor: "rgba(0, 0, 0, 0.5)" }}>
                    <div class="smartphone">
                        <div class="content" >
                            <div className='push-bar'   >
                                <div dangerouslySetInnerHTML={{
                                    __html: quillValue.editorHtml
                                }}></div>
                            </div>
                            {actionButton?.map(button => <div key={button} className='push-bar action-button'>{button}</div>)}
                        </div>
                    </div>
                </DialogContentText>
            </DialogContent>
        </Dialog>
    )
}

export default MobilePreview