import React from 'react';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import {Link as RouterLink} from 'react-router-dom';

export default function MadeWithLove() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      Built with love by the&nbsp;
      <Link color="inherit" href="https://adrich.io/">
        Adrich
      </Link>
       &nbsp;Dev Team
       <br/>
      <Link component={RouterLink} to="/terms"
        target="_blank" rel="noopener">Terms</Link>&nbsp;
      <Link component={RouterLink} to="/privacy"
        target="_blank" rel="noopener">Privacy Policy</Link>.
    </Typography>
  );
}
