import React from 'react';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import IconButton from '@material-ui/core/IconButton';
import CreateIcon from '@material-ui/icons/AddBox'
import Grid from '@material-ui/core/Grid'
import Avatar from '@material-ui/core/Avatar'
import Typography from '@material-ui/core/Typography'

import withStyle from '../style';

export default function AddCard({title, logo, onClick, id, disabled}) {
  const classes = withStyle();

  return (
      <Card className={classes.addCard}>
      <CardContent>
        <Grid container spacing={3} alignItems="center" justifyContent="center">
          <Grid item>
            <Typography className={classes.title} color="textSecondary" gutterBottom>
              {title}
            </Typography>
          </Grid>
          {logo ? <Grid item>
              <Avatar alt={title} src={logo}  className={classes.avatar}>
                title.substr(0, 1)
              </Avatar>
          </Grid> : null}
          <Grid item>
            {disabled ? null : <IconButton className={classes.iconFill} onClick={onClick} id={id || ''}>
              <CreateIcon fontSize="large"/>
            </IconButton>}
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}
