import React from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import Networker from "../Utils/Networker";


const TICK_IMG =
  "https://upload.wikimedia.org/wikipedia/commons/thumb/8/8b/Eo_circle_green_white_checkmark.svg/2048px-Eo_circle_green_white_checkmark.svg.png";

const AppEcom = (props) => {
  const { match = {} } = props;
  const { params = {} } = match;
  const { userId, productId, providerId } = params;
  const [loginMail, setLoginMail] = React.useState("");
  const [registerMail, setRegisterMail] = React.useState("");
  const [registerFname, setRegisterFname] = React.useState("");
  const [registerLname, setRegisterLname] = React.useState("");
  const [registerPassword, setRegisterPassword] = React.useState("");
  const [userNotExists, setUserNotExists] = React.useState(false);
  // const [replenishmentProviderData, setReplenishmentProviderData] =
  //   React.useState({});
  const [validated, setUserValidated] = React.useState(false);
  const [status, setStatus] = React.useState("zxdfghjk");
  const [loginLoading, setLoginLoading] = React.useState(false);
  const [loginMailError, setLoginMailError] = React.useState(false);
  const [registerLoading, setRegisterLoading] = React.useState(false);
  const [password, setPassword] = React.useState("password");
  const [loginError, setLoginError] = React.useState("");
  const [registerError, setRegisterError] = React.useState("");

  // const fetchEcomParameter = async () => {
  //   const response = await Networker.getMethod("user/replenishment_providers");
  //   console.log("vhghjj", response);

  //   if (response.body?.length) setReplenishmentProviderData(response.body[0]);
  //   // console.log(response.body[0]);
  // };
  //   console.log(replenishmentProviderData.banner);
  React.useEffect(() => {
    // fetchEcomParameter();
  }, []);

  const handleValidateUser = async () => {
    try {
      setLoginLoading(true);
      const validate = await Networker.postMethod("user/validate_user", { email: loginMail });
      if (validate.status === 200) await handleProvideAuth(loginMail);
      handlePerformance("success");
      setLoginMailError(false);
      setUserNotExists(false);
      setUserValidated(true);
      setStatus("validated");
    } catch (err) {
      console.log(err.response, "is the err");
      if (err.response.body?.errorMessage) {
        handlePerformance("fail");
        setLoginError(err.response.body.errorMessage);
        setUserNotExists(false);
      } else {
        setUserNotExists(true);
        setLoginMailError(true);
        setLoginError("");
      }
      setRegisterError("");
    } finally {
      setLoginLoading(false);
    }
  };

  const handlePerformance = (type) => {
    window.open(`/app/ecom/shopify?status=${type}`, "_self");
    // try {
    //   await Networker.getMethod(`app/ecom/shopify?status=${type}`);
    // } catch (err) {
    //   console.error(err, "is the error");
    // }
  };

  const handleProvideAuth = (email) => {
    return Networker.postMethod("user/provider_auth", {
      userId: userId,
      productId: productId,
      replenishmentProviderId: providerId,
      registeredEmail: email
    });
  };
  const handleCreateUser = async () => {
    try {
      setRegisterLoading(true);
      const signup = await Networker.postMethod("user/user_signup", {
        email: registerMail,
        password: registerPassword,
        firstName: registerFname,
        lastName: registerLname,
      });
      if (signup.status === 201) await handleProvideAuth(registerMail);
      handlePerformance("success");
      setUserNotExists(false);
      setStatus("created");
      setUserValidated(true);
    } catch (err) {
      console.log(err.response, "is the err");
      if (err.response.body?.errorMessage) {
        handlePerformance("fail");
        setRegisterError(err.response.body.errorMessage);
      } else {
        setRegisterError("");
      }
      setLoginError("");
    } finally {
      setRegisterLoading(false);
    }
  };
  const togglePassword = () => {
    if (password === "password") {
      setPassword("text");
      return;
    }
    setPassword("password");
  };
  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  return (
    <>
      {validated ? (
        <>
          <div className="ParentValidatiedCase">
            <div className="ValidatiedCase">
              <img
                src={TICK_IMG}
                style={{
                  width: "40%",
                  marginTop: "30px",
                  marginBottom: "20px",
                }}
                alt="Successful"
              />
              <div style={{ fontSize: "25px", textAlign: "center" }}>
                Your account has been successfully {status} ! You may close this
                page now.
              </div>
            </div>
          </div>
        </>
      ) : (
        <div className="ParentDivOfModifiedEcom">
          <div className="modifiedEcom">
            <div className="card bg-light mb-3">
              <div className="card-header">
                <h3>Connect Your Account</h3>
              </div>
              <div className="card-body">
                <p className="card-text">
                  Your shopify auto replenthisment provider. Never run out of
                  your products again. Enable your container to make automatic
                  reorders.
                </p>
              </div>
            </div>
            {/*  */}
            {!userNotExists && (
              <div
                className="Popup"
                style={{ marginBottom: "10px !important" }}
              >
                Please enter your Shopify store email address
              </div>
            )}
            {userNotExists && (
              <div className="ErrorPopup">
                Ooops, it looks we hit snag... we can not find your email
                address.
                <br />
                <br />
                Please try again by entering a different email address or create
                a new account below:
              </div>
            )}
            {/*  */}
            <div className="User">
              <div className={`UserEmail ${loginMailError ? 'error__box' : '' }`}>
                <div className="ImageDiv">
                  <img
                    src="/image/Message.jpg"
                    style={{
                      marginLeft: "auto",
                      marginRight: "auto",
                      height: "12px",
                      width: "14px",
                    }}
                    alt="Error"
                  />
                </div>

                <div className="InputDiv">
                  <input
                      type="email"
                      autoCapitalize="off"
                      autoCorrect="off"
                    placeholder="abc@google.com"
                    className="EmailInput"
                    value={loginMail}
                    onChange={(e) => {
                      setLoginMail(e.target.value);
                      setLoginMailError(false);
                    }}
                  />
                </div>
              </div>
            </div>
            {loginError && (
              <div
                className="ErrorPopup"
                style={{ color: "red", margin: "0", marginBottom: "10px" }}
              >
                {loginError}
              </div>
            )}
            {loginLoading ? (
              <CircularProgress style={{ width: '30px', height: '30px' }} />
            ) : (
              <div style={{ alignSelf: "stretch" }}>
                <button
                  className="ContinueButton"
                  disabled={!validateEmail(loginMail)}
                  onClick={handleValidateUser}
                >
                  Continue
                </button>
              </div>
            )}
            <div className="BelowFrame">
              <p>New to Shopify?</p>
              <div className="User" style={{ margin: "0" }}>
                <div className="UserEmail">
                  <div className="ImageDiv">
                    <img
                      src="/image/firstname.png"
                      style={{
                        marginLeft: "auto",
                        marginRight: "auto",
                        height: "14px",
                        width: "12px",
                      }}
                      alt="Error"
                    />
                  </div>
                  <div className="InputDiv">
                    <input
                      value={registerFname}
                      type="text"
                      onChange={(e) => setRegisterFname(e.target.value)}
                      placeholder="First Name"
                      className="EmailInput"
                    />
                  </div>
                </div>
              </div>
              <div className="User" style={{ margin: "0" }}>
                <div></div>
                <div className="UserEmail">
                  <div className="ImageDiv">
                    <img
                      src="/image/firstname.png"
                      style={{
                        marginLeft: "auto",
                        marginRight: "auto",
                        height: "14px",
                        width: "12px",
                      }}
                      alt="Error"
                    />
                  </div>
                  <div className="InputDiv">
                    <input
                      value={registerLname}
                      onChange={(e) => setRegisterLname(e.target.value)}
                      type="text"
                      placeholder="Last Name"
                      className="EmailInput"
                    />
                  </div>
                </div>
              </div>

              <div className="User" style={{ margin: "0" }}>
                <div></div>
                <div className="UserEmail">
                  <div className="ImageDiv">
                    <img
                      src="/image/Message.jpg"
                      style={{
                        marginLeft: "auto",
                        marginRight: "auto",
                        height: "12px",
                        width: "14px",
                      }}
                      alt="Error"
                    />
                  </div>
                  <div className="InputDiv">
                    <input
                      value={registerMail}
                      onChange={(e) => setRegisterMail(e.target.value)}
                        type="email"
                        autoCapitalize="off"
                        autoCorrect="off"
                      placeholder="abc@google.com"
                      className="EmailInput"
                    />
                  </div>
                </div>
              </div>
              <div className="User">
                <div className="UserEmail" style={{ display: "flex" }}>
                  <div style={{ display: "flex", width: "100%" }}>
                    <div className="ImageDiv">
                      <img
                        src="/image/Lock.png"
                        style={{
                          marginLeft: "auto",
                          marginRight: "auto",
                          height: "15px",
                          width: "13px",
                        }}
                        alt="Error"
                      />
                    </div>
                    <div className="InputDiv">
                      <input
                        value={registerPassword}
                        onChange={(e) => setRegisterPassword(e.target.value)}
                        type={password}
                        placeholder="Password"
                        className="EmailInput"
                      />
                    </div>
                  </div>
                  <div>
                    <img
                      className="HideImage"
                      src="/image/Hide.png"
                      onClick={togglePassword}
                      alt="Error"
                    />
                  </div>
                </div>
              </div>
              {registerError && (
                <div
                  className="ErrorPopup"
                  style={{ color: "red", margin: "0", marginBottom: "10px" }}
                >
                  {registerError}
                </div>
              )}
              <div style={{ alignSelf: "stretch" }}>
                {registerLoading ? (
                  <CircularProgress style={{ width: '30px', height: '30px' }} />
                ) : (
                  <button
                    disabled={
                      !validateEmail(registerMail) ||
                      !registerFname ||
                      !registerLname ||
                      !registerPassword
                    }
                    className="CreateButton"
                    onClick={handleCreateUser}
                  >
                    Create Your Shopify Account
                  </button>
                )}
              </div>
            </div>
            {/* 
          <div style={{ width: "100%", marginTop: "10px" }}>
            {!userNotExists && (
              <div style={{ fontSize: "14px" }}>
                Please enter your Shopify store email address
              </div>
            )}
            {userNotExists && (
              <div style={{ fontSize: "14px" }}>
                Ooops, it looks we hit snag... we can not find your email
                address.
              </div>
            )}
            {userNotExists && (
              <div style={{ fontSize: "14px", marginTop: "10px" }}>
                Please try again by entering a different email address:
              </div>
            )}
            <input
              value={loginMail}
              type="text"
              onChange={(e) => setLoginMail(e.target.value)}
              style={{
                width: "100%",
                height: "30px",
                borderRadius: "5px",
                boxSizing: "border-box",
                outline: "none",
                marginTop: "10px",
              }}
              placeholder="Email Address"
            />
            {loginLoading ? (
              <CircularProgress />
            ) : (
              <button
                style={{
                  background: "#7ab55c",
                  padding: "5px 10px",
                  borderRadius: "10px",
                  color: "#fff",
                  border: "none",
                  outline: "none",
                  marginTop: "10px",
                }}
                disabled={!loginMail}
                onClick={handleValidateUser}
              >
                Continue
              </button>
            )}
          </div> */}
          </div>

          {/* {userNotExists && (
          <div style={{ width: "100%", marginTop: "30px" }}>
            <div style={{ width: "100%", textAlign: "center" }}>
              New to Oliverr ?
            </div>
            <input
              value={registerFname}
              type="text"
              onChange={(e) => setRegisterFname(e.target.value)}
              style={{
                width: "100%",
                height: "30px",
                borderRadius: "5px",
                boxSizing: "border-box",
                outline: "none",
                marginTop: "10px",
              }}
              placeholder="First Name"
            />
            <input
              value={registerLname}
              type="text"
              onChange={(e) => setRegisterLname(e.target.value)}
              style={{
                width: "100%",
                height: "30px",
                borderRadius: "5px",
                boxSizing: "border-box",
                outline: "none",
                marginTop: "10px",
              }}
              placeholder="Last Name"
            />
            <input
              value={registerMail}
              type="text"
              onChange={(e) => setRegisterMail(e.target.value)}
              style={{
                width: "100%",
                height: "30px",
                borderRadius: "5px",
                boxSizing: "border-box",
                outline: "none",
                marginTop: "10px",
              }}
              placeholder="Email Address"
            />
            <input
              value={registerPassword}
              type="password"
              onChange={(e) => setRegisterPassword(e.target.value)}
              style={{
                width: "100%",
                height: "30px",
                borderRadius: "5px",
                boxSizing: "border-box",
                outline: "none",
                marginTop: "10px",
              }}
              placeholder="Password"
            />
            {registerLoading ? (
              <CircularProgress />
            ) : (
              <button
                disabled={
                  !registerMail ||
                  !registerFname ||
                  !registerLname ||
                  !registerPassword
                }
                style={{
                  background: "#7ab55c",
                  padding: "5px 10px",
                  borderRadius: "10px",
                  color: "#fff",
                  border: "none",
                  outline: "none",
                  marginTop: "10px",
                }}
                onClick={handleCreateUser}
              >
                Create your shopify account
              </button>
            )}
          </div>
        )} */}
        </div>
      )}
      <style>
        {`
        @font-face {
          font-family: "Space Grotesk";
          src: url("/fonts/SpaceGrotesk-Regular.ttf") format("truetype");
          font-weight: 400;
        }
      @font-face {
          font-family: "Space Grotesk";
          src: url("/fonts/SpaceGrotesk-Medium.ttf") format("truetype");
          font-weight: 500;
        }
      @font-face {
          font-family: "Space Grotesk";
          src: url("/fonts/SpaceGrotesk-Bold.ttf") format("truetype");
          font-weight: 700;
        }
      
      body {
          margin: 0 !important;
      }

      .error__box {
        border: 1px solid red;
        color: red;
      }
      
      .ParentDivOfModifiedEcom {
          width: 100vw;
          height: 100vh;
          padding: 24px 20px;
          box-sizing: border-box;
          font-family: "Space Grotesk", sans-serif;
      }
      
      .modifiedEcom {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: center;
          max-width: 450px;
          margin-left: auto;
          margin-right: auto;
      }
      
      .card {
          border-radius: 10px;
          align-items: center;
          background-color: #f2f4f7;
          padding: 14px 18px;
      }
      
      .card-header {
          border-bottom: solid 1px #e1e1e1;
          padding-bottom: 10px;
          text-align: center;
      }
      
      .card-header h3 {
          font-style: normal;
          font-weight: 700;
          margin: 0;
          font-size: 18px;
      }
      
      .card-body .card-text {
          font-style: normal;
          font-weight: 400;
          margin-top: 10px;
          margin-bottom: 0;
          font-size: 14px;
          line-height: 20px;
      }
      
      .ErrorPopup {
          font-family: "Space Grotesk";
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          letter-spacing: -0.02em;
          color: #101828;
          margin: 20px 0;
      }
      
      .Popup {
          font-family: "Space Grotesk";
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          letter-spacing: -0.02em;
          color: #101828;
          margin: 20px 0;
      }
      
      .User {
          display: flex;
          flex-direction: column;
          width: 100%;
      }

      .User input {
        color: inherit;
      }
      
      .UserEmail {
          background-color: #f2f4f7;
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 16px 10px 16px 16px;
          margin-bottom: 10px;
          align-self: stretch;
          border-radius: 10px;
      }
      
      .HideImage {
          padding: 0 5px;
          height: 15px;
          width: 18px;
      }
      
      .ImageDiv {
          border-right: solid 1px #e1e1e1;
          padding-right: 5px;
          display: block;
      }
      
      .InputDiv {
          width: 100%;
      }
      
      .EmailInput {
          border: none;
          background-color: #f2f4f7;
          padding: 0;
          padding-left: 5px;
          font-family: "Space Grotesk", sans-serif;
          width: 100%;
          font-weight: 500;
      }
      
      input:focus {
          outline: none;
      }
      
      .ContinueButton {
          font-family: "Space Grotesk", sans-serif;
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 19px;
          padding: 12px 24px;
          background-color: white;
          width: 100%;
          color: #6b403c;
          border: 1px solid #6b403c;
          border-radius: 10px;
      }
      
      .ContinueButton:hover,
      .CreateButton:hover,
      .HideImage:hover {
          cursor: pointer;
      }
      
      .CreateButton:disabled,
      .ContinueButton:disabled {
          opacity: 0.5;
      }
      
      .CreateButton {
          font-family: "Space Grotesk", sans-serif;
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 19px;
          padding: 13px 24px;
          background-color: #48742c;
          width: 100%;
          color: #ffffff;
          border-radius: 8px;
          border: none;
      }
      
      .BelowFrame {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: flex-start;
          padding: 0px;
          width: 100%;
          margin-top: 20px;
      }
      
      .BelowFrame p {
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;
          margin: 0;
          margin-bottom: 6px;
      }
      
      .ValidatiedCase {
          max-width: 450px;
          margin-left: auto;
          margin-right: auto;
      }
      
      
      .ParentValidatiedCase {
          width: 100vw;
          height: 100vh;
          padding: 10px;
          text-align: center;
          box-sizing: border-box;
          font-family: Space Grotesk;
      }
        
        `}
      </style>
    </>
  );
};

export default AppEcom;
