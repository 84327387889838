import React, { useState, useEffect } from 'react';
import clsx from 'clsx';

import { Redirect } from 'react-router-dom';

import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import MenuIcon from '@material-ui/icons/Menu';

import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Avatar from '@material-ui/core/Avatar';
import Grid from '@material-ui/core/Grid';

import Typography from '@material-ui/core/Typography';

import { DynamicListItems, MainListItems, SecondaryListItems } from '../Components/SidebarItems';

import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';

import Networker from '../Utils/Networker';

import LogoData from '../Utils/LogoData';


const setRemember = (should) => {
  try {
    window.localStorage.setItem('remember', should ? "true" : "false");
  } catch (e) {
    return false;
  }
};


export default function AppContainter ({classes, title, children, match}) {
  const [open, setOpen] = useState(true);
  const [user, setUser] = useState({});
  const [redirect, setShouldRedirect] = useState(false);

  useEffect(() => {
    Networker.getUserSession()
    .then(res => {
      if (res.body) {
        setUser(res.body);
      } else {
        setRemember(false);
        setShouldRedirect(true);
      }
    })
    .catch(err => {
      console.error(err);
      setShouldRedirect(true);
    });
  }, [])

  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };
  const handleLogOut = () => {
    setRemember(false);
    Networker.logOut().then(res=> {
      setShouldRedirect(true);
    })
    .catch(e => {
      setRemember(false);
      setShouldRedirect(true);
    });
  };
  return redirect ? <Redirect to="/login"/> : <React.Fragment>
  <AppBar position="fixed"
    className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}>
        <Toolbar>
        <Grid container alignItems="center" justifyContent="space-around" spacing={0}>
          <Grid item>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              className={clsx(classes.menuButton, open && classes.hide)}
              >
              <MenuIcon />
            </IconButton>
          </Grid>
          <Grid item xs={4} md={2}>
            <Typography variant="h6" color="inherit" noWrap>
              {title || 'Adrich'}
            </Typography>
          </Grid>
            <Grid item xs={2} md={2}>
              <Typography component="span" variant="body2" noWrap>
                [{user ? user.email : '...'}]
              </Typography>
            </Grid>
            <Grid item xs={3} md={2}>
              <Button onClick={handleLogOut} variant="contained">
                <Typography component="span"
                  variant="button" color="inherit" noWrap>
                  Log Out
                </Typography>
              </Button>
            </Grid>
          </Grid>
        </Toolbar>
  </AppBar>
  <Drawer
    variant="persistent"
    anchor="left"
    className={classes.drawer}
    classes={{
      paper: classes.drawerPaper,
    }}
    open={open}
  >
    <div className={classes.drawerHeader}>
      <IconButton onClick={handleDrawerClose}>
        <Avatar src={LogoData.adrich}/>
      </IconButton>
      <IconButton
        color="default"
        aria-label="close drawer"
        onClick={handleDrawerClose}
        edge="start"
        >
        <MenuIcon />
      </IconButton>
    </div>
    <Divider />
      <DynamicListItems match={match} open={open}/>
    <Divider />
    <List>
      <MainListItems match={match}/>
    </List>
    <Divider />
    <List>
      <SecondaryListItems match={match}/>
    </List>
  </Drawer>
  <main className={clsx(classes.content, {
          [classes.contentShift]: open,
        })}>
  {children}
  </main>
  </React.Fragment>
}
