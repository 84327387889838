import React, {useEffect} from 'react';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Link from '@material-ui/core/Link';
import Fade from '@material-ui/core/Fade';

import {Redirect} from 'react-router-dom';
import {Link as RouterLink} from 'react-router-dom';
import Networker from '../Utils/Networker';
import {getCreatedAtFromObjectId} from '../Utils/DataUtils';
import withStyle from '../style';
import Loader from '../Components/Loader';
import MadeWithLove from '../Components/MadeWithLove';
const emailRX = /^([-!#-'*+/-9=?A-Z^-~]+(\.[-!#-'*+/-9=?A-Z^-~]+)*|"[^"]+")@[0-9A-Za-z]([0-9A-Za-z-]{0,61}[0-9A-Za-z])?(\.[0-9A-Za-z]([0-9A-Za-z-]{0,61}[0-9A-Za-z])?)+$/;
const getRemeber = () => {
  try {
    const shouldRemember = window.localStorage.getItem('remember') === "true";
    return shouldRemember;
  } catch (e) {
    return false;
  }
};

const getAllAdminProducts = (org) => {
  return Networker.get({
    root: 'products',
    inner: 'admin',
    cache: true
  });
};

const getAllOrganizations = (userId) => {
  return Networker.get({
    root: 'organizations',
    query: {userId}
  });
};


const setRemember = (should) => {
  try {
    window.localStorage.setItem('remember', should ? "true" : "false");
  } catch (e) {
    return false;
  }
}


export default function SignIn() {
  const [isLoggedIn, setIsLoggedIn] = React.useState(false);
  const [email, setEmail] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [pathToOpen, setPathToOpen] = React.useState(null);
  const [loading, setLoading] = React.useState(false);

  useEffect(() => {
    if (getRemeber()) {
      setLoading(true);
      Networker.getUserSession()
      .then(async res => {
        setIsLoggedIn(true);
      })
      .catch(err => {
        setRemember(false);
        setIsLoggedIn(false);
        setLoading(false);
      })
    }
  }, []);

  useEffect(() => {
    if (isLoggedIn && !pathToOpen) {
      Networker.getUserSession()
      .then(async res => {
      localStorage.setItem("userEmail",res?.body?.email);
        let prods = {};
        try {
          prods = await getAllAdminProducts();
        } catch (e) {
          console.warn('user is not an admin');
          setPathToOpen('/account');
          return;
        }
        const userOrgs = new Set(res.body.organizations);
        const sortedProds = prods.body.sort((a,b) => {
          const aDate = Date.parse(getCreatedAtFromObjectId(a._id));
          const bDate = Date.parse(getCreatedAtFromObjectId(b._id));
          return bDate - aDate;
        });
        if (sortedProds.length > 0) {
          const redirProduct = sortedProds[0];
          const productOrgs = new Set(redirProduct.organizations);
          const intersection = new Set([...userOrgs].filter(x => productOrgs.has(x)));
          if (intersection.size >= 1) {
            const org = intersection.values().next().value;
            const path = `/dashboard/${org}/${redirProduct._id}`;
            Networker.interactionBeacon({
              message: 'found intersecting product and organization',
            });
            setPathToOpen(path);
          } else {
            const adminOrgs = await getAllOrganizations(res.body._id);
            const prodAdminOrgs = adminOrgs.body.filter(({_id}) => {
              return intersection.has(_id);
            });
            if (prodAdminOrgs.length > 0) {
              const path = `/dashboard/${
                prodAdminOrgs[0]._id
              }/${redirProduct._id}`;
              setPathToOpen(path);
            } else {
              console.log('here! 2');
              setPathToOpen('/account');
            }
          }
        } else {
          console.log('here!');
          setPathToOpen('/account');
        }
      })
      .catch((err) => {
        console.error(err);
        setRemember(false);
        setIsLoggedIn(false);
        setLoading(false);
      });
    }
  }, [isLoggedIn, pathToOpen]);

  const setField = (e) => {
    if (e.target.id === 'email') {
      setEmail(e.target.value);
    } else if (e.target.id === 'password') {
      setPassword(e.target.value);
    }
  };

  const onLoginClick = (e) => {
    e.preventDefault();
    if (!emailRX.test(email)) {
      window.alert(email + ' is not a valid email address');
      Networker.interactionBeacon({
        message: 'bad email entered',
        data: {
          email: email
        }
      });
      return;
    }
    setLoading(true);
    Networker.signIn({email, password})
    .then((res) => {
      setLoading(false);
      setRemember(document.getElementById('remember').checked);
      setIsLoggedIn(true);
    })
    .catch(async (err) => {
      setIsLoggedIn(false);
      setRemember(false);
      setLoading(false);
      window.alert('Error, password or email incorrect');
      await Networker.sendError({
        message: 'login error',
        namespace: 'dashboard.login',
        data: 'login incorrect'
      });
    });
  };

  const classes = withStyle();
  return isLoggedIn && pathToOpen ? <Redirect to={pathToOpen}/> : (
      <div>
      <Fade in={!loading} timeout={{
          enter: 1000,
          exit: 500
        }}>
        <Container component="main" maxWidth="sm">
          <CssBaseline />
          <center style={{margin: '5vh'}}>
            <img src="/logo.png" width="100%" alt="Adrich Dashboard Logo">
            </img>
          </center>
          <div className={classes.paper}>
            <Typography component="h1" variant="h4">
              Sign In
            </Typography>
            <form className={classes.form} noValidate onSubmit={onLoginClick}>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                InputProps={{
                  classes: {
                    input: classes.largeInput,
                  },
                }}
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                autoFocus
                onChange={setField}
              />
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                InputProps={{
                  classes: {
                    input: classes.largeInput,
                  },
                }}
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
                onChange={setField}
              />
              <FormControlLabel
                control={<Checkbox id="remember" value="remember" color="primary" />}
                label="Remember me"
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="secondary"
                className={classes.submit}
              >
                Sign In
              </Button>
              <hr/>
              <Grid container>
                <Grid item xs>
                  <Link component={RouterLink} to="/recover" variant="body2">
                    Forgot password?
                  </Link>
                </Grid>
                <Grid item>
                  <Link component={RouterLink} to="/register" variant="body2">
                    Don't have an account? Sign Up
                  </Link>
                </Grid>
              </Grid>
            </form>
          </div>
        <Box mt={5}>
          <MadeWithLove />
        </Box>
      </Container>
    </Fade>
    <Loader show={loading}/>
    </div>
  );
}
