import Networker from "./Networker";

export const fileUploaderAWS = async (file, AWS) => {
    const { name, type } = file
    const s3Details = await Networker.getMethod("user/env");
    const { REACT_APP_SECRETACCESSKEY, REACT_APP_ACCESSKEYID, REACT_APP_S3BUCKET } = s3Details?.body

    try {
        return new Promise((resolve, reject) => {
            const fileName = Date.now() + "_" + name
            var s3 = new AWS.S3({
                signatureVersion: "v4",
                region: "us-west-2",
                accessKeyId: REACT_APP_ACCESSKEYID,
                secretAccessKey: REACT_APP_SECRETACCESSKEY
            });


            var params = {
                Bucket: REACT_APP_S3BUCKET,
                Key: fileName,
                ContentType: type,
                Body: file,
                ACL: "public-read",
            };
            const options = { partSize: 10 * 1024 * 1024, queueSize: 10 };
            s3.upload(params, options, function (err, data) {
                if (err) {
                    return reject(err);
                } else {
                    let fileUrl = `https://adrich-push-notification-images.s3-us-west-2.amazonaws.com/` + params.Key;
                    return resolve(fileUrl);
                }
            }).on("httpUploadProgress", (progress) => {
                let uploaded = parseInt((progress.loaded * 100) / progress.total);
                console.log(uploaded, 'is the uploaded state ==>>');
            });
        })
    } catch (error) {
        console.log(error)
    }
};