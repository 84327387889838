import React from 'react';

const getIframeWithLang = (text, lang) => `<iframe class="privacy-policy" style="width:99vw;height:90vh;" src="/${text}/${lang}.html">`;

export default class Privacy extends React.Component {
  state = {
    language: 'english'
  }

  componentDidMount() {
    const lang = window.navigator.languages.join(' ') || 'en-US';
    const isfrench = lang.toLowerCase().indexOf('fr') > -1;
    const language = isfrench ? 'french' : 'english';

    this.setState({
      language,
      altLanguage: isfrench ? 'english' : 'french'
    });
  }

  render() {

    return (
      <div>
        <button
          onClick={() => this.setState({
            language: this.state.altLanguage,
            altLanguage: this.state.language
          })}>
          {this.state.language === 'english' ? 'Français' : 'English'}
        </button>
        <div dangerouslySetInnerHTML={{
            __html: getIframeWithLang('policies', this.state.language)
          }}>
        </div>
      </div>
    );
  }
}
