import React, {Component} from 'react';
import Networker from '../Utils/Networker';

export default class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    console.error('ERROR BOUNDARY', errorInfo);
    const namespace = 'dashboard.errorboundary';
    Networker.sendError({
      message: 'render error',
      namespace: namespace,
      data: {
        error: JSON.stringify(error),
        errorInfo: JSON.stringify(errorInfo)
      }
    });
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <h5>Something went wrong. This error has been reported.
        You will be redirected in 5 seconds. </h5>;
    }

    return this.props.children;
  }
}