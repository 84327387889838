import React, { useState,useRef } from 'react';
import { styled } from '@material-ui/core/styles';
import { withStyles } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import ConsumerTable from '@material-ui/core/Table';
import Switch from '@material-ui/core/Switch';
import CancelIcon from '@material-ui/icons/Cancel';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import Button from '@material-ui/core/Button';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme, createTheme } from '@material-ui/core/styles';
import Networker from '../Utils/Networker';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import { ThemeProvider } from '@material-ui/styles';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.MuiTableCell-head`]: {
    backgroundColor: '#686868',
    color: theme.palette.common.white,
    width: 400
  },
  [`&.MuiTableCell-body`]: {
    fontSize: 14,
    width: 400
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.common.white
  },
  '&:nth-of-type(even)': {
    backgroundColor: theme.palette.common.white
  },
  // hide last border
  'td, th': {
    border: 1
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 1
  },
}));
const snackBarTheme = createTheme({
    palette: {
        primary: {
            main: "#46AD8D",
            contrastText: "#fff" //button text white instead of black
        },
        background: {
            default: "#008000"
        }
    }
});
const snackBarErrorTheme = createTheme({
    palette: {
        primary: {
            main: "#46AD8D",
            contrastText: "#fff" //button text white instead of black
        },
        background: {
            default: "#ff0000"
        }
    }
});
const AntSwitch = withStyles((theme) => ({
  root: {
    width: 28,
    height: 16,
    padding: 0
  },
  switchBase: {
    padding: 2,
    color: theme.palette.grey[500],
    '&$checked': {
      transform: 'translateX(12px)',
      color: theme.palette.common.white,
      '& + $track': {
        opacity: 1,
        backgroundColor: `#ff7a59`,
        borderColor: theme.palette.primary.main
      }
    }
  },
  thumb: {
    width: 12,
    height: 12,
    boxShadow: 'none',
    color: 'white'
  },
  track: {
    border: `0px solid ${theme.palette.grey[500]}`,
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor: '#808080'
  },
  checked: {}
}))(Switch);

export default function ConsumersTable({ConsumersListDetailsDataFromApi, show, consumersStackList, currentTabId, exportCosntStatsToCSVApi, ShowMatchedEmailsList, closePopup}) {
     const ConsumersListDetailsFromApi = useRef(ConsumersListDetailsDataFromApi);
    const openConsumersStack = React.useRef(show);
    const [isAgree, seIsAgree] = React.useState(false);
    const [isEmailsOrPhonelistChecked, setIsAllEmailsOrPhoneNumberList] = React.useState({
        allRecruitedEmails: false,
        allAccountedEmails: false,
        allMatchedEmails: false,
        allPhoneNumbers: false
    });
    const [consumbersStackData, setconsumbersStackData] = useState([...consumersStackList]);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    const [selectedValueForSendNotifications, setSelectedValue] = React.useState('1');
    const [getEmailOrPhoneNumberList, setEmailOrPhoneNumberList] = React.useState([]);
    const disbledSendBtn = useRef(true);
    const [isSendBtnDisable, setisSendBtnDisable] = React.useState(true);
    const valueForNotficationSuject = React.useRef('');
    const valueForNotficationEmails = React.useRef('');
    const [ShowMatchedEmails, setShowMatchedEmails] = React.useState(ShowMatchedEmailsList);
    const currentTab = currentTabId;
    const emailPhoneNumToSendList = useRef([]);
    const [openToastr, setToastr] = React.useState(false);
    const [getSendApiData, setSendApiData] = React.useState();
    const [subjectText, setSubjectText] = React.useState();
    const [emailList, setEmailList] = React.useState();
    const sendNotificationsForSMS = (async (stringValue) => {
        if (valueForNotficationEmails.current) {
        emailPhoneNumToSendList.current = [...emailPhoneNumToSendList.current, ...valueForNotficationEmails.current.split(',')];
        }
        const postSmsRes = await Networker.post({
            root: 'notifications/sms',
            inner: '',
            body: {
                to: emailPhoneNumToSendList.current || [],
                message: valueForNotficationSuject.current
            },
        });
        console.warn('postSmsRes', postSmsRes);
        if (postSmsRes) {
            setToastr(true);
            setSendApiData({ statusCode: postSmsRes.statusCode, showToastr: true });
        }
        setTimeout(() => {
            setToastr(false);
        }, 4000);
    });


    const sendNotificationsForEmail = (async (stringValue) => {
        const postEmailRes = await Networker.post({
        root: 'notifications/email',
        inner: '',
        body: {
            to: (emailPhoneNumToSendList.current) || [],
            message: (valueForNotficationSuject.current || ''),
            subject: (valueForNotficationSuject.current || ''),
        },
        });
        console.warn('postEmailRes', postEmailRes);
        if (postEmailRes) {
            setToastr(true);
            setSendApiData({ statusCode: postEmailRes.statusCode, showToastr: true });
        }
        setTimeout(() => {
            setToastr(false);
        }, 4000);
    });
    const handleToggle = (newValue) => {
        seIsAgree(newValue);
        if (newValue === true) {
        setShowMatchedEmails(true);
        } else {
            getconsumersList();
            valueForNotficationSuject.current = '';
            valueForNotficationEmails.current = '';
            setSubjectText('');
            setEmailList('');
        }
    };
    const getconsumersList = async () => {
        setShowMatchedEmails(false);
        const filterRes = await Networker.get({
        root: 'recruitedConsumers/filter',
        inner: '',
        query: { productId: currentTab },
        cache: true
        });
        let updatedPotentialMatchedEmails = [];
        if (filterRes && filterRes.body) ConsumersListDetailsFromApi.current = filterRes.body;
        if (filterRes.body && filterRes.body.emailList.length > 0) {
            filterRes.body.emailList.map((item, index) => {
                filterRes.body.emailList[index]['phoneNumberChecked'] = false;
                filterRes.body.emailList[index]['recruitedEmailChecked'] = false;
                filterRes.body.emailList[index]['matchedEmailChecked'] = false;
                if(!filterRes.body.emailList[index].hasOwnProperty('extraAccountedEmails'))
                    filterRes.body.emailList[index]['AllEmailDataAreThere'] = true;
                if (item.matchedEmail && item.matchedEmail.indexOf(',') > 0) {
                filterRes.body.emailList[index]['potentialEmails'] = item.matchedEmail.split(',');
                if (filterRes.body.emailList[index]['potentialEmails'].length > 0) {
                    filterRes.body.emailList[index]['potentialEmails'].map((matchedEmail) => {
                    updatedPotentialMatchedEmails.push({
                        potentialEmailChecked: false,
                        potentialEmail: matchedEmail
                    });
                    return true;
                    });
                    filterRes.body.emailList[index]['potentialEmails'] = updatedPotentialMatchedEmails;
                    updatedPotentialMatchedEmails = [];
                } else {
                    filterRes.body.emailList[index]['potentialEmails'] = [];
                  }
                }
                else {
                filterRes.body.emailList[index]['potentialEmails'] = []; 
                }
                return true
            });
            // filterRes.body.presentInvalidData.map((item, index) => {
            //     filterRes.body.emailList[index]['accountedEmail'] = item.email;
            //     filterRes.body.emailList[index]['accountedEmailChecked'] = false;
            //     return true
            // });
            setTimeout(() => {
                // setconsumbersStackData(filterRes.body.emailList);
                // openConsumersStack.current = (true);
                if (filterRes.body.emailList.length > 0 && filterRes.body.presentInvalidData.length === 0) {
                    setconsumbersStackData(filterRes.body.emailList);
                    openConsumersStack.current = (true);
                }
            }, 100);
        } if (filterRes && filterRes.body && filterRes.body.presentInvalidData.length > 0) {
            let finalPresentInvalidData = [];
            // if (filterRes.body.presentInvalidData.length > 0 && filterRes.body.emailList.length === 0) finalPresentInvalidData = filterRes.body.presentInvalidData;
            filterRes.body.presentInvalidData.map((item, index) => {
                // newEmailList.push({ recruiteEmail: item.email });
                const againMatchIndex = filterRes.body.emailList.findIndex((emails) => emails.accountedEmail === item.email);
                if (filterRes.body.emailList.length > 0) {
                    let matchedIndex = filterRes.body.emailList.findIndex((emails) => emails.matchedEmail === item.email);
                    if (matchedIndex > -1) {
                        filterRes.body.emailList[matchedIndex]['accountedEmail'] = item.email;
                        filterRes.body.emailList[matchedIndex]['accountedEmailChecked'] = false;
                    } else if (matchedIndex === -1) {
                        let potentioalEmailIndex = -1;
                        filterRes.body.emailList.map((emailItem, index) => {
                            if (emailItem.potentialEmails && emailItem.potentialEmails.length > 0) {
                                potentioalEmailIndex = emailItem.potentialEmails.findIndex((potential) => potential.potentialEmail === item.email);
                                matchedIndex = index;
                                if (potentioalEmailIndex > -1 &&
                                !filterRes.body.emailList[index].accountedEmailMatchedEmail) {
                                filterRes.body.emailList[index]['accountedEmail'] = item.email;
                                filterRes.body.emailList[index]['accountedEmailChecked'] = false;
                                filterRes.body.emailList[index]['accountedEmailMatchedEmail'] = true;
                                }
                            }
                            return true;
                            });
                            if (potentioalEmailIndex === -1 && matchedIndex === -1 && againMatchIndex === -1) {
                                filterRes.body.emailList.push({
                                    'accountedEmail': item.email,
                                    'accountedEmailChecked': false,
                                    'extraAccountedEmails': true
                                });
                            }
                        const againMatch = filterRes.body.emailList.findIndex((emails) => emails.accountedEmail === item.email);
                        if(againMatch === -1 && againMatchIndex === -1) filterRes.body.emailList.push({
                        'accountedEmail': item.email,
                        'accountedEmailChecked': false,
                        'extraAccountedEmails': true
                        });
                    }
                } else {
                finalPresentInvalidData.push({
                    'accountedEmail': item.email,
                    'accountedEmailChecked': false
                });
                }
                return true
            });
      // setTimeout(() => {
        // setLoading(false);
        // setConsumersStackData(filterRes.body.emailList.length > 0 ? filterRes.body.emailList : finalPresentInvalidData);
        // setOpenConsumersStack(true);
            setTimeout(() => {
                setconsumbersStackData(filterRes.body.emailList.length > 0 ? filterRes.body.emailList : finalPresentInvalidData);
                openConsumersStack.current = (true);
            }, 100);
        }
        else {
        openConsumersStack.current = (true);
        }
    };
    const handleCloseButton = () => {
        openConsumersStack.current = (false);
        setSelectedValue('1');
        valueForNotficationSuject.current = '';
        valueForNotficationEmails.current = '';
        setEmailOrPhoneNumberList([]);
        emailPhoneNumToSendList.current = [];
        const consumerDataArr = consumbersStackData;
        ConsumersListDetailsFromApi.current = [];
        consumerDataArr.map((item, index) => {
        item['phoneNumberChecked'] = false;
        item['recruitedEmailChecked'] = false;
        item['accountedEmailChecked'] = false;
        item['matchedEmailChecked'] = false;
        if (item.potentialEmails && item.potentialEmails.length > 0) {
            item.potentialEmails.forEach((potentialItem) => {
            potentialItem['potentialEmailChecked'] = false;
            });
        }
        return true;
        });
        // setconsumbersStackData([...consumerDataArr]);
        const removeAllChecks = {
        allRecruitedEmails: false,
        allAccountedEmails: false,
        allMatchedEmails: false,
        allPhoneNumbers: false
        }
        setIsAllEmailsOrPhoneNumberList({ ...removeAllChecks });
        setconsumbersStackData([]);
        closePopup();
    }

    const handleChangeForNotification = (event) => {
        setSelectedValue(event.target.value);
        // valueForNotficationSuject.current = '';
        // valueForNotficationEmails.current = '';
        setEmailOrPhoneNumberList([]);
        emailPhoneNumToSendList.current = [];
        const consumerDataArr = consumbersStackData;
        consumerDataArr.map((item, index) => {
        item['phoneNumberChecked'] = false;
        item['recruitedEmailChecked'] = false;
        item['accountedEmailChecked'] = false;
        item['matchedEmailChecked'] = false;
        if (item.potentialEmails && item.potentialEmails.length > 0) {
            item.potentialEmails.forEach((potentialItem) => {
            potentialItem['potentialEmailChecked'] = false;
            });
        }
        return true;
        });
        setconsumbersStackData([...consumerDataArr]);
        const removeAllChecks = {
        allRecruitedEmails: false,
        allAccountedEmails: false,
        allMatchedEmails: false,
        allPhoneNumbers: false
        }
        setIsAllEmailsOrPhoneNumberList({ ...removeAllChecks });

    }
    const getNotificationSubject = (event) => {
        if (emailPhoneNumToSendList.current && valueForNotficationSuject.current) {
        disbledSendBtn.current = false;
        setisSendBtnDisable(disbledSendBtn.current);
        } else {
        disbledSendBtn.current = true;
        setisSendBtnDisable(disbledSendBtn.current);
        }
    }
    const getNotificationEmail = (event) => {
        if (emailPhoneNumToSendList.current && valueForNotficationSuject.current) {
        disbledSendBtn.current = false;
        setisSendBtnDisable(disbledSendBtn.current);
        } else {
        disbledSendBtn.current = true;
        setisSendBtnDisable(disbledSendBtn.current);
        }
    }
    // to get value as per columns 
    const getAllEmailsOrPhoneNumberList = (caseVal, event) => {
        const consumerDataArr = consumbersStackData;
        const emailsPhoneMuberColumnsData = isEmailsOrPhonelistChecked;
        let checkedList = {
        allRecruitedEmails: [],
        allAccountEmails: [],
        allMatchedEmails: [],
        allPhoneNumber: []
        };
        let value = '';
        console.warn('value', value);
        value = caseVal === 1 ? emailsPhoneMuberColumnsData.allRecruitedEmails = !emailsPhoneMuberColumnsData.allRecruitedEmails : '';
        value = caseVal === 2 ? emailsPhoneMuberColumnsData.allAccountedEmails = !emailsPhoneMuberColumnsData.allAccountedEmails : '';
        value = caseVal === 3 ? emailsPhoneMuberColumnsData.allMatchedEmails = !emailsPhoneMuberColumnsData.allMatchedEmails : '';
        value = caseVal === 4 ? emailsPhoneMuberColumnsData.allPhoneNumbers = !emailsPhoneMuberColumnsData.allPhoneNumbers : '';
        setIsAllEmailsOrPhoneNumberList({ ...emailsPhoneMuberColumnsData });
        // all recruited emails 
        if (caseVal === 1) {
        checkedList = getTableAllColumnsList(caseVal, emailsPhoneMuberColumnsData.allRecruitedEmails,
            checkedList, consumerDataArr);
        setEmailOrPhoneNumberList(oldList => ([...oldList, ...checkedList.allRecruitedEmails]));
        emailPhoneNumToSendList.current = [...emailPhoneNumToSendList.current, ...checkedList.allRecruitedEmails];
        }
        if (caseVal === 2) {
        checkedList = getTableAllColumnsList(caseVal, emailsPhoneMuberColumnsData.allAccountedEmails,
            checkedList, consumerDataArr);
        setEmailOrPhoneNumberList(oldList => ([...oldList, ...checkedList.allAccountEmails]));
        emailPhoneNumToSendList.current = [...emailPhoneNumToSendList.current, ...checkedList.allAccountEmails];
        }
        if (caseVal === 3) {
        checkedList = getTableAllColumnsList(caseVal, emailsPhoneMuberColumnsData.allMatchedEmails,
            checkedList, consumerDataArr);
        setEmailOrPhoneNumberList(oldList => ([...oldList, ...checkedList.allMatchedEmails]));
        emailPhoneNumToSendList.current = [...emailPhoneNumToSendList.current, ...checkedList.allMatchedEmails];
        }
        else if (caseVal === 4) {
        checkedList = getTableAllColumnsList(caseVal, emailsPhoneMuberColumnsData.allPhoneNumbers,
            checkedList, consumerDataArr);
        setEmailOrPhoneNumberList(oldList => ([...oldList, ...checkedList.allPhoneNumber]));
        emailPhoneNumToSendList.current = [...emailPhoneNumToSendList.current, ...checkedList.allPhoneNumber];
        }
        if (emailPhoneNumToSendList.current && valueForNotficationSuject.current) {
        disbledSendBtn.current = false;
        setisSendBtnDisable(disbledSendBtn.current);
        } else {
        disbledSendBtn.current = true;
        setisSendBtnDisable(disbledSendBtn.current);
        }
    }
    //get resepctive emails or phone number all list
    const getTableAllColumnsList = (caseVal, columnCheck, checkedList, consumerDataArr) => {
        let value = '';
        console.warn('value', value);
        let latestEmailsSendList = emailPhoneNumToSendList.current;
        if (columnCheck) {
        consumerDataArr.forEach((item, index) => {
            value = caseVal === 1 ?
            (item['recruitedEmailChecked'] = true,
                checkedList.allRecruitedEmails.push(item.email)) : '';
            value = caseVal === 2 ?
            (item['accountedEmailChecked'] = true,
                checkedList.allAccountEmails.push(item.accountedEmail)) : '';
            value = caseVal === 3 ?
            (item['matchedEmailChecked'] = true,
                checkedList.allMatchedEmails.push(item.matchedEmail)) : '';
            value = caseVal === 4 ?
            (item['phoneNumberChecked'] = true,
                checkedList.allPhoneNumber.push(item.phone)) : '';
            if (caseVal === 3 && item.potentialEmails && item.potentialEmails.length > 0) {
            item.potentialEmails.forEach((potentialItem) => {
                potentialItem['potentialEmailChecked'] = true;
                checkedList.allMatchedEmails.push(potentialItem.potentialEmail);
            });
            }
        });
        } else if (!columnCheck) {
        consumerDataArr.forEach((item, index) => {
            value = caseVal === 1 ?
            (item['recruitedEmailChecked'] = false,
                checkedList.allRecruitedEmails = []) : '';
            value = caseVal === 2 ?
            (item['accountedEmailChecked'] = false,
                checkedList.allAccountEmails = []) : '';
            value = caseVal === 3 ?
            (item['matchedEmailChecked'] = false,
                checkedList.allMatchedEmails = []) : '';
            value = caseVal === 4 ?
            (item['phoneNumberChecked'] = false,
                checkedList.allPhoneNumber = []) : '';
            if (caseVal === 3 && item.potentialEmails && item.potentialEmails.length > 0) {
            item.potentialEmails.forEach((potentialItem) => {
                potentialItem['potentialEmailChecked'] = false;
                let index = latestEmailsSendList.findIndex((emailItem) => emailItem === item.potentialEmail);
                value = index > -1 ? latestEmailsSendList.splice(index, 1) : '';
            });
            checkedList.allMatchedEmails = [];
            }
            if (latestEmailsSendList.length > 0) {
            if (caseVal === 1) {
                let index = latestEmailsSendList.findIndex((emailItem) => emailItem === item.email);
                value = index > -1 ? latestEmailsSendList.splice(index, 1) : '';
            }
            if (caseVal === 2) {
                let index = latestEmailsSendList.findIndex((emailItem) => emailItem === item.accountedEmail);
                value = index > -1 ? latestEmailsSendList.splice(index, 1) : '';
            }
            if (caseVal === 3) {
                let index = latestEmailsSendList.findIndex((emailItem) => emailItem === item.matchedEmail);
                value = index > -1 ? latestEmailsSendList.splice(index, 1) : '';
            }
            if (caseVal === 4) {
                let index = latestEmailsSendList.findIndex((emailItem) => emailItem === item.phone);
                value = index > -1 ? latestEmailsSendList.splice(index, 1) : '';
            }
            }
        });
        }
        emailPhoneNumToSendList.current = latestEmailsSendList;
        return checkedList;
    }
    // to get phone number or email list to send notifications
    const getcheckedList = (event, caseVal, indexValue, moreEmailsIndex) => {
        const consumerDataArr = consumbersStackData;
        const emilaPhoneList = emailPhoneNumToSendList.current;
        let checkedList = [];
        let value = '';
        console.warn('value', value);
        value = caseVal === 1 ? consumerDataArr[indexValue].recruitedEmailChecked = !consumerDataArr[indexValue].recruitedEmailChecked : '';
        value = caseVal === 2 ? consumerDataArr[indexValue].accountedEmailChecked = !consumerDataArr[indexValue].accountedEmailChecked : '';
        value = caseVal === 3 ? consumerDataArr[indexValue].phoneNumberChecked = !consumerDataArr[indexValue].phoneNumberChecked : '';
        value = caseVal === 4 ? consumerDataArr[indexValue].matchedEmailChecked = !consumerDataArr[indexValue].matchedEmailChecked : '';
        value = caseVal === 5 ? consumerDataArr[indexValue].matchedEmailChecked = !consumerDataArr[indexValue].matchedEmailChecked : '';
        value = (caseVal === 5 && moreEmailsIndex > -1 && consumerDataArr[indexValue].potentialEmails[moreEmailsIndex]) ?
        consumerDataArr[indexValue].potentialEmails[moreEmailsIndex].potentialEmailChecked =
        !consumerDataArr[indexValue].potentialEmails[moreEmailsIndex].potentialEmailChecked : '';
        let index = -1;
        if (getEmailOrPhoneNumberList && getEmailOrPhoneNumberList.length > 0) {
        if (caseVal === 1) {
            setconsumbersStackData([...consumerDataArr]);
            index = emilaPhoneList.findIndex((item) => item === consumerDataArr[indexValue].email);
            value = (index > -1 && consumerDataArr[indexValue].recruitedEmailChecked === false) ? emilaPhoneList.splice(index, 1) : consumerDataArr[indexValue].recruitedEmailChecked === true ?
            (checkedList.push(consumerDataArr[indexValue].email)) : '';
        } if (caseVal === 2) {
            setconsumbersStackData([...consumerDataArr]);
            index = emilaPhoneList.findIndex((item) => item === consumerDataArr[indexValue].accountedEmail);
            value = (index > -1 && consumerDataArr[indexValue].accountedEmailChecked === false) ? emilaPhoneList.splice(index, 1) :
            consumerDataArr[indexValue].accountedEmailChecked === true ?
                (checkedList.push(consumerDataArr[indexValue].accountedEmail)) : '';
        } if (caseVal === 4) {
            setconsumbersStackData([...consumerDataArr]);
            index = emilaPhoneList.findIndex((item) => item === consumerDataArr[indexValue].matchedEmail);
            value = (index > -1 && consumerDataArr[indexValue].matchedEmailChecked === false) ? emilaPhoneList.splice(index, 1) :
            consumerDataArr[indexValue].matchedEmailChecked === true ?
                (checkedList.push(consumerDataArr[indexValue].matchedEmail)) : '';
        } if (caseVal === 5) {
            setconsumbersStackData([...consumerDataArr]);
            index = emilaPhoneList.findIndex((item) => item === consumerDataArr[indexValue].potentialEmails[moreEmailsIndex].potentialEmail);
            value = (index > -1 && consumerDataArr[indexValue].potentialEmails[moreEmailsIndex].potentialEmailChecked === false) ? emilaPhoneList.splice(index, 1) :
            consumerDataArr[indexValue].potentialEmails[moreEmailsIndex].potentialEmailChecked === true ?
                (checkedList.push(consumerDataArr[indexValue].potentialEmails[moreEmailsIndex].potentialEmail)) : '';

        } else if (caseVal === 3) {
            setconsumbersStackData([...consumerDataArr]);
            index = emilaPhoneList.findIndex((item) => item === consumerDataArr[indexValue].phone);
            value = (index > -1 && consumerDataArr[indexValue].phoneNumberChecked === false) ? emilaPhoneList.splice(index, 1) :
            consumerDataArr[indexValue].phoneNumberChecked === true ?
                (checkedList.push(consumerDataArr[indexValue].phone)) : '';
        }
        setEmailOrPhoneNumberList(oldList => ([...emilaPhoneList]));
        emailPhoneNumToSendList.current = [...emilaPhoneList, ...checkedList];
        } else {
        if (caseVal === 1 && consumerDataArr[indexValue].recruitedEmailChecked === true) {
            checkedList.push(consumerDataArr[indexValue].email);
            setEmailOrPhoneNumberList(oldList => ([...oldList, ...checkedList]));
            emailPhoneNumToSendList.current = [...checkedList];
        }
        if (caseVal === 2 && consumerDataArr[indexValue].accountedEmailChecked === true) {
            checkedList.push(consumerDataArr[indexValue].accountedEmail);
            setEmailOrPhoneNumberList(oldList => ([...oldList, ...checkedList]));
            emailPhoneNumToSendList.current = [...checkedList];
        }
        if (caseVal === 4 && consumerDataArr[indexValue].matchedEmailChecked === true) {
            checkedList.push(consumerDataArr[indexValue].matchedEmail);
            setEmailOrPhoneNumberList(oldList => ([...oldList, ...checkedList]));
            emailPhoneNumToSendList.current = [...checkedList];
        }
        if (caseVal === 5 && consumerDataArr[indexValue].matchedEmailChecked === true
            && consumerDataArr[indexValue].potentialEmails[moreEmailsIndex].potentialEmailChecked) {
            checkedList.push(consumerDataArr[indexValue].potentialEmails[moreEmailsIndex].potentialEmail);
            setEmailOrPhoneNumberList(oldList => ([...oldList, ...checkedList]));
            emailPhoneNumToSendList.current = [...checkedList];
        }
        else if (caseVal === 3 && consumerDataArr[indexValue].phoneNumberChecked === true) {
            checkedList.push(consumerDataArr[indexValue].phone);
            setEmailOrPhoneNumberList(oldList => ([...oldList, ...checkedList]));
            emailPhoneNumToSendList.current = [...checkedList];
        }
        }
        if (emailPhoneNumToSendList.current && valueForNotficationSuject.current) {
        disbledSendBtn.current = false;
        setisSendBtnDisable(disbledSendBtn.current);
        } else {
        disbledSendBtn.current = true;
        setisSendBtnDisable(disbledSendBtn.current);
        }
    }
    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
        return;
        }
        setSendApiData({});
        setToastr(false);
    };
    return (
        <div className="consumnerStatisticsTable">
            <Snackbar
                open={openToastr}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                bodyStyle={{ fontWeight: 600, color: 'coral' }}
                onClose={(event, reason) => handleClose(event, reason)}
                message="Note archived"
            >
                <ThemeProvider theme={getSendApiData && getSendApiData.statusCode === 200 ? snackBarTheme : snackBarErrorTheme}>
                    <SnackbarContent
                        message={<span id="client-snackbar">{getSendApiData && getSendApiData.statusCode === 200 ? 'Sent SMS/Emails Successfully' : 'Not able to send SMS/Emails'}</span>}
                    />
                </ThemeProvider>
            </Snackbar>
            <Dialog
                open={openConsumersStack.current}
                onClose={() => handleCloseButton()}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                fullScreen={fullScreen}
                maxWidth="lg"
                width="100%"
            >
                <DialogTitle id="alert-dialog-title">
                {"Consumers"}
                <span className="closeBtn" onClick={() => { handleCloseButton() }}>
                    <CancelIcon title="Close Popup" />
                </span>
                </DialogTitle>
                <DialogContent style={{ overflowX: 'hidden', padding: '8px' }}>
                {/* reset and filter buttons */}
                <div className={`row ${consumbersStackData && consumbersStackData.length > 0 ? 'filterWrapper' : 'notfilterWrapper'}`}>
                    <Grid container>
                    <Grid style={{ marginTop: '20px' }} item xs={6}>
                        <span className="switchButtonText">Reset</span>
                        <span><AntSwitch onChange={(event, newValue) => handleToggle(newValue)} checked={isAgree} inputProps={{ 'aria-label': 'ant design' }} /></span>
                        <span className="switchButtonText">Match Filter</span>
                    </Grid>
                    <Grid item xs={6} style={{ textAlign: 'end' }}>
                        <div>
                        <FormControl component="fieldset">
                            <RadioGroup
                            aria-label="notifications"
                            row name="row-radio-buttons-group"
                            value={selectedValueForSendNotifications}
                            onChange={(event) => handleChangeForNotification(event)}
                            >
                            <FormControlLabel value="1" control={<Radio />} label="SMS" />
                            <FormControlLabel value="2" control={<Radio />} label="Email" />
                            </RadioGroup>
                        </FormControl>
                        </div>
                    </Grid>
                    </Grid>
                </div>
                {/* notifications field for sma and email to enter values */}
                <div className={`row ${consumbersStackData && consumbersStackData.length > 0 ? 'subjectWrapper' : 'notfilterWrapper'}`}>
                    <Grid container>
                    <Grid style={{ marginTop: '20px' }} item xs={6}>
                    </Grid>
                    <Grid item xs={6} style={{ textAlign: 'end' }}>
                        <div>
                        <TextField
                            className="subjectPlace outlined-multiline-flexible"
                            label={selectedValueForSendNotifications === '1' ? 'Text Body' : 'Subject'}
                            multiline
                            variant="outlined"
                            maxrows={4}
                            value={subjectText}
                            onChange={(event, newValue) => {
                            valueForNotficationSuject.current = event.target.value;
                                getNotificationSubject(event);
                                setSubjectText(event.target.value);
                            }}
                        />
                        <TextField
                            className={`subjectPlace outlined-textarea ${selectedValueForSendNotifications !== '2' ? 'notfilterWrapper' : ''}`}
                            label="Email Body"
                            placeholder="Email Body"
                            multiline
                            variant="outlined"
                            value={emailList}
                            onChange={(event) => {
                            valueForNotficationEmails.current = event.target.value;
                                getNotificationEmail(event);
                                setEmailList(event.target.value);
                            }}
                        />
                        <Button size="large"
                            disabled={isSendBtnDisable}
                            className={`sendBtn`}
                            onClick={() => selectedValueForSendNotifications === '1' ? sendNotificationsForSMS() : sendNotificationsForEmail()}>Send</Button>
                        </div>
                    </Grid>
                    </Grid>
                </div>
                {/*  */}
                <div className={`${consumbersStackData && consumbersStackData.length > 0 ? 'consumnerStackWrappers' : ''}`}>
                    {
                    consumbersStackData && consumbersStackData.length === 0 ?
                        <div className="col-12">
                        <DialogContentText id="alert-dialog-description">
                            No Data Found
                        </DialogContentText>
                        </div> :
                        <div className="allTableDataWrapper">
                        {/* // recruited email data table */}
                        <Grid container>
                            {ConsumersListDetailsFromApi.current.presentInvalidData.length > 0 && (
                                <Grid style={{ paddingRight: '5px' }} item xs={(ConsumersListDetailsFromApi.current.presentInvalidData.length > 0 && ConsumersListDetailsFromApi.current.emailList.length > 0) ?
                                                 4 : (ConsumersListDetailsFromApi.current.presentInvalidData.length > 0 && ConsumersListDetailsFromApi.current.emailList.length === 0) ? 12 : ''}>
                                    <div className="userConsumersList consumnerStackWrapper col-12">
                                                    <ConsumerTable stickyHeader aria-label="simple table">
                                                        <TableHead>
                                                            <StyledTableRow>
                                                                <StyledTableCell className="consumerEmailTableCellWidth" style={{ padding: selectedValueForSendNotifications === '2' ? '16px' : '25px' }} align="left">
                                                                    {selectedValueForSendNotifications === '2' && (
                                                                        <Checkbox
                                                                            color="secondary"
                                                                            sx={{
                                                                                '&.Mui-checked': {
                                                                                    color: "#ff7a59",
                                                                                },
                                                                            }}
                                                                            disabled={selectedValueForSendNotifications === '1'}
                                                                            checked={isEmailsOrPhonelistChecked.allAccountEmails}
                                                                            onChange={(event) => getAllEmailsOrPhoneNumberList(2, event)}
                                                                        />
                                                                    )}
                                                                    <strong>Recruited Email</strong>
                                                                </StyledTableCell >
                                                            </StyledTableRow>
                                                        </TableHead>
                                                        <TableBody>
                                                            {consumbersStackData.map((row, index) => (
                                                                <StyledTableRow
                                                                    key={Symbol(`${index}tableLable`).toString()}
                                                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                                >
                                                                    {row.accountedEmail && (
                                                                        <StyledTableCell className={`consumerEmailTableCellWidth ${row.extraAccountedEmails === true ? 'highLighEmail' : ''}`}
                                                                            style={{ padding: selectedValueForSendNotifications === '2' ? '16px' : '27px' }} align="left" size="medium" component="th" scope="row">
                                                                            {selectedValueForSendNotifications === '2' && (
                                                                                <Checkbox
                                                                                    color="primary"
                                                                                    disabled={isEmailsOrPhonelistChecked.allAccountedEmails || selectedValueForSendNotifications === '1'}
                                                                                    checked={row.accountedEmailChecked}
                                                                                    onChange={(event) => getcheckedList(event, 2, index)}
                                                                                />
                                                                            )}
                                                                            {row.accountedEmail}
                                                                        </StyledTableCell>
                                                                    )}
                                                                </StyledTableRow>
                                                            ))}
                                                        </TableBody>
                                                    </ConsumerTable>
                                    </div>
                                </Grid>
                            )}
                            {(ConsumersListDetailsFromApi.current.emailList && ConsumersListDetailsFromApi.current.emailList.length > 0) && (
                                <Grid item xs={(ConsumersListDetailsFromApi.current.emailList.length > 0 &&
                                                ConsumersListDetailsFromApi.current.presentInvalidData.length > 0) ? 8 : (ConsumersListDetailsFromApi.current.emailList.length > 0 &&
                                                ConsumersListDetailsFromApi.current.presentInvalidData.length === 0) ? 12 : ''}>
                                    {/* // adrcih account emails data table */}
                                    <div className="userConsumersList consumnerStackWrapper col-12">
                                                    <ConsumerTable stickyHeader aria-label="simple table">
                                                        <TableHead>
                                                            <StyledTableRow>
                                                                <StyledTableCell className={`${ShowMatchedEmails === true ? 'consumerEmailTableCellWidth' : 'donotShow'}`} align="left">
                                                                    {selectedValueForSendNotifications === '2' && (
                                                                        <Checkbox
                                                                            color="secondary"
                                                                            sx={{
                                                                                '&.Mui-checked': {
                                                                                    color: "#ff7a59",
                                                                                },
                                                                            }}
                                                                            disabled={selectedValueForSendNotifications === '1'}
                                                                            checked={isEmailsOrPhonelistChecked.allMatchedEmails}
                                                                            onChange={(event) => getAllEmailsOrPhoneNumberList(3, event)}
                                                                        />
                                                                    )}
                                                                    <strong>Potential Email</strong>
                                                                </StyledTableCell >
                                                                <StyledTableCell className="consumerEmailTableCellWidth" align="left">
                                                                    {selectedValueForSendNotifications === '2' && (
                                                                        <Checkbox
                                                                            color="secondary"
                                                                            sx={{
                                                                                '&.Mui-checked': {
                                                                                    color: "#ff7a59",
                                                                                },
                                                                            }}
                                                                            disabled={selectedValueForSendNotifications === '1'}
                                                                            checked={isEmailsOrPhonelistChecked.allRecruitedEmails}
                                                                            onChange={(event) => getAllEmailsOrPhoneNumberList(1, event)}
                                                                        />
                                                                    )}
                                                                    <strong>Adrich Account Email</strong>
                                                                </StyledTableCell >
                                                                <StyledTableCell className="consumerTableCellWidth" style={{ width: '600px' }} align="left" size="medium">
                                                                    {selectedValueForSendNotifications === '1' && (
                                                                        <Checkbox
                                                                            color="secondary"
                                                                            sx={{
                                                                                '&.Mui-checked': {
                                                                                    color: "#ff7a59",
                                                                                },
                                                                            }}
                                                                            disabled={selectedValueForSendNotifications === '2'}
                                                                            checked={isEmailsOrPhonelistChecked.allPhoneNumbers}
                                                                            onChange={(event) => getAllEmailsOrPhoneNumberList(4, event)}
                                                                        />
                                                                    )}
                                                                    <strong>Phone Number</strong>
                                                                </StyledTableCell >
                                                                <StyledTableCell className="consumerTableCellWidth" align="left" size="medium"><strong>User Id</strong></StyledTableCell >
                                                                <StyledTableCell className="consumerTableCellWidth" align="left" size="medium"><strong>MAC</strong></StyledTableCell >
                                                            </StyledTableRow>
                                                        </TableHead>
                                                        <TableBody>
                                                            {consumbersStackData.map((row, index) => (
                                                                <StyledTableRow
                                                                    key={Symbol(`${index}tableLable`).toString()}
                                                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                                >
                                                                    {row.AllEmailDataAreThere && (
                                                                        <StyledTableCell align="left" size="medium" className={`${ShowMatchedEmails === true ? 'consumerEmailTableCellWidth' : 'donotShow'}`}>
                                                                            {(row.potentialEmails.length === 0) && (
                                                                                <div className="signledMatchedEmails">
                                                                                    {(row.matchedEmail && row.matchedEmail.length > 0 && selectedValueForSendNotifications === '2') && (
                                                                                        <Checkbox
                                                                                            color="primary"
                                                                                            disabled={isEmailsOrPhonelistChecked.allMatchedEmails || selectedValueForSendNotifications === '1'}
                                                                                            checked={row.matchedEmailChecked}
                                                                                            onChange={(event) => getcheckedList(event, 4, index)}
                                                                                        />
                                                                                    )}
                                                                                    {(row.matchedEmail && row.matchedEmail.length > 0) ? row.matchedEmail : 'N/A'}
                                                                                </div>
                                                                            )}
                                                                            {(row.potentialEmails && row.potentialEmails.length > 0) && (
                                                                                <div className="moreMatchedEmails">
                                                                                    {row.potentialEmails.map((potentialItem, potentialIndex) => (
                                                                                        <div className="eachMatchedMails" key={Math.random()}>
                                                                                            {selectedValueForSendNotifications === '2' && (
                                                                                                <Checkbox
                                                                                                    color="primary"
                                                                                                    disabled={isEmailsOrPhonelistChecked.allMatchedEmails || selectedValueForSendNotifications === '1'}
                                                                                                    checked={potentialItem.potentialEmailChecked}
                                                                                                    onChange={(event) => getcheckedList(event, 5, index, potentialIndex)}
                                                                                                />
                                                                                            )}
                                                                                            {potentialItem.potentialEmail}
                                                                                        </div>
                                                                                    ))}
                                                                                </div>
                                                                            )}
                                                                        </StyledTableCell>
                                                                    )}
                                                                    {row.AllEmailDataAreThere && (
                                                                        <StyledTableCell align="left" size="medium" className="consumerEmailTableCellWidth">
                                                                            {(row.email && row.email.length > 0 && selectedValueForSendNotifications === '2') && (
                                                                                <Checkbox
                                                                                    color="primary"
                                                                                    disabled={isEmailsOrPhonelistChecked.allRecruitedEmails || selectedValueForSendNotifications === '1'}
                                                                                    checked={row.recruitedEmailChecked}
                                                                                    onChange={(event) => getcheckedList(event, 1, index)}
                                                                                />
                                                                            )}
                                                                            {row.email && row.email.length > 0 ? row.email : 'N/A'}
                                                                        </StyledTableCell>
                                                                    )}
                                                                    {row.AllEmailDataAreThere && (
                                                                        <StyledTableCell style={{ minWidth: '300px !important' }} align="left" className="consumerTableCellWidth" >
                                                                            {(row.phone && row.phone.length > 0 && selectedValueForSendNotifications === '1') && (
                                                                                <Checkbox
                                                                                    color="primary"
                                                                                    disabled={isEmailsOrPhonelistChecked.allPhoneNumbers || selectedValueForSendNotifications === '2'}
                                                                                    checked={row.phoneNumberChecked}
                                                                                    onChange={(event) => getcheckedList(event, 3, index)}
                                                                                />
                                                                            )}
                                                                            {(row.phone && row.phone.length > 0) ? row.phone.replace(/\D+/g, '').replace(/(\d{3})(\d{3})(\d{0,10})/, '($1) $2-$3') : 'N/A'}
                                                                        </StyledTableCell>
                                                                    )}
                                                                    {row.AllEmailDataAreThere && (
                                                                        <StyledTableCell align="left" size="medium" className="consumerTableCellWidth" >
                                                                            {(row.userId && row.userId.length > 0) ? row.userId : 'N/A'}
                                                                        </StyledTableCell>
                                                                    )}
                                                                    {row.AllEmailDataAreThere && (
                                                                        <StyledTableCell align="left" size="medium" className="consumerTableCellWidth">
                                                                        {(row.macs && row.macs.length > 0) ? row.macs : 'N/A'}
                                                                        </StyledTableCell>
                                                                    )}
                                                                </StyledTableRow>
                                                            ))}
                                                        </TableBody>
                                                    </ConsumerTable>
                                    </div>
                                </Grid>
                            )}
                        </Grid>
                        </div>

                    }
                </div>
                </DialogContent>
                <DialogActions>
                <Grid style={{ padding: '0px 16px' }} container>
                    <Grid item xs={6} >
                    <Button className={`CloseButton ${consumbersStackData && consumbersStackData.length > 0 ? 'consumnerStackWrapper' : 'notfilterWrapper'}`} onClick={() => exportCosntStatsToCSVApi(Object.values(consumbersStackData), currentTab)}>Export CSV</Button>
                    </Grid>
                </Grid>
                </DialogActions>
            </Dialog>
            <style>
            {
                ` .consumnerStackWrapper{
                border: 1px solid #8080805e;
                }
                .notfilterWrapper, .donotShow{
                    display:none;
                }
                .stickyWrapper{
                        top: 0;
                        left: 0;
                        z-index: 99999;
                        position: sticky !important;
                }
                .userConsumersList{
                    width: auto !important;
                    height: 400px !important;
                    overflow-y: auto !important;
                    overflow-x: auto !important;
                }
                .userConsumersList::-webkit-scrollbar-track,
                ::-webkit-scrollbar-track {
                    background: #f1f1f1 !important;
                }

                .userConsumersList::-webkit-scrollbar-thumb,
                ::-webkit-scrollbar-thumb {
                    background: gray !important;
                }

                .userConsumersList::-webkit-scrollbar-thumb:hover,
                ::-webkit-scrollbar-thumb:hover {
                    background: #555 !important;
                } 
                .userConsumersList::-webkit-scrollbar,
                ::-webkit-scrollbar {
                    width: 10px !important;
                    height: 10px !important;
                }  
                #alert-dialog-title{
                    color: #ff7a59;
                    text-align: center;
                    // text-decoration-line: underline;
                }
                .consumerTableCellWidth{
                    width:auto;
                    min-width: 300px !important;
                    word-break: 'break-all';
                    padding:16px;
                    border-bottom: 1px solid rgba(224, 224, 224, 1);
                }
                .tableBorder{
                    border: 1px solid #8080805e;
                }
                .consumerEmailTableCellWidth{
                    width:auto !important;
                    min-width: 300px !important;
                    word-break: 'break-all' !important;
                    padding:16px;
                    border-bottom: 1px solid rgba(224, 224, 224, 1);
                }
                .CloseButton, .CloseButton:hover, .CloseButton:focus{
                    background: #ff7a59;
                    color: white;
                    font-weight:700;
                }
                .inlineDisplayBlock{
                    display:inline-block !important;
                }
                .sendBtn, .sendBtn:hover, .sendBtn:focus{
                    background: #18b718e8;
                    color: white;
                    font-weight:700;
                    margin-top: 12px;
                }
                .subjectWrapper{
                    margin-bottom:10px !important;
                }
                .subjectPlace{
                    margin-right:10px;
                }
                .closeBtn{
                    text-align: end;
                    float: right;
                    color: gray;
                    font-size: 15px;
                    cursor:pointer;
                    padding-left: 10px;
                }
                .switchButtonText{
                    padding: 5px;
                    /* background: #ff7a59; */
                    color: #ff7a59;
                    font-weight: 700;
                    /* border: 1px solid #ff7a59; */
                    margin: 0px 10px;
                    text-decoration: underline;
                }
                .ProductListWrapper{
                    max-width: 25% !important;
                    width:350px !important;
                }
                .connectionStaticsWrapper{
                    height: 100vh;
                    overflow: auto;
                    padding-bottom: 77px;
                }
                .wrapperScrollHeight{
                    height :100vh;
                    overflow:auto;
                }
                .subjectPlace .MuiInputLabel-outlined.MuiInputLabel-shrink{
                    transform: translate(14px, -20px) scale(0.75) !important;
                }
                #alert-dialog-description{
                    text-align: center;
                }
                .MuiPaper-root{
                    width:100% !important;
                }
                .highLighEmail{
                    color: black !important;
                }
                `
            }
            </style>
        </div>
    )
};
