import React, { useState, useEffect } from 'react';

import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';

import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Fade from '@material-ui/core/Fade';

import {Redirect} from 'react-router-dom';
import MadeWithLove from '../Components/MadeWithLove';

import Loader from '../Components/Loader';
import AppContainer from '../Components/AppContainer';
import withStyle from '../style';

import Networker from '../Utils/Networker';


const setRemember = (should) => {
  try {
    window.localStorage.setItem('remember', should ? "true" : "false");
  } catch (e) {
    return false;
  }
}

export default function Account({match}) {
  const [user, setUser] = useState(null);
  const [isLoggedIn, setIsLoggedIn] = useState(true);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    Networker.getUserSession()
    .then(res => {
      setLoading(false);
      if (res.body) {
        if (!res.body.meta) {
          res.body.meta = {fname: '', lname: ''};
        }
        if (res.body) {
          setUser(res.body);
        } else {
          setRemember(false);
          setIsLoggedIn(false);
        }
      } else {
        setRemember(false);
        setIsLoggedIn(false);
      }
    })
    .catch(err => {
      console.error(err);
      setRemember(false);
      setLoading(false);
      setIsLoggedIn(false);
      Networker.sendError({
        message: 'account page error',
        namespace: 'dashboard.account',
        data: err
      });
    });
  }, []);

  const edit = (e) => {
    const objUpdate = {};
    const val = e.target.value;
    const id = e.target.id;
    const checkbox = (e.target.type === 'checkbox');
    if (user[id] !== val) {
      objUpdate[id] = checkbox ? e.target.checked : val;
      const updateSend = Object.assign({}, user || {}, objUpdate);
      setUser(updateSend);
    }
  };

  const editMeta = (e) => {
    const objUpdate = {meta: user.meta};
    const val = e.target.value;
    const id = e.target.id;
    if (!user.meta || user.meta[id] !== val) {
      objUpdate.meta[id] = val;
      const updateSend = Object.assign({}, user || {}, objUpdate);
      setUser(updateSend);
    }
  };

  const handleSave = () => {
    Networker.put({
      root: 'user',
      inner: user._id,
      body: user
    })
    .then(res => {
      window.alert('saved');
      setUser(res.body);
    })
    .catch(err => {
      console.error(err);
      setIsLoggedIn(false);
      Networker.sendError({
        message: 'account edit error',
        namespace: 'dashboard.account',
        data: err
      });
    })
  }
  const classes = withStyle();
  return isLoggedIn ? user ? (
    <div className={classes.root}>
      <CssBaseline />
      <AppContainer classes={classes} title="My Account" match={match}>
        <div className={classes.appBarSpacer} />
        <Fade in={!loading} mountOnEnter unmountOnExit>
        <div>
        <Container maxWidth="lg" className={classes.container}>
          <Grid container spacing={4} justifyContent="center" alignItems="center">
            <Grid item xs={12} md={4} lg={3}>
              <TextField
                id="email"
                type="email"
                value={user.email}
                onChange={edit}
                label="Email"
              >
              </TextField>
            </Grid>
            <Grid item xs={12} md={4} lg={3}>
              <TextField
                id="phone"
                type="tel"
                value={user.phone}
                onChange={edit}
                label="Phone"
              >
              </TextField>
            </Grid>
            <Grid item xs={12} md={4} lg={3}>
              <TextField
                id="fname"
                value={user.meta.fname}
                onChange={editMeta}
                label="First Name"
              >
              </TextField>
            </Grid>
            <Grid item xs={12} md={4} lg={3}>
              <TextField
                id="lname"
                value={user.meta.lname}
                onChange={editMeta}
                label="Last Name"
              >
              </TextField>
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={<Checkbox id="notificationsEnabled"
                checked={user.notificationsEnabled}
                value="notificationsEnabled"
                color="primary" />}
                onChange={edit}
                label="I agree to receive notifications."
              />
            </Grid>
          </Grid>
          <Button onClick={handleSave} color="primary">
            Save
          </Button>
        </Container>
        </div>
        </Fade>
        <MadeWithLove />
        <Typography variant="caption">ID: {user._id}</Typography>
      </AppContainer>
  <Loader show={loading} />
  </div>
  ) : null : <Redirect to="/login"></Redirect>;
}
